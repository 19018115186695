<h1 mat-dialog-title i18n="@@newUserDialogHeader">New User</h1>
<form [formGroup]="form" fxLayout="column" (ngSubmit)="createNewUser()" fxLayoutGap="16px">
  <div mat-dialog-content>
    <span i18n="@@newUserDialogText">Who would you like to invite?</span>
    <div class="error" *ngIf="hasError">
      <span *ngIf="this.errorMessage">{{this.errorMessage}}</span>
      <span *ngIf="!this.errorMessage" i18n="@@newUserDialogError">There was an error, please try it again.</span>
    </div>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@fullNameInputLabel">Full name</mat-label>
        <input matInput i18n-placeholder="@@fullNameInputPlaceholder" placeholder="First and lastname" required formControlName="name">
        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        <mat-hint i18n="@@fullNameInputHint">Enter the full name</mat-hint>
        <mat-error i18n="@@fullNameInputError">
          Please provide the full name of the user
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@initialsInputLabel">Initials</mat-label>
        <input matInput i18n-placeholder="@@initialsInputPlaceholder" placeholder="Initials" required formControlName="initials">
        <mat-icon matSuffix>label</mat-icon>
        <mat-hint i18n="@@initialsInputHint">Enter the initials of the user</mat-hint>
        <mat-error i18n="@@initialsInputError">
          Please provide the initials of the user
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@emailAddressInputLabel">Email address</mat-label>
        <input matInput i18n-placeholder="@@emailAddressInputPlaceholder" placeholder="name@example.com" required formControlName="email">
        <mat-icon matSuffix>alternate_email</mat-icon>
        <mat-hint i18n="@@emailAddressInputHint">Provide an email address</mat-hint>
        <mat-error i18n="@@emailAddressInputError">
          Please provide a valid email address
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@roleInputLabel">Role</mat-label>
        <mat-select formControlName="role" required>
          <mat-option *ngFor="let role of roles" [value]="role.name"
                      [disabled]="(role.name === 'superadministrator' && !authService.hasRole('superadministrator'))">
            {{role.display_name}}
          </mat-option>
        </mat-select>
        <mat-hint i18n="@@roleInputHint">Grant the user more access if necessary</mat-hint>
        <mat-error i18n="@@roleInputError">
          Please select a role
        </mat-error>
      </mat-form-field>
    </p>
  </div>
  <mat-dialog-actions fxLayoutAlign="end" fxLayoutGap="16px">
    <a mat-button mat-dialog-close [disabled]="isLoading" i18n="@@cancelBtnLabel">Cancel</a>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-button color="primary" [loading]="isLoading" i18n="@@sendInvitationBtnLabel">Send invitation</button>
  </mat-dialog-actions>
</form>
