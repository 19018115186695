import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Risk} from '../../services/risk.service';
import {RiskAssessment} from '../../services/risk-assessment.service';
import {Project} from '../../services/project.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {formatNumber} from '@angular/common';
import {RiskAction, RiskActionService} from '../../services/risk-action.service';

@Component({
  selector: 'app-confirm-delete-action-dialog',
  templateUrl: './confirm-delete-action-dialog.component.html',
  styleUrls: ['./confirm-delete-action-dialog.component.scss']
})
export class ConfirmDeleteActionDialogComponent implements OnInit {
  isLoading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { risk: Risk, riskAssessment: RiskAssessment, project?: Project, action: RiskAction }, // todo make without project
    private riskActionService: RiskActionService,
    private dialogRef: MatDialogRef<ConfirmDeleteActionDialogComponent>,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
  }

  deleteAction() {
    this.isLoading = true;
    this.riskActionService.delete(this.data.project, this.data.riskAssessment, this.data.risk, this.data.action).subscribe(
      (success: boolean) => {
        if (success) {
          this.dialogRef.close(true);
          this.snackBar.open('Action for risk \"R' + formatNumber(this.data.risk.number, 'en-US', '3.0') + '\" is deleted');
        }
      }, () => {
        this.isLoading = false;
      }
    );
  }
}
