import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  private readonly currentLocale: string;

  constructor(
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.currentLocale = this.locale.split('-', 1)[0];
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Clone the request to add the new header only if not already exists
    if (!request.headers.has('Accept-Language')) {
      const clonedRequest = request.clone({
        headers: request.headers.set('Accept-Language', this.currentLocale)
      });
      // Pass the cloned request instead of the original request to the next handle
      return next.handle(clonedRequest);
    } else {
      return next.handle(request);
    }

  }
}
