import {AfterViewInit, Component} from '@angular/core';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Risk, RiskService} from '../../services/risk.service';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from "@angular/router";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss']
})
export class SearchDialogComponent implements AfterViewInit {
  hasError = false;
  isLoading = false;
  errorMessage = '';
  searchQuery = '';
  risksDataSource: MatTableDataSource<Risk> = new MatTableDataSource<Risk>([]);
  displayedColumns: string[] = ['description', 'risk_assessment-name', 'project-name', 'created_at', 'risk_buttons_suffix'];

  constructor(
    private riskService: RiskService,
    private router: Router,
    private dialogRef: MatDialogRef<SearchDialogComponent>,
  ) {
  }

  doSearch() {
    if (this.searchQuery.length > 5) {
      this.riskService.search(this.searchQuery).subscribe((risks: Risk[]) => {
        this.isLoading = false;
        this.risksDataSource = new MatTableDataSource(risks);
      })
    }
  }

  ngAfterViewInit(): void {
    this.isLoading = true;
  }

  goToRisk(risk) {
    this.router.navigate([
      '/projects',
      risk.risk_assessment.project.id,
      'risk-assessments',
      risk.risk_assessment.id,
    ], {
      queryParams: {
        risk: risk.id
      }
    });
    this.dialogRef.close();
  }
}
