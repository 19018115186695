import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AuthGuard} from './guards/auth.guard';
import {MainLayoutComponent} from './main-layout/main-layout.component';
import {UsersComponent} from './users/users.component';
import {UnauthGuard} from './guards/unauth-guard.service';
import {PermissionGuard} from './guards/permission.guard';
import {ForbiddenComponent} from './forbidden/forbidden.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {PasswordResetCompleteComponent} from './password-reset-complete/password-reset-complete.component';
import {MyProfileComponent} from './my-profile/my-profile.component';
import {RisksComponent} from './risks/risks.component';
import {ProjectsComponent} from './projects/projects.component';
import {RiskAssessmentsComponent} from './risk-assessments/risk-assessments.component';
import {RiskTemplatesComponent} from './risk-assessment-templates/risk-templates/risk-templates.component';
import {TemplatesComponent} from './templates/templates.component';


const routes: Routes = [
  {path: 'login', canActivate: [UnauthGuard], component: LoginComponent},
  {path: 'reset-password', canActivate: [UnauthGuard], component: ResetPasswordComponent},
  {path: 'password-reset-complete', canActivate: [UnauthGuard], component: PasswordResetCompleteComponent},
  {
    path: '', component: MainLayoutComponent, canActivate: [AuthGuard], children: [
      {path: 'forbidden', component: ForbiddenComponent},
      {path: 'dashboard', redirectTo: 'projects', pathMatch: 'full'},
      {
        path: 'templates',
        component: TemplatesComponent,
        canActivate: [PermissionGuard],
        data: {permissions: ['templates-read']}
      },
      {
        path: 'templates/risk-assessments/:riskAssessmentId',
        component: RiskTemplatesComponent,
        canActivate: [PermissionGuard],
        data: {permissions: ['risk_assessments_templates-update']}
      },
      {
        path: 'templates/project/:projectId/risk-assessments',
        component: RiskAssessmentsComponent,
        canActivate: [PermissionGuard],
        data: {permissions: ['project_templates-read']}
      },
      {
        path: 'templates/project/:projectId/risk-assessments/:riskAssessmentId',
        component: RisksComponent,
        canActivate: [PermissionGuard],
        data: {permissions: ['risks-read']}
      },
      {
        path: 'projects',
        component: ProjectsComponent,
        canActivate: [PermissionGuard],
        data: {permissions: ['projects-read']}
      },
      {
        path: 'projects/:projectId/risk-assessments',
        component: RiskAssessmentsComponent,
        canActivate: [PermissionGuard],
        data: {permissions: ['risk_assessments-read']}
      },
      {
        path: 'projects/:projectId/risk-assessments/:riskAssessmentId',
        component: RisksComponent,
        canActivate: [PermissionGuard],
        data: {permissions: ['risks-read']}
      },
      {path: 'users', component: UsersComponent, canActivate: [PermissionGuard], data: {permissions: ['users-create']}},
      {path: 'my-profile', component: MyProfileComponent},
      {path: '', redirectTo: 'projects', pathMatch: 'full'},
      {path: '**', component: PageNotFoundComponent},
    ]
  },
  {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
