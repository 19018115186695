import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {version} from '../../../package.json'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public version: string = version;
  form: FormGroup;
  isLoading: boolean;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', Validators.email],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.isLoading = true;
      this.authService.resetPassword(this.form.value).subscribe((result: boolean) => {
        if (result) {
          this.router.navigateByUrl('/password-reset-complete');
        } else {
          this.isLoading = false;
        }
      }, () => {
        this.isLoading = false;
      })
    }
  }
}
