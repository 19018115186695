import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {RiskCategory} from './risk-category.service';
import {HttpClient} from '@angular/common/http';

export interface RiskActionPhase {
  id: number,
  display_name: string,
}

@Injectable({
  providedIn: 'root'
})
export class RiskActionPhaseService {

  apiEndpoint = 'risk_action_phases';

  constructor(
    private http: HttpClient,
  ) {
  }

  index(): Observable<Array<RiskCategory>> {
    return this.http.get<Array<RiskCategory>>(environment.api_url + this.apiEndpoint, {withCredentials: true});
  }
}
