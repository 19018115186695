import {AfterContentInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ReplaySubject, Subject, Subscription} from 'rxjs';
import {Risk, RiskService} from '../../services/risk.service';
import {MatSelect} from '@angular/material/select';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {debounceTime, filter, mergeMap, takeUntil, tap} from 'rxjs/operators';
import {RiskAssessment} from '../../services/risk-assessment.service';
import {Project} from '../../services/project.service';

@Component({
  selector: 'app-import-risks-dialog',
  templateUrl: './import-risks-dialog.component.html',
  styleUrls: ['./import-risks-dialog.component.scss']
})
export class ImportRisksDialogComponent implements OnInit, AfterContentInit, OnDestroy {
  form: FormGroup;
  isLoading: boolean;
  hasError: boolean;
  errorMessage: string;
  riskFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  risks: Array<Risk>;
  filteredRisks: ReplaySubject<Risk[]> = new ReplaySubject<Risk[]>(1);
  @ViewChild('templateSelect', {static: true}) singleSelect: MatSelect;
  private sub: Subscription;
  searching: boolean;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      riskAssessment: RiskAssessment,
      project: Project | null,
    },
    private fb: FormBuilder,
    private riskService: RiskService,
    private dialogRef: MatDialogRef<ImportRisksDialogComponent>,
    public authService: AuthService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      from_risk_ids: ['', Validators.required],
    });
  }

  importRisks() {
    if (this.form.valid) {
      this.form.disable();
      this.isLoading = true;
      this.riskService.create(this.data.project, this.data.riskAssessment, this.form.value).subscribe((risks: Risk[]) => {
        this.snackBar.open('Risks imported successfully');
        this.dialogRef.close(risks);
      }, (e) => {
        if (e.error?.errors) {
          this.errorMessage = Object.values(e.error.errors).join(', ');
        }
        this.hasError = true;
        this.isLoading = false;
        this.form.enable();
      })
    }
  }

  ngAfterContentInit(): void {
    /*this.sub = this.riskService.index().pipe().subscribe((risks: Array<Risk>) => {
      this.risks = risks;
      this.filteredRisks.next(this.risks.slice());
    });*/

    /*this.riskFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRisks();
      });*/

    this.sub = this.riskFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        mergeMap(search => {
          return this.riskService.search(search);
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe((filteredRisks: Risk[]) => {
          this.searching = false;
          this.filteredRisks.next(filteredRisks);
        },
        () => {
          // no errors in our simulated example
          this.searching = false;
          // handle error...
        });

  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.sub.unsubscribe();
  }

  protected filterRisks() {
    if (!this.risks) {
      return;
    }
    // get the search keyword
    let search = this.riskFilterCtrl.value;
    if (!search) {
      this.filteredRisks.next(this.risks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredRisks.next(
      this.risks.filter(risk => risk.description.toLowerCase().indexOf(search) > -1)
    );
  }

}
