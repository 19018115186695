<h1 mat-dialog-title i18n="@@saveAsTemplateHeader">Save as Template</h1>
<form [formGroup]="form" (ngSubmit)="createTemplate()">
  <div mat-dialog-content fxLayout="column" fxLayoutGap="16px">
    <span i18n="@@saveRiskAssessmentAsTemplateDialogText">Save Risk Assessment as Template</span>
    <div class="error" *ngIf="hasError">
      <span *ngIf="this.errorMessage">{{this.errorMessage}}</span>
      <span *ngIf="!this.errorMessage" i18n="@@dialogError">There was an error, please try it again.</span>
    </div>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@riskAssessmentNameInputLabel">Name</mat-label>
        <input matInput i18n-placeholder="@@riskAssessmentNamePlaceholder" placeholder="Example Risk Assessment" required formControlName="name">
        <mat-hint i18n="@@riskAssessmentNameInputHint">Enter the name of the risk assessment subject</mat-hint>
        <mat-error i18n="@@riskAssessmentNameInputError">
          Please provide the name of the risk assessment subject
        </mat-error>
      </mat-form-field>
    </p>
  </div>
  <mat-dialog-actions fxLayoutAlign="end" fxLayoutGap="16px">
    <a mat-button mat-dialog-close [disabled]="isLoading" i18n="@@cancelBtnLabel">Cancel</a>
    <button mat-button color="primary" [loading]="isLoading" i18n="@@createTemplateBtnLabel">Create Template</button>
  </mat-dialog-actions>
</form>

