<section class="container" fxLayout="column">

  <mat-toolbar color="primary" class="dark-theme">
    <button mat-button [loading]="loadingProject" color="accent" *ngIf="loadingProject"></button>
    <button mat-button routerLink="/projects" *ngIf="!loadingProject && !project.is_template">
      <mat-icon>folder</mat-icon>
      <span i18n="@@projectsBreadcrumbLabel">Projects</span>
    </button>
    <button mat-button routerLink="/templates" *ngIf="!loadingProject && project.is_template">
      <mat-icon>library_books</mat-icon>
      <span i18n="@@templatesBreadcrumbLabel">Templates</span>
    </button>
    <mat-icon>arrow_right</mat-icon>
    <button mat-button [disableRipple]="true" [loading]="loadingProject" [color]="loadingProject ? 'accent' : null">
      <span *ngIf="!loadingProject"><span *ngIf="!project.is_template">{{project.case_number}} - </span>{{project.name}}</span>
    </button>

    <span class="row-spacer"></span>

    <button [matMenuTriggerFor]="createMenu"
            [loading]="loadingProject"
            *ngIf="(
            authService.can('projects-create')
            || authService.can('project_templates-create')
            )"
            mat-flat-button color="accent">
      <span i18n="@@createNewBtnLabel">Create</span>
      <mat-icon aria-label="Add new project">expand_more</mat-icon>
    </button>


    <mat-menu #createMenu="matMenu" xPosition="before">
      <ng-template matMenuContent let-name="name">
        <button mat-menu-item (click)="openNewRiskAssessmentDialog()"
                *ngIf="authService.can('projects-create')">
          <mat-icon>note_add</mat-icon>
          <span i18n="@@blankRiskAssessmentBtnLabel">Blank Risk Assessment</span>
        </button>
        <button mat-menu-item (click)="openNewRiskAssessmentFromTemplateDialog()"
                *ngIf="authService.can('project_templates-create')">
          <mat-icon>library_add</mat-icon>
          <span i18n="@@riskAssessmentFromTemplateBtnLabel">Risk Assessment From Template</span>
        </button>
      </ng-template>
    </mat-menu>

  </mat-toolbar>

  <mat-card class="mat-elevation-z15 no-container-padding no-padding-horizontal">

    <app-risk-assessments-list #riskAssessmentsListComponent *ngIf="!loadingProject" [templates]="false" [project]="project"></app-risk-assessments-list>

  </mat-card>

</section>
