import {AfterContentInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Project} from '../../services/project.service';
import {RiskAssessment} from '../../services/risk-assessment.service';
import {Risk} from '../../services/risk.service';
import {RiskAction} from '../../services/risk-action.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {ReplaySubject, Subject} from 'rxjs';
import {User, UserService} from '../../services/user.service';
import {take, takeUntil} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {RiskActionResult, RiskActionResultService} from '../../services/risk-action-result.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-new-action-result-dialog',
  templateUrl: './new-action-result-dialog.component.html',
  styleUrls: ['./new-action-result-dialog.component.scss']
})
export class NewActionResultDialogComponent implements OnInit, AfterContentInit {

  form: FormGroup;
  hasError: boolean;
  errorMessage: string;
  isLoading: boolean;
  @ViewChild('executedBySelect', {static: true}) singleSelect: MatSelect;
  userFilterCtrl: FormControl = new FormControl();
  filteredUsers: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  private users: Array<User>;
  protected _onDestroy = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { project: Project, riskAssessment: RiskAssessment, risk: Risk, action: RiskAction },
    private fb: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private riskActionResultService: RiskActionResultService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<NewActionResultDialogComponent>,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      new_probability: [this.data.risk.initial_probability, Validators.required],
      new_consequence: [this.data.risk.initial_consequence, Validators.required],
      description: ['', Validators.required],
      executed_at: ['', Validators.required],
      executed_by: ['', Validators.required],
    });
    this.form.controls.executed_at.setValue(new Date());
  }

  createNewResult() {
    if (this.form.valid) {
      this.form.disable();
      this.isLoading = true;
      this.form.controls.executed_by.setValue(this.form.controls.executed_by.value.id);
      this.riskActionResultService.create(this.data.project, this.data.riskAssessment, this.data.risk, this.data.action,
        this.form.value
      ).subscribe((result: RiskActionResult) => {
        this.snackBar.open('Result is saved successfully');
        this.dialogRef.close(result);
      }, (e) => {
        if (e.error?.errors) {
          this.errorMessage = Object.values(e.error.errors).join(', ');
        }
        this.hasError = true;
        this.isLoading = false;
        this.form.enable();
      })
    }
  }

  ngAfterContentInit(): void {

    this.userService.index().pipe(take(1)).subscribe((users: Array<User>) => {
      this.users = users;
      // set initial created by to be current user
      this.authService.getUser().pipe(take(1)).subscribe((user: User) => {
        this.form.controls.executed_by.setValue(this.users.filter((obj: User) => obj.id === user.id).pop());
        this.setInitialUserSearchValue();
      });
      this.filteredUsers.next(this.users.slice());
    });

    this.userFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterUsers();
      });

  }

  protected filterUsers() {
    if (!this.users) {
      return;
    }
    // get the search keyword
    let search = this.userFilterCtrl.value;
    if (!search) {
      this.filteredUsers.next(this.users.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredUsers.next(
      this.users.filter(user => user.name.toLowerCase().indexOf(search) > -1)
    );
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialUserSearchValue() {
    this.filteredUsers
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: User, b: User) => a && b && a.id === b.id;
      });
  }
}
