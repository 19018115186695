import {AfterContentInit, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {RiskAssessment, RiskAssessmentService} from '../../services/risk-assessment.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../services/auth.service';
import {take, takeUntil} from 'rxjs/operators';
import {Project, ProjectService} from '../../services/project.service';
import {Risk, RiskService} from '../../services/risk.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-copy-risk-to-dialog',
  templateUrl: './copy-risk-to-dialog.component.html',
  styleUrls: ['./copy-risk-to-dialog.component.scss']
})
export class CopyRiskToDialogComponent implements OnInit, AfterContentInit {
  form: FormGroup;
  isLoading: boolean;
  hasError: boolean;
  errorMessage: string;
  protected _onDestroy = new Subject<void>();
  riskAssessmentFilterCtrl: FormControl = new FormControl();
  filteredRiskAssessments: ReplaySubject<RiskAssessment[]> = new ReplaySubject<RiskAssessment[]>(1);
  private riskAssessments: Array<RiskAssessment>;

  projectFilterCtrl: FormControl = new FormControl();
  filteredProjects: ReplaySubject<Project[]> = new ReplaySubject<Project[]>(1);
  private projects: Array<Project>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public selectedRisks,
    private fb: FormBuilder,
    private riskAssessmentService: RiskAssessmentService,
    private projectService: ProjectService,
    public authService: AuthService,
    private riskService: RiskService,
    private dialogRef: MatDialogRef<CopyRiskToDialogComponent>,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      project: ['', Validators.required],
      risk_assessment: ['', Validators.required],
    });
  }

  ngAfterContentInit(): void {

    this.projectService.index().pipe(take(1)).subscribe((projects: Array<Project>) => {
      this.projects = projects;
      this.filteredProjects.next(this.projects.slice());
    });

    this.projectFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProjects();
      });
  }

  changeProjectInput() {

    this.riskAssessmentService.index(this.form.controls.project.value.id).pipe(take(1)).subscribe((riskAssesments: Array<RiskAssessment>) => {
      this.riskAssessments = riskAssesments;
      this.filteredRiskAssessments.next(this.riskAssessments.slice());
    });

    this.riskAssessmentFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRiskAssessments();
      });


  }

  protected filterRiskAssessments() {
    if (!this.riskAssessments) {
      return;
    }
    // get the search keyword
    let search = this.riskAssessmentFilterCtrl.value;
    if (!search) {
      this.filteredRiskAssessments.next(this.riskAssessments.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredRiskAssessments.next(
      this.riskAssessments.filter(user => user.name.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterProjects() {
    if (!this.projects) {
      return;
    }
    // get the search keyword
    let search = this.projectFilterCtrl.value;
    if (!search) {
      this.filteredProjects.next(this.projects.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredProjects.next(
      this.projects.filter(project => project.name.toLowerCase().indexOf(search) > -1)
    );
  }

  copySelectedRisksTo() {
    if (this.form.valid) {
      this.form.disable();
      this.isLoading = true;
      this.riskService.create(this.form.controls.project.value, this.form.controls.risk_assessment.value, {from_risk_ids: this.selectedRisks.map(risk => risk.id)}).subscribe(
        (risks: Risk[]) => {
          this.dialogRef.close({
            project: this.form.controls.project.value,
            risk_assessment: this.form.controls.risk_assessment.value,
            risk: risks[0],
          });
        },
        (e) => {
          if (e.error?.errors) {
            this.errorMessage = Object.values(e.error.errors).join(', ');
          }
          this.hasError = true;
          this.isLoading = false;
          this.form.enable();

        })
    }
  }


}
