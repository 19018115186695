import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {catchError, map, mergeMap, share, take} from 'rxjs/operators';
import {Permission, User} from './user.service';
import {SuccessResponse} from '../app.component';

interface LoginCredentials {
  email: string,
  password: string;
}

interface UpdateSecurity {
  old_password: string,
  new_password: string,
  new_password2: string,
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user: User;

  constructor(
    private http: HttpClient,
  ) {
  }

  login(loginCredentials: LoginCredentials): Observable<boolean> {
    return this.getCookie().pipe(
      mergeMap(() => this.http.post(environment.api_url + 'login', loginCredentials, {withCredentials: true}).pipe(
        mergeMap((result: { success }) => {
          if (result.success) {
            localStorage.setItem('IsLoggedIn', '1');
            return of(true);
          } else {
            return of(false);
          }
        })
      )),
      take(1),
    );
  }

  logout() {
    return this.http.post(environment.api_url + 'logout', {}, {withCredentials: true}).pipe(
      map((result: { success }) => {
        if (result.success) {
          localStorage.removeItem('IsLoggedIn');
          window.location.reload();
        } else {
          return false;
        }
      }),
      take(1),
      catchError(err => {
        localStorage.removeItem('IsLoggedIn');
        window.location.reload();
        return of(err);
      })
    );
  }

  private getCookie(): Observable<null> {
    return this.http.get<null>(environment.api_url + 'sanctum/csrf-cookie', {withCredentials: true}).pipe(take(1));
  }

  getUser(): Observable<User> {
    return this.http.get<User>(environment.api_url + 'user', {withCredentials: true}).pipe(
      map((_user: User) => {
        this.user = _user;
        return _user;
      })
    );
  }

  can(permissionName: string): boolean {
    return this.user.permissions.some((permission: Permission) => permission.name === permissionName);
  }

  hasRole(roleName: string): boolean {
    return this.user.role.name === roleName;
  }

  resetPassword(value: { email: string }): Observable<boolean> {
    return this.http.post<SuccessResponse>(environment.api_url + 'reset-password', value, {withCredentials: true}).pipe(
      map((response: SuccessResponse) => response.success),
      take(1),
    );
  }

  updateSecurity(credentials: UpdateSecurity): Observable<boolean> {
    return this.http.post<SuccessResponse>(environment.api_url + 'update-password', credentials, {withCredentials: true}).pipe(
      map((response: SuccessResponse) => response.success),
      take(1),
    );
  }

  isLoggedIn() {
    return localStorage.getItem('IsLoggedIn') === '1';
  }
}
