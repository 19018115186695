<div fxLayout="row" fxFill class="unauthenticated">

  <div fxFlex.gt-md="35" fxFlex="100" fxLayout="row" fxLayoutAlign="center" class="login_section_left">

    <div fxFlex="70" fxFlex.gt-sm="50" fxFlex.gt-md="70" fxLayout="column" fxLayoutGap="16px">

      <span>
        <img src="assets/img/logo_blue.png" alt="Seco logo image" class="logo">
      </span>

      <span class="mat-headline" i18n="@@appTitle">Risk Analysis Platform</span>

      <span class="mat-subheader" i18n="@@resetPasswordCompleteHeader">Reset password complete</span>

      <span class="mat-body">
        <p i18n="@@resetPasswordCompleteText1">If your email address is known, we've sent you instructions on how to retrieve access to your account.</p>
        <p i18n="@@resetPasswordCompleteText2">You can now close this page and look in your email inbox for further instructions.</p>
      </span>

      <a mat-flat-button color="primary" routerLink="/login" i18n="@@backToLoginBtnLabel">Back to login</a>

    </div>

  </div>

  <section fxFlex.gt-md="65" fxFlex="0" class="login_section_right dark-theme" fxLayoutAlign="end end">
    <small class="version_number">v{{version}}</small>
  </section>

</div>
