<div mat-dialog-content fxLayout="column" fxLayoutGap="16px">

  <div class="p-h-24" fxLayout="column" fxLayoutGap="16px">

    <span i18n="@@searchDialogDescriptiom">Search for risks by description</span>
    <div class="error" *ngIf="hasError">
      <span *ngIf="this.errorMessage">{{this.errorMessage}}</span>
      <span *ngIf="!this.errorMessage" i18n="@@dialogError">There was an error, please try it again.</span>
    </div>
    <mat-form-field appearance="outline" class="no-padding">
      <span matPrefix><mat-icon>search</mat-icon></span>
      <input type="text" matInput i18n-placeholder="@@" placeholder="Search for risks" (keyup)="doSearch()"
             [(ngModel)]="searchQuery">
    </mat-form-field>

  </div>

  <table
    mat-table
    [dataSource]="risksDataSource"
    [fxHide]="isLoading"
  >

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef i18n="@@descriptionTableHead">Description</th>
      <td mat-cell *matCellDef="let risk"> {{risk.description}} </td>
    </ng-container>

    <ng-container matColumnDef="risk_assessment-name">
      <th mat-header-cell *matHeaderCellDef i18n="@@riskAssessmentNameTableHead2">Risk Assessment</th>
      <td mat-cell *matCellDef="let risk"> {{risk.risk_assessment.name}} </td>
    </ng-container>

    <ng-container matColumnDef="project-name">
      <th mat-header-cell *matHeaderCellDef i18n="@@projectNameTableHead2">Project</th>
      <td mat-cell
          *matCellDef="let risk"> {{risk.risk_assessment.project.case_number}} {{risk.risk_assessment.project.name}} </td>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef i18n="@@createdAtTableHead">Created At</th>
      <td mat-cell *matCellDef="let risk"> {{risk.identified_at | date: 'longDate'}} </td>
    </ng-container>

    <ng-container matColumnDef="risk_buttons_suffix">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let risk">
        <div fxLayoutAlign="end">
          <mat-icon>chevron_right</mat-icon>
        </div>
      </td>
    </ng-container>

    <!--Risk table headers row-->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <!--Risk row-->
    <tr mat-row
        class="clickable hover"
        *matRowDef="let risk; columns: displayedColumns;"
        (click)="goToRisk(risk)"
    ></tr>


  </table>

</div>
