import {Component, OnInit} from '@angular/core';
import {version} from '../../../package.json'


@Component({
  selector: 'app-password-reset-complete',
  templateUrl: './password-reset-complete.component.html',
  styleUrls: ['./password-reset-complete.component.scss']
})
export class PasswordResetCompleteComponent implements OnInit {
  public version: string = version;

  constructor() {
  }

  ngOnInit(): void {
  }

}
