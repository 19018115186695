import {AfterContentInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Project, ProjectService} from '../../services/project.service';
import {MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {take, takeUntil} from 'rxjs/operators';
import {ReplaySubject, Subject, Subscription} from 'rxjs';
import {User, UserService} from '../../services/user.service';
import {MatSelect} from '@angular/material/select';

@Component({
  selector: 'app-new-project-from-template-dialog',
  templateUrl: './new-project-from-template-dialog.component.html',
  styleUrls: ['./new-project-from-template-dialog.component.scss']
})
export class NewProjectFromTemplateDialogComponent implements OnInit, AfterContentInit, OnDestroy {
  form: FormGroup;
  isLoading: boolean;
  hasError: boolean;
  errorMessage: string;
  projectFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  projects: Array<Project>;
  filteredProjects: ReplaySubject<Project[]> = new ReplaySubject<Project[]>(1);
  @ViewChild('templateSelect', {static: true}) singleSelect: MatSelect;
  private sub: Subscription;


  constructor(
    private fb: FormBuilder,
    private projectService: ProjectService,
    private dialogRef: MatDialogRef<NewProjectFromTemplateDialogComponent>,
    public authService: AuthService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      from_template_ids: ['', Validators.required],
      name: ['', Validators.required],
      case_number: ['PSNL-', Validators.required],
    });
  }

  createNewProject() {
    if (this.form.valid) {
      this.form.disable();
      this.isLoading = true;
      this.projectService.create(this.form.value).subscribe((project: Project) => {
        this.snackBar.open('Project \"' + this.form.controls.name.value + '\" is created successfully');
        this.dialogRef.close(project);
      }, (e) => {
        if (e.error?.errors) {
          this.errorMessage = Object.values(e.error.errors).join(', ');
        }
        this.hasError = true;
        this.isLoading = false;
        this.form.enable();
      })
    }
  }

  ngAfterContentInit(): void {
    this.sub = this.projectService.indexTemplates().pipe().subscribe((projects: Array<Project>) => {
      this.projects = projects;
      this.filteredProjects.next(this.projects.slice());
    });

    this.projectFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterUsers();
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  protected filterUsers() {
    if (!this.projects) {
      return;
    }
    // get the search keyword
    let search = this.projectFilterCtrl.value;
    if (!search) {
      this.filteredProjects.next(this.projects.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredProjects.next(
      this.projects.filter(user => user.name.toLowerCase().indexOf(search) > -1)
    );
  }

}
