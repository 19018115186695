import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatCardModule} from '@angular/material/card';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {MainLayoutComponent} from './main-layout/main-layout.component';
import {ProjectsComponent} from './projects/projects.component';
import {
  UsersComponent,
  ConfirmDeleteUserDialogComponent,
  EditUserDialogComponent,
  NewUserDialogComponent
} from './users/users.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpXsrfInterceptor} from './interceptors/HttpXsrfInterceptor';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {AuthInterceptor, ForbiddenErrorDialogComponent} from './interceptors/AuthInterceptor';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonLoadingDirective} from './directives/mat-button-loading.directive';
import {LetterLogoComponent} from './components/letter-logo/letter-logo.component';
import {CachingInterceptor} from './interceptors/CachingInterceptor';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {ForbiddenComponent} from './forbidden/forbidden.component';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {PasswordResetCompleteComponent} from './password-reset-complete/password-reset-complete.component';
import {MyProfileComponent} from './my-profile/my-profile.component';
import {
  ConfirmDeleteRiskAssessmentDialogComponent,
  EditRiskAssessmentDialogComponent,
  NewRiskAssessmentDialogComponent,
  RiskAssessmentsListComponent, SaveRiskAssessmentAsTemplateDialogComponent
} from './risk-assessments/risk-assessments-list/risk-assessments-list.component';
import {
  ConfirmDeleteRiskDialogComponent,
  EditRiskDialogComponent,
  NewRiskDialogComponent,
  RisksComponent
} from './risks/risks.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ColoredRiskBlockComponent} from './components/colored-risk-block/colored-risk-block.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {NewActionDialogComponent} from './risks/new-action-dialog/new-action-dialog.component';
import {EditActionDialogComponent} from './risks/edit-action-dialog/edit-action-dialog.component';
import {
  ConfirmDeleteActionDialogComponent
} from './risks/confirm-delete-action-dialog/confirm-delete-action-dialog.component';
import {NewActionResultDialogComponent} from './risks/new-action-result-dialog/new-action-result-dialog.component';
import {EditActionResultDialogComponent} from './risks/edit-action-result-dialog/edit-action-result-dialog.component';
import {
  ConfirmDeleteActionResultDialogComponent
} from './risks/confirm-delete-action-result-dialog/confirm-delete-action-result-dialog.component';
import {
  ConfirmDeleteProjectDialogComponent,
  EditProjectDialogComponent,
  NewProjectDialogComponent,
  ProjectsTableComponent, SaveProjectAsTemplateDialogComponent
} from './projects/projects-table/projects-table.component';
import {
  NewProjectFromTemplateDialogComponent
} from './projects/new-project-from-template-dialog/new-project-from-template-dialog.component';
import {RiskAssessmentsComponent} from './risk-assessments/risk-assessments.component';
import {RiskTemplatesComponent} from './risk-assessment-templates/risk-templates/risk-templates.component';
import {
  NewRiskAssessmentFromTemplateDialogComponent
} from './risk-assessments/new-risk-assessment-from-template-dialog/new-risk-assessment-from-template-dialog.component';
import {TemplatesComponent} from './templates/templates.component';
import {
  DownloadRiskAssessmentPDFDialogComponent
} from './risks/download-risk-assessment-pdfdialog/download-risk-assessment-pdfdialog.component';
import {LanguageInterceptor} from './interceptors/language.interceptor';
import {MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {ImportRisksDialogComponent} from './risks/import-risks-dialog/import-risks-dialog.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { CopyRiskToDialogComponent } from './risks/copy-risk-to-dialog/copy-risk-to-dialog.component';
import {MatChipsModule} from "@angular/material/chips";
import { SearchDialogComponent } from './search/search-dialog/search-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    DashboardComponent,
    MainLayoutComponent,
    ProjectsComponent,
    UsersComponent,
    MatButtonLoadingDirective,
    LetterLogoComponent,
    NewUserDialogComponent,
    EditUserDialogComponent,
    ConfirmDeleteUserDialogComponent,
    ForbiddenComponent,
    ForbiddenErrorDialogComponent,
    ResetPasswordComponent,
    PasswordResetCompleteComponent,
    MyProfileComponent,
    NewProjectDialogComponent,
    EditProjectDialogComponent,
    ConfirmDeleteProjectDialogComponent,
    RiskAssessmentsListComponent,
    NewRiskAssessmentDialogComponent,
    EditRiskAssessmentDialogComponent,
    ConfirmDeleteRiskAssessmentDialogComponent,
    RisksComponent,
    NewRiskDialogComponent,
    EditRiskDialogComponent,
    ConfirmDeleteRiskDialogComponent,
    ColoredRiskBlockComponent,
    NewActionDialogComponent,
    EditActionDialogComponent,
    ConfirmDeleteActionDialogComponent,
    NewActionResultDialogComponent,
    EditActionResultDialogComponent,
    ConfirmDeleteActionResultDialogComponent,
    ProjectsTableComponent,
    NewProjectFromTemplateDialogComponent,
    RiskAssessmentsComponent,
    RiskTemplatesComponent,
    NewRiskAssessmentFromTemplateDialogComponent,
    TemplatesComponent,
    SaveRiskAssessmentAsTemplateDialogComponent,
    SaveProjectAsTemplateDialogComponent,
    DownloadRiskAssessmentPDFDialogComponent,
    ImportRisksDialogComponent,
    CopyRiskToDialogComponent,
    SearchDialogComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        MatCardModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatTabsModule,
        MatIconModule,
        FormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        HttpClientModule,
        MatMenuModule,
        MatDividerModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatAutocompleteModule,
        NgxMatSelectSearchModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatMomentDateModule,
        DragDropModule,
        MatChipsModule,
    ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 5000, horizontalPosition: 'left'}},
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
