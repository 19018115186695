import {AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Project, ProjectService} from '../../services/project.service';
import {Observable, Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-projects-table',
  templateUrl: './projects-table.component.html',
  styleUrls: ['./projects-table.component.scss']
})
export class ProjectsTableComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() templates: boolean;
  @Input() archive: boolean;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[];

  dataSource: MatTableDataSource<Project> = new MatTableDataSource<Project>([]);
  isLoading: boolean;
  indexProjectsSub: Subscription;
  projects: Array<Project>;
  resultsLength = 0;


  constructor(
    public projectService: ProjectService,
    private dialog: MatDialog,
    public authService: AuthService,
    public snackBar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.displayedColumns =
      this.templates ?
        ['symbol', 'name', 'last_activity', 'created_at', 'actions']
        : ['symbol', 'name', 'case_number', 'last_activity', 'created_at', 'actions'];
  }

  ngAfterViewInit(): void {
    this.indexProjectsSub = this.refresh().subscribe(() => {
    }, () => {
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.indexProjectsSub.unsubscribe();
  }

  openDeleteProjectDialog(project: Project) {
    const dialogRef = this.dialog.open(ConfirmDeleteProjectDialogComponent, {data: project});
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // remove the project from the table
        let data = this.dataSource.data;
        data = data.filter(obj => obj !== project);
        this.dataSource.data = data;
      }
    });
  }

  openEditProjectDialog(_project: any) {
    const dialogRef = this.dialog.open(EditProjectDialogComponent, {data: _project, disableClose: true});
    dialogRef.afterClosed().subscribe(project => {
      if (project) {
        const data = this.dataSource.data;
        const projectIndex = data.findIndex((_p => _p.id === project.id));
        data[projectIndex] = project;
        this.dataSource.data = data;
      }
    });
  }

  openSaveAsTemplateDialog(project: Project) {
    this.dialog.open(SaveProjectAsTemplateDialogComponent, {data: {project}});
  }

  unarchiveProject(_project: Project) {
    this.projectService.unarchive(_project).subscribe((project: Project) => {
      let data = this.dataSource.data;
      data = data.filter(obj => obj !== _project);
      this.dataSource.data = data;
      this.snackBar.open(`Project '${project.name}' has been unarchived`);
    });
  }

  archiveProject(_project: Project) {
    this.projectService.archive(_project).subscribe((project: Project) => {
      let data = this.dataSource.data;
      data = data.filter(obj => obj !== _project);
      this.dataSource.data = data;
      this.snackBar.open(`Project '${project.name}' has been archived`);
    });
  }

  refresh(): Observable<void> {
    let index$: Observable<Array<Project>>;
    if (this.templates) {
      index$ = this.projectService.indexTemplates();
    } else if (this.archive) {
      index$ = this.projectService.indexArchives();
    } else {
      index$ = this.projectService.index();
    }
    return index$.pipe(map((projects: Array<Project>) => {
        this.projects = projects;
        Promise.resolve(null).then(() => {
          this.isLoading = false;
          this.resultsLength = projects.length;
        });
        this.dataSource = new MatTableDataSource(projects);

        // custom sorting data accessor (when using child property's of objects)
        this.dataSource.sortingDataAccessor = (project: Project, property: string) => {
          switch (property) {
            case 'name':
              return project.name.toLowerCase();
            default:
              return project[property];
          }
        };

        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      })
    );
  }
}

@Component({
  selector: 'app-save-project-as-template-dialog',
  templateUrl: '../save-project-as-template-dialog.html',
  styles: ['mat-form-field { width: 100%; min-width: 350px; }']
})
export class SaveProjectAsTemplateDialogComponent implements OnInit {
  form: FormGroup;
  isLoading: boolean;
  hasError: boolean;
  errorMessage: string;
  isTemplate = false;

  constructor(
    private fb: FormBuilder,
    private projectService: ProjectService,
    private dialogRef: MatDialogRef<NewProjectDialogComponent>,
    public authService: AuthService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { project: Project },
  ) {
  }

  ngOnInit() {
    this.isTemplate = false;
    this.form = this.fb.group({
      name: [this.data.project.name, Validators.required],
      is_template: [true, Validators.required],
      from_template_id: [this.data.project.id, Validators.required]
    });
  }

  createTemplate() {
    if (this.form.valid) {
      this.form.disable();
      this.isLoading = true;
      this.projectService.create(this.form.value).subscribe((project: Project) => {
        this.snackBar.open('Project Template \"' + this.form.controls.name.value + '\" is created successfully');
        this.dialogRef.close(project);
      }, (e) => {
        if (e.error?.errors) {
          this.errorMessage = Object.values(e.error.errors).join(', ');
        }
        this.hasError = true;
        this.isLoading = false;
        this.form.enable();
      })
    }
  }
}


@Component({
  selector: 'app-new-project-dialog',
  templateUrl: '../new-project-dialog.html',
  styles: ['mat-form-field { width: 100%; min-width: 350px; }']
})
export class NewProjectDialogComponent implements OnInit {
  form: FormGroup;
  isLoading: boolean;
  hasError: boolean;
  errorMessage: string;
  isTemplate = false;

  constructor(
    private fb: FormBuilder,
    private projectService: ProjectService,
    private dialogRef: MatDialogRef<NewProjectDialogComponent>,
    public authService: AuthService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { isTemplate?: boolean },
  ) {
  }

  ngOnInit() {
    this.isTemplate = this.data && this.data.isTemplate ? this.data.isTemplate : false;
    if (this.isTemplate) {
      this.form = this.fb.group({
        name: ['', Validators.required],
        is_template: [true],
      });
    } else {
      this.form = this.fb.group({
        name: ['', Validators.required],
        case_number: ['PSNL-', Validators.required],
      });
    }
  }

  createNewProject() {
    if (this.form.valid) {
      this.form.disable();
      this.isLoading = true;
      this.projectService.create(this.form.value).subscribe((project: Project) => {
        this.snackBar.open('Project \"' + this.form.controls.name.value + '\" is created successfully');
        this.dialogRef.close(project);
      }, (e) => {
        if (e.error?.errors) {
          this.errorMessage = Object.values(e.error.errors).join(', ');
        }
        this.hasError = true;
        this.isLoading = false;
        this.form.enable();
      })
    }
  }
}

@Component({
  selector: 'app-edit-project-dialog',
  templateUrl: 'edit-project-dialog.html',
  styles: ['mat-form-field { width: 100%; min-width: 350px; }']
})
export class EditProjectDialogComponent implements OnInit {
  form: FormGroup;
  isLoading: boolean;
  hasError: boolean;
  errorMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public project: Project,
    private fb: FormBuilder,
    private projectService: ProjectService,
    private dialogRef: MatDialogRef<EditProjectDialogComponent>,
    public authService: AuthService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    if (this.project.is_template) {
      this.form = this.fb.group({
        name: ['', Validators.required],
      });
    } else {
      this.form = this.fb.group({
        name: ['', Validators.required],
        case_number: ['', Validators.required],
      });
    }
    this.form.patchValue(this.project);
  }

  updateProject() {
    if (this.form.valid) {
      this.form.disable();
      this.isLoading = true;
      if(this.project.is_template) {
        this.project = {...{id: this.project.id, is_template: this.project.is_template}, ...this.form.value};
      } else {
        this.project = {...{id: this.project.id}, ...this.form.value};
      }
      this.projectService.update(this.project).subscribe((project: Project) => {
        this.snackBar.open('Project \"' + this.project.name + '\" was updated successfully');
        this.isLoading = false;
        this.dialogRef.close(project);
      }, (e) => {
        if (e.error?.errors) {
          this.errorMessage = Object.values(e.error.errors).join(', ');
        }
        this.hasError = true;
        this.isLoading = false;
      })
    }
  }

}

@Component({
  selector: 'app-confirm-delete-project-dialog-component',
  templateUrl: 'confirm-delete-project-dialog.html',
  styles: ['p:first-child { margin-top: 0; }', 'p:last-child { margin-bottom: 0; }']
})
export class ConfirmDeleteProjectDialogComponent {
  isLoading: boolean;

  constructor(
    private projectService: ProjectService,
    private dialogRef: MatDialogRef<ConfirmDeleteProjectDialogComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public project: Project
  ) {
  }

  deleteProject() {
    this.isLoading = true;
    this.projectService.delete(this.project).subscribe((success: boolean) => {
      if (success) {
        this.dialogRef.close(true);
        this.snackBar.open('Project \"' + this.project.name + '\" is deleted');
      }
    }, () => {
      this.isLoading = false;
    });
  }

}
