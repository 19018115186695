import {Injectable} from '@angular/core';
import {User} from './user.service';
import {HttpClient} from '@angular/common/http';
import {Project, ProjectService} from './project.service';
import {RiskAssessment, RiskAssessmentService} from './risk-assessment.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {RiskCategory} from './risk-category.service';
import {map, take} from 'rxjs/operators';
import {RiskScope} from './risk-scope.service';
import {SuccessResponse} from '../app.component';
import {RiskAction} from './risk-action.service';

export interface Risk {
  id: string,
  number: number,
  category: RiskCategory,
  scope: RiskScope,
  created_by: User,
  description: string,
  identified_at: string,
  initial_probability: number,
  initial_consequence: number,
  highest_probability: number,
  highest_consequence: number,
  expanded: boolean,
  selected: boolean,
  actions: RiskAction[],
  risk_assessment?: RiskAssessment,
  completed: boolean,
}

export interface RiskScoreStep {
  score_from: number,
  score_to: number,
  color: string,
  textColor?: string,
}

@Injectable({
  providedIn: 'root'
})
export class RiskService {

  apiEndpoint = 'risks';

  public riskScoreSteps: RiskScoreStep[] = [
    {
      score_from: 0,
      score_to: 0,
      color: '#A4A4A4',
    },
    {
      score_from: 0,
      score_to: 5,
      color: '#00b050',
      textColor: '#ffffff',
    },
    {
      score_from: 5,
      score_to: 10,
      color: '#92d050',
      textColor: '#ffffff',
    },
    {
      score_from: 10,
      score_to: 20,
      color: '#ffc000',
      textColor: '#ffffff',
    },
    {
      score_from: 20,
      score_to: 40,
      color: '#ed7d31',
      textColor: '#ffffff',
    },
    {
      score_from: 40,
      score_to: 80,
      color: '#c00000',
      textColor: '#ffffff',
    },
  ]

  constructor(
    private http: HttpClient,
    private projectService: ProjectService,
    private riskAssessmentService: RiskAssessmentService,
  ) {
  }

  index(project: Project = null, riskAssessment: RiskAssessment = null): Observable<Array<Risk>> {
    if (!project && !riskAssessment) {
      return this.http.get<Array<Risk>>(
        environment.api_url +
        this.apiEndpoint, {withCredentials: true}
      );
    } else {
      return this.http.get<Array<Risk>>(
        environment.api_url +
        this.projectService.apiEndpoint + '/' + project.id + '/' +
        this.riskAssessmentService.apiEndpoint + '/' + riskAssessment.id + '/' +
        this.apiEndpoint, {withCredentials: true}
      );
    }
  }

  indexTemplates(riskAssessment: RiskAssessment): Observable<Array<Risk>> {
    return this.http.get<Array<Risk>>(
      environment.api_url +
      this.riskAssessmentService.apiEndpoint + '/' + riskAssessment.id + '/' +
      this.apiEndpoint,
      {withCredentials: true}
    );
  }

  create(project: Project | null, riskAssessment: RiskAssessment, risk: Risk | Risk[] | { from_risk_ids: Risk['id'] }): Observable<Risk | Risk[]> {
    return this.http.post<Risk | Risk[]>(
      environment.api_url +
      (project ? this.projectService.apiEndpoint + '/' + project.id + '/' : '') +
      this.riskAssessmentService.apiEndpoint + '/' + riskAssessment.id + '/' +
      this.apiEndpoint,
      risk,
      {withCredentials: true}
    ).pipe(take(1));
  }

  createTemplate(riskAssessment: RiskAssessment, risk: Risk): Observable<Risk> {
    return this.http.post<Risk>(
      environment.api_url +
      this.riskAssessmentService.apiEndpoint + '/' + riskAssessment.id + '/' +
      this.apiEndpoint,
      risk,
      {withCredentials: true}
    ).pipe(take(1));
  }


  update(project: Project, riskAssessment: RiskAssessment, risk: Risk): Observable<Risk> {
    return this.http.put<Risk>(
      environment.api_url +
      this.projectService.apiEndpoint + '/' + project.id + '/' +
      this.riskAssessmentService.apiEndpoint + '/' + riskAssessment.id + '/' +
      this.apiEndpoint + '/' + risk.id,
      risk,
      {withCredentials: true}
    ).pipe(take(1));
  }

  updateRiskNumbers(project: Project, riskAssessment: RiskAssessment, risksData: Risk[]): Observable<boolean> {
    return this.http.put<SuccessResponse>(
      environment.api_url +
      this.projectService.apiEndpoint + '/' + project.id + '/' +
      this.riskAssessmentService.apiEndpoint + '/' + riskAssessment.id + '/' +
      this.apiEndpoint,
      risksData,
      {withCredentials: true}
    ).pipe(
      map((response: SuccessResponse) => {
        return response.success;
      }),
      take(1)
    );
  }


  updateTemplate(riskAssessment: RiskAssessment, risk: Risk): Observable<Risk> {
    return this.http.put<Risk>(
      environment.api_url +
      this.riskAssessmentService.apiEndpoint + '/' + riskAssessment.id + '/' +
      this.apiEndpoint + '/' + risk.id,
      risk,
      {withCredentials: true}
    ).pipe(take(1));
  }

  delete(project: Project, riskAssessment: RiskAssessment, risk: Risk): Observable<boolean> {
    return this.http.delete<SuccessResponse>(
      environment.api_url +
      this.projectService.apiEndpoint + '/' + project.id + '/' +
      this.riskAssessmentService.apiEndpoint + '/' + riskAssessment.id + '/' +
      this.apiEndpoint + '/' + risk.id,
      {withCredentials: true}
    ).pipe(
      map((response: SuccessResponse) => {
        return response.success;
      }),
      take(1)
    );
  }

  deleteTemplate(riskAssessment: RiskAssessment, risk: Risk): Observable<boolean> {
    return this.http.delete<SuccessResponse>(
      environment.api_url +
      this.riskAssessmentService.apiEndpoint + '/' + riskAssessment.id + '/' +
      this.apiEndpoint + '/' + risk.id,
      {withCredentials: true}
    ).pipe(
      map((response: SuccessResponse) => {
        return response.success;
      }),
      take(1)
    );
  }

  search(search: string): Observable<Risk[]> {
    return this.http.get<Risk[]>(
      environment.api_url +
      this.apiEndpoint + '?query=' + search,
      {withCredentials: true}
    );
  }
}
