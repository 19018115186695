import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import { version } from '../../../package.json'
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public version: string = version;
  form: FormGroup;
  loginInvalid: boolean;
  isLoading: boolean;
  isProduction: boolean;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.isProduction = environment.production;

    this.form = this.fb.group({
      email: ['', Validators.email],
      password: ['', Validators.required],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.isLoading = true;
      this.authService.login(this.form.value).subscribe((result: boolean) => {
        if (result) {
          this.loginInvalid = false;
          this.router.navigateByUrl('/dashboard');
        } else {
          this.loginInvalid = true;
          this.isLoading = false;
        }
      }, () => {
        this.loginInvalid = true;
        this.isLoading = false;
      })
    }
  }
}
