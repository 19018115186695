import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from '../services/auth.service';
import {NewProjectDialogComponent, ProjectsTableComponent} from './projects-table/projects-table.component';
import {Project} from '../services/project.service';
import {NewProjectFromTemplateDialogComponent} from './new-project-from-template-dialog/new-project-from-template-dialog.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, OnDestroy {


  @ViewChild('search') search: ElementRef;
  @ViewChild('projectsTableComponent') projectsTableComponent: ProjectsTableComponent;
  @ViewChild('archiveTableComponent') archiveTableComponent: ProjectsTableComponent;

  searchActive: boolean;
  selectedTabIndex: number;
  private $refreshProjectTableSub: Subscription;
  private $refreshArchiveTableSub: Subscription;

  constructor(
    private dialog: MatDialog,
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.selectedTabIndex = parseInt(this.route.snapshot.queryParamMap.get('tab'), 10);
    if (isNaN(this.selectedTabIndex) || this.selectedTabIndex < 0) {
      this.selectedTabIndex = 0;
    }
  }

  openNewProjectDialog() {
    const dialogRef = this.dialog.open(NewProjectDialogComponent, {disableClose: true});
    dialogRef.afterClosed().subscribe((project: Project) => {
      if (project) {
        const data = this.projectsTableComponent.dataSource.data;
        data.push(project);
        this.projectsTableComponent.dataSource.data = data;
      }
    });
  }

  clearFilters() {
    this.search.nativeElement.value = '';
    this.projectsTableComponent.dataSource.filter = '';
    this.archiveTableComponent.dataSource.filter = '';
    this.searchActive = false;
  }

  applyFilter() {
    const filterValue = this.search.nativeElement.value;
    this.searchActive = filterValue.length > 0;
    this.projectsTableComponent.dataSource.filter = filterValue.trim().toLowerCase();
    this.archiveTableComponent.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openNewProjectFromTemplateDialog() {
    const dialogRef = this.dialog.open(NewProjectFromTemplateDialogComponent, {disableClose: true});
    dialogRef.afterClosed().subscribe((project: Project) => {
      if (project) {
        const data = this.projectsTableComponent.dataSource.data;
        data.push(project);
        this.projectsTableComponent.dataSource.data = data;
      }
    });
  }

  onTabChange(selectedTabIndex: number): void {
    this.router.navigate([], {
      relativeTo: this.route, queryParams: {
        tab: selectedTabIndex
      }
    });
    switch (selectedTabIndex) {
      case 0:
        this.$refreshProjectTableSub = this.projectsTableComponent.refresh().subscribe(() => {
          this.applyFilter();
        });
        break;
      case 1:
        this.$refreshArchiveTableSub = this.archiveTableComponent.refresh().subscribe(() => {
          this.applyFilter();
        });
        break;
    }
  }

  ngOnDestroy(): void {
    if (this.$refreshProjectTableSub) {
      this.$refreshProjectTableSub.unsubscribe();
    }
    if (this.$refreshArchiveTableSub) {
      this.$refreshArchiveTableSub.unsubscribe();
    }
  }

}
