<section class="container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">

  <img src="assets/img/secure.svg">

  <span class="mat-headline" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-icon>lock</mat-icon>
      <span i18n="@@forbiddenHeader">Forbidden</span>
    </span>

  <span class="mat-subheader" i18n="@@forbiddenText">You don't have permission to access this page</span>

  <button mat-raised-button color="accent" (click)="goBack()"><mat-icon>arrow_back</mat-icon> <span i18n="@@goBackBtnLabel">Go back</span></button>

</section>
