<h1 mat-dialog-title i18n="@@copyRiskToDialogHeader">Copy to</h1>
<form [formGroup]="form" (ngSubmit)="copySelectedRisksTo()">
  <div mat-dialog-content fxLayout="column" fxLayoutGap="16px">
    <span i18n="@@copyRiskToDialogText">Copy selected risks to another risk assessment</span>
    <div class="error" *ngIf="hasError">
      <span *ngIf="this.errorMessage">{{this.errorMessage}}</span>
      <span *ngIf="!this.errorMessage" i18n="@@dialogError">There was an error, please try it again.</span>
    </div>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@projectInputLabel">Project</mat-label>
        <mat-select (selectionChange)="changeProjectInput()" formControlName="project" i18n-placeholder="@@projectInputHint" placeholder="Chroose a project" #projectSelect>
          <mat-option>
            <ngx-mat-select-search
              [formControl]="projectFilterCtrl"
              noEntriesFoundLabel="Nothing found"
              i18n-noEntriesFoundLabel="@@executedByInputNoEntriesFound"
              i18n-placeholderLabel="@@executedByInputPlaceholder"
              placeholderLabel="Search"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let project of filteredProjects | async" [value]="project">
            {{project.name}}
          </mat-option>
        </mat-select>
        <mat-hint i18n="@@projectInputHint">Choose a project</mat-hint>
        <mat-error i18n="@@projectInputError">
          Please select a project
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@riskAssessmentInputLabel">Risk assessment</mat-label>
        <mat-select [disabled]="this.form.controls.project.value === ''" formControlName="risk_assessment" i18n-placeholder="@@riskAssesmentInputPlaceholder" placeholder="Choose a risk assessment" #riskAssessmentSelect>
          <mat-option>
            <ngx-mat-select-search
              [formControl]="riskAssessmentFilterCtrl"
              noEntriesFoundLabel="Nothing found"
              i18n-noEntriesFoundLabel="@@executedByInputNoEntriesFound"
              i18n-placeholderLabel="@@executedByInputPlaceholder"
              placeholderLabel="Search"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let riskAssessment of filteredRiskAssessments | async" [value]="riskAssessment">
            {{riskAssessment.name}}
          </mat-option>
        </mat-select>
        <mat-hint i18n="@@riskAssessmentInputHint">Choose a template to implement in the new project</mat-hint>
        <mat-error i18n="@@riskAssessmentInputError">
          Please select a risk assessment
        </mat-error>
      </mat-form-field>
    </p>
  </div>
  <mat-dialog-actions fxLayoutAlign="end" fxLayoutGap="16px">
    <a mat-button mat-dialog-close [disabled]="isLoading" i18n="@@cancelBtnLabel">Cancel</a>
    <button mat-button color="primary" [loading]="isLoading" i18n="@@copyBtnLabel">Copy</button>
  </mat-dialog-actions>
</form>

