<section class="container" fxLayout="column">

  <mat-toolbar color="primary" class="dark-theme">
    <button mat-button routerLink="/templates" [loading]="loadingRiskAssessment"
            [color]="loadingRiskAssessment ? 'accent' : null">
      <mat-icon>library_books</mat-icon>
      <span i18n="@@templatesHeader">Templates</span>
    </button>
    <mat-icon>arrow_right</mat-icon>
    <button mat-button [disableRipple]="true" [loading]="loadingRiskAssessment"
            [color]="loadingRiskAssessment ? 'accent' : null">
      <span *ngIf="!loadingRiskAssessment">{{riskAssessment.name}}</span>
    </button>

  </mat-toolbar>

  <div class="dark-theme" fxLayoutAlign="end center" fxLayoutGap="16px">

    <!--<span class="row-spacer"></span>-->

    <button [matMenuTriggerFor]="createMenu"
            *ngIf="(
            authService.can('projects-create')
            || authService.can('project_templates-create')
            )"
            [loading]="loadingRiskAssessment"
            mat-flat-button color="accent">
      <span i18n="@@createNewBtnLabel">Create New</span>
      <mat-icon aria-label="Add new item to risk assessment">expand_more</mat-icon>
    </button>

    <mat-menu #createMenu="matMenu" xPosition="before">
      <ng-template matMenuContent let-name="name">

        <button mat-menu-item (click)="openNewRiskDialog()"
                *ngIf="authService.can('risks-create')">
          <mat-icon>playlist_add</mat-icon>
          <span i18n="@@newRiskBtnLabel">New Risk</span>
        </button>

        <button mat-menu-item (click)="openImportRiskDialog()"
                *ngIf="authService.can('risks-create')">
          <mat-icon>manage_search</mat-icon>
          <span i18n="@@importRisk">Import risk</span>
        </button>

      </ng-template>
    </mat-menu>


  </div>

  <div class="dark-theme" fxLayoutAlign="start center" fxLayoutGap="16px">

    <span class="mat-subheader">Filter</span>

    <mat-form-field color="accent">
      <mat-label i18n="@@scopeFilterInputLabel">Scope</mat-label>
      <mat-select (selectionChange)="riskFilterChange('scopes', $event.value)" multiple [(ngModel)]="scopeFilterModel">
        <mat-option *ngFor="let scope of $scopes | async" [value]="scope.id">
          {{scope.display_name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-label i18n="@@assignedToFilterInputLabel">Assigned to</mat-label>
      <mat-select (selectionChange)="actionFilterChange('assigned_to', $event.value)" multiple
                  [(ngModel)]="assignedToFilterModel">
        <mat-option *ngFor="let name of $responsibles | async" [value]="name">
          {{name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-label i18n="@@categoryFilterInputLabel">Category</mat-label>
      <mat-select (selectionChange)="riskFilterChange('category', $event.value)" multiple
                  [(ngModel)]="categoryFilterModel">
        <mat-option *ngFor="let category of $categories | async" [value]="category.id">
          {{category.display_name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-label i18n="@@phaseFilterInputLabel">Phase</mat-label>
      <mat-select (selectionChange)="actionFilterChange('phase', $event.value)" multiple [(ngModel)]="phaseFilterModel">
        <mat-option *ngFor="let phase of $phases | async" [value]="phase.id">
          {{phase.display_name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-label i18n="@@createdByInputLabel">Created by</mat-label>
      <mat-select (selectionChange)="riskFilterChange('created_by', $event.value)" multiple
                  [(ngModel)]="createdByFilterModel">
        <mat-option *ngFor="let user of $createdBys | async" [hidden]="!user" [value]="user ? user.id : null">
          {{user ? user.initials : '-'}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-button (click)="resetFilters()" *ngIf="filterActive">
      <mat-icon>clear</mat-icon>
      Clear filter
    </button>

  </div>

  <mat-card class="mat-elevation-z15 no-container-padding no-padding-horizontal">

    <!--risks table-->
    <table [fxHide]="isLoading"
           mat-table
           matSort
           matSortDisableClear
           multiTemplateDataRows
           [dataSource]="risksDataSource"
           matSortActive="number"
           matSortDirection="asc"
           class="clickable">

      <ng-container matColumnDef="risk_buttons_prefix">
        <th mat-header-cell *matHeaderCellDef>
          <button mat-icon-button (click)="toggleExpandAllRisks()"
                  [matTooltip]="someExpanded ? 'Collapse all' : 'Expand all'">
            <mat-icon *ngIf="!someExpanded">expand_more</mat-icon>
            <mat-icon *ngIf="someExpanded">expand_less</mat-icon>
          </button>

        </th>
        <td mat-cell *matCellDef="let risk">
          <button mat-icon-button>
            <mat-icon *ngIf="!risk.expanded">expand_more</mat-icon>
            <mat-icon *ngIf="risk.expanded">expand_less</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nr</th>
        <td mat-cell *matCellDef="let risk">R{{risk.number | number:'3.0'}}</td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th i18n="@@riskCategoryTableHead" mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
        <td mat-cell *matCellDef="let risk">{{risk.category.display_name}}</td>
      </ng-container>

      <ng-container matColumnDef="created_by">
        <th i18n="@@createdByTableHead" mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
        <td mat-cell *matCellDef="let risk">{{risk.created_by ? risk.created_by.initials : '-'}}</td>
      </ng-container>

      <ng-container matColumnDef="scope">
        <th i18n="@@scopeTableHead" mat-header-cell *matHeaderCellDef mat-sort-header>Scope</th>
        <td mat-cell *matCellDef="let risk">{{risk.scope.display_name}}</td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th i18n="@@descriptionTableHead" mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let risk">{{risk.description}}</td>
      </ng-container>

      <ng-container matColumnDef="identified_at">
        <th i18n="@@dateTableHead" mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let risk">{{risk.identified_at | date: 'longDate'}}</td>
      </ng-container>

      <ng-container matColumnDef="probability_consequence">
        <th i18n="@@highestProbConsTableHead" class="mat-header-cell-right" mat-header-cell arrowPosition="before"
            *matHeaderCellDef mat-sort-header>
          Highest risk<br>Prob/Cons
        </th>
        <td mat-cell *matCellDef="let risk">
          <div fxLayoutAlign="end">
            <app-colored-risk-block [consequence]="risk.highest_consequence"
                                    [probability]="risk.highest_probability"></app-colored-risk-block>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="risk_buttons_suffix">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let risk;  let i = index;">
          <div fxLayoutAlign="end">
            <button mat-icon-button [matMenuTriggerFor]="menu"
                    (click)="$event.stopPropagation()"
                    *ngIf="(authService.can('risks-delete') || authService.can('risks-update'))">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu" xPosition="before">
              <ng-template matMenuContent let-name="name">
                <button mat-menu-item (click)="openEditRiskDialog(risk)"
                        *ngIf="authService.can('risks-update')">
                  <mat-icon>edit</mat-icon>
                  <span i18n="@@editBtnLabel">Edit</span><span> "R{{risk.number | number:'3.0'}}"</span>
                </button>
                <button mat-menu-item (click)="openDeleteRiskDialog(risk)"
                        *ngIf="authService.can('risks-delete')">
                  <mat-icon>delete</mat-icon>
                  <span i18n="@@deleteBtnLabel">Delete</span><span> "R{{risk.number | number: '3.0'}}"</span>
                </button>
              </ng-template>
            </mat-menu>

          </div>
        </td>
      </ng-container>

      <!--Expanded risk container-->
      <ng-container matColumnDef="expandedRisk">
        <!--Expanded risk table cell-->
        <td mat-cell *matCellDef="let risk" [attr.colspan]="displayedColumns.length">
          <!--Expanded risk content (this part is hidden or shown)-->
          <div class="risk-detail"
               [@detailExpand]="risk.expanded ? 'expanded' : 'collapsed'" fxLayout="column"
               [style.height]="risk.expanded ? 'unset' : '0 !important'">

            <!--Title and initial risk row-->
            <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
              <span class="mat-title" i18n="@@actionsLabel">Actions</span>
              <span class="row-spacer"></span>
              <span i18n="@@intialRiskLabel">Initial risk</span>
              <app-colored-risk-block
                class="initial_risk"
                [probability]="risk.initial_probability"
                [consequence]="risk.initial_consequence">
              </app-colored-risk-block>
            </div>

            <table
              #actionTables
              mat-table
              multiTemplateDataRows
              [dataSource]="actionsDataSources[risk.id]"
              class="nested-table">

              <ng-container matColumnDef="description">
                <th i18n="@@descriptionTableHead" mat-header-cell *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let action">{{action.description}}</td>
              </ng-container>

              <ng-container matColumnDef="responsible">
                <th i18n="@@assignedToTableHead" mat-header-cell *matHeaderCellDef>Assigned to</th>
                <td mat-cell *matCellDef="let action">
                  {{action.assigned_to ? action.assigned_to.initials : action.responsible ? action.responsible : nobodyString}}
                </td>
              </ng-container>

              <ng-container matColumnDef="phase">
                <th i18n="@@phaseTableHead" mat-header-cell *matHeaderCellDef>Phase</th>
                <td mat-cell *matCellDef="let action">{{action.phase.display_name}}</td>
              </ng-container>

              <ng-container matColumnDef="action_probability_consequence">
                <th i18n="@@highestProbConsTableHead" class="mat-header-cell-right" mat-header-cell *matHeaderCellDef>
                  Highest risk<br>Prob/Cons
                </th>
                <td mat-cell *matCellDef="let action">
                  <div fxLayoutAlign="end">
                    <app-colored-risk-block
                      *ngIf="action.highest_consequence !== null && action.highest_probability !== null"
                      [consequence]="action.highest_consequence"
                      [probability]="action.highest_probability">
                    </app-colored-risk-block>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="action_buttons_suffix">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let action;  let i = index;">
                  <div fxLayoutAlign="end">
                    <button mat-icon-button [matMenuTriggerFor]="menu"
                            (click)="$event.stopPropagation()"
                            *ngIf="(authService.can('risk_actions-delete') || authService.can('risk_actions-update'))">
                      <mat-icon>more_vert</mat-icon>
                    </button>

                    <mat-menu #menu="matMenu" xPosition="before">
                      <ng-template matMenuContent let-name="name">
                        <button mat-menu-item (click)="openEditActionDialog(risk, action)"
                                *ngIf="authService.can('risk_actions-update')">
                          <mat-icon>edit</mat-icon>
                          <span i18n="@@editBtnLabel">Edit</span>
                        </button>
                        <button mat-menu-item (click)="openDeleteActionDialog(risk, action)"
                                *ngIf="authService.can('risk_actions-delete')">
                          <mat-icon>delete</mat-icon>
                          <span i18n="@@deleteBtnLabel">Delete</span>
                        </button>
                      </ng-template>
                    </mat-menu>

                  </div>
                </td>
              </ng-container>


              <!--actions table header row-->
              <tr mat-header-row *matHeaderRowDef="actionDisplayedColumns"></tr>

              <!--action row-->
              <tr mat-row *matRowDef="let action; columns: actionDisplayedColumns;"></tr>
            </table>

            <!--actions table inside expanded risk-->
            <p>
              <button mat-flat-button color="primary" (click)="openNewActionDialog(risk)">
                <mat-icon>add</mat-icon>
                <span i18n="@@addActionBtnLabel">Add Action</span>
              </button>
            </p>
          </div>

        </td>

      </ng-container>

      <!--Risk table headers row-->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

      <!--Risk row-->
      <tr mat-row *matRowDef="let risk; columns: displayedColumns;"
          [class.risk-expanded-row]="risk.expanded"
          (click)="toggleExpandRisk(risk)"
          [class.hidden]="actionFilterActive && actionsDataSources[risk.id].filteredData.length === 0"
          class="clickable hover"></tr>

      <!--Risk detail row-->
      <tr mat-row *matRowDef="let risk; columns: ['expandedRisk']"
          [class.hidden]="actionFilterActive && actionsDataSources[risk.id].filteredData.length === 0"
          class="risk-detail-row"></tr>
    </table>

    <div *ngIf="isLoading" class="table_spinner">
      <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
    </div>

  </mat-card>

</section>

<button mat-mini-fab *ngIf="scrolledFromTop" (click)="scrollToTop()">
  <mat-icon>expand_less</mat-icon>
</button>
