import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {User, UserService} from '../services/user.service';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Role, RoleService} from '../services/role.service';
import {AuthService} from '../services/auth.service';
import {take} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Project} from '../services/project.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, AfterViewInit, OnDestroy {

  displayedColumns: string[] = ['symbol', 'name', 'initials', 'email', 'role', 'actions'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('search') search: ElementRef;
  dataSource: MatTableDataSource<User> = new MatTableDataSource<User>([]);
  isLoading: boolean;
  private indexUsersSub: Subscription;
  private users: Array<User>;
  searchActive: boolean;

  constructor(
    public userService: UserService,
    private dialog: MatDialog,
    public authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
  }


  ngAfterViewInit(): void {
    this.indexUsersSub = this.userService.index().subscribe((users: Array<User>) => {
        this.users = users;
        Promise.resolve(null).then(() => {
          this.isLoading = false;
        });
        this.dataSource = new MatTableDataSource(users);

        this.dataSource.sortingDataAccessor = (user: User, property: string) => {
          switch (property) {
            case 'role':
              return user.role.display_name;
            case 'name':
              return user.name.toLowerCase();
            default:
              return user[property];
          }
        };

        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      },
      () => this.isLoading = false
    );
  }


  ngOnDestroy(): void {
    this.indexUsersSub.unsubscribe();
  }

  openNewUserDialog() {
    const dialogRef = this.dialog.open(NewUserDialogComponent, {disableClose: true});
    dialogRef.afterClosed().subscribe(user => {
      if (user) {
        const data = this.dataSource.data;
        data.push(user);
        this.dataSource.data = data;
      }
    });
  }

  openDeleteUserDialog(user: User) {
    const dialogRef = this.dialog.open(ConfirmDeleteUserDialogComponent, {data: user});
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // remove the user from the table
        let data = this.dataSource.data;
        data = data.filter(obj => obj !== user);
        this.dataSource.data = data;
      }
    });
  }

  openEditUserDialog(_user: any) {
    const dialogRef = this.dialog.open(EditUserDialogComponent, {data: _user, disableClose: true});
    dialogRef.afterClosed().subscribe(user => {
      if (user) {
        const data = this.dataSource.data;
        const userIndex = data.findIndex((_u => _u.id === user.id));
        data[userIndex] = user;
        this.dataSource.data = data;
      }
    });
  }


  clearFilters() {
    this.search.nativeElement.value = '';
    this.dataSource.filter = '';
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchActive = filterValue.length > 0;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}

@Component({
  selector: 'app-new-user-dialog',
  templateUrl: 'new-user-dialog.html',
  styles: ['mat-form-field { width: 100%; min-width: 350px; }']
})
export class NewUserDialogComponent
  implements OnInit, AfterContentInit {
  form: FormGroup;
  isLoading: boolean;
  hasError: boolean;
  roles: Array<Role>;
  errorMessage: string;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private roleService: RoleService,
    private dialogRef: MatDialogRef<NewUserDialogComponent>,
    public authService: AuthService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      initials: ['', Validators.required],
      email: ['', Validators.email],
      role: ['', Validators.required],
    });
    this.form.controls.role.setValue('user', {onlySelf: true});
  }

  createNewUser() {
    if (this.form.valid) {
      this.form.disable();
      this.isLoading = true;
      this.userService.create({...this.form.value, roles: [this.form.controls.role.value]}).subscribe((user: User) => {
        this.snackBar.open('User \"' + this.form.controls.name.value + '\" was invited via email');
        this.dialogRef.close(user);
      }, (e) => {
        if (e.error?.errors) {
          this.errorMessage = Object.values(e.error.errors).join(', ');
        }
        this.hasError = true;
        this.isLoading = false;
        this.form.enable();
      })
    }
  }

  ngAfterContentInit() {
    this.roleService.index().pipe(take(1)).subscribe((roles: Array<Role>) => {
      this.roles = roles;
    });
  }

}

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: 'edit-user-dialog.html',
  styles: ['mat-form-field { width: 100%; min-width: 350px; }']
})
export class EditUserDialogComponent implements OnInit, AfterContentInit {
  form: FormGroup;
  isLoading: boolean;
  hasError: boolean;
  roles: Array<Role>;
  role: Role;
  errorMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public user: User,
    private fb: FormBuilder,
    private userService: UserService,
    private roleService: RoleService,
    private dialogRef: MatDialogRef<NewUserDialogComponent>,
    public authService: AuthService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      initials: ['', Validators.required],
      email: ['', Validators.email],
      roleName: ['', Validators.required],
    });
    this.form.patchValue(this.user);
    this.form.controls.roleName.setValue(this.user.role.name, {onlySelf: true});
  }

  updateUser() {
    if (this.form.valid) {
      this.form.disable();
      this.isLoading = true;
      this.user = {...this.user, ...this.form.value, role: this.form.controls.roleName.value};
      this.userService.update(this.user).subscribe((user: User) => {
        this.snackBar.open('User \"' + this.user.name + '\" has been updated');
        this.isLoading = false;
        this.dialogRef.close(user);
      }, (e) => {
        if (e.error?.errors) {
          this.errorMessage = Object.values(e.error.errors).join(', ');
        }
        this.hasError = true;
        this.isLoading = false;
      })
    }
  }

  ngAfterContentInit() {
    this.roleService.index().pipe(take(1)).subscribe((roles: Array<Role>) => {
      this.roles = roles;
    });
  }

}

@Component({
  selector: 'app-confirm-delete-user-dialog-component',
  templateUrl: 'confirm-delete-user-dialog.html',
  styles: ['p:first-child { margin-top: 0; }', 'p:last-child { margin-bottom: 0; }']
})
export class ConfirmDeleteUserDialogComponent {
  isLoading: boolean;

  constructor(
    private userService: UserService,
    private dialogRef: MatDialogRef<NewUserDialogComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public user: User
  ) {
  }

  deleteUser() {
    this.isLoading = true;
    this.userService.delete(this.user).subscribe((success: boolean) => {
      if (success) {
        this.dialogRef.close(true);
        this.snackBar.open('User \"' + this.user.name + '\" is deleted');
      }
    });
  }

}
