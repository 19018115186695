<div fxLayout="row" fxFill class="unauthenticated">

  <div fxFlex.gt-md="35" fxFlex="100" fxLayout="row" fxLayoutAlign="center" class="login_section_left">

    <div fxFlex="70" fxFlex.gt-sm="50" fxFlex.gt-md="70" fxLayout="column" fxLayoutGap="16px">

      <span>
        <img src="assets/img/logo_blue.png" alt="Seco logo image" class="logo">
      </span>

      <span class="mat-headline" i18n="@@appTitle">Risk Analysis Platform</span>

      <span class="mat-subheader" i18n="@@resetPasswordHeader">Reset password</span>

      <span class="mat-body" i18n="@@resetPasswordText">Please provide us with your email address so we can help you retrieve access to your account.</span>

      <form [formGroup]="form" (ngSubmit)="onSubmit()" fxLayout="column" fxLayoutGap="16px">
        <mat-form-field [hideRequiredMarker]="true" class="full-width-input" appearance="outline">
          <mat-label i18n="@@emailInputLabel">Email</mat-label>
          <input matInput i18n-placeholder="@@emailInputPlaceholder" placeholder="Fill in your e-mail address" formControlName="email" required>
          <mat-icon matSuffix>alternate_email</mat-icon>
          <mat-error i18n="@@emailInputError">
            Please provide a valid email address
          </mat-error>
        </mat-form-field>

        <button mat-flat-button [loading]="isLoading" color="primary" class="login_button" i18n="@@resetPasswordBtnLabel">Reset password</button>

        <a mat-button color="primary" routerLink="/login" i18n="@@backToLoginBtnLabel">Back to login</a>

      </form>

    </div>

  </div>

  <section fxFlex.gt-md="65" fxFlex="0" class="login_section_right dark-theme" fxLayoutAlign="end end">
    <small class="version_number">v{{version}}</small>
  </section>

</div>
