<section class="container" fxLayout="column">

  <div class="title_and_searchbar dark-theme" fxLayoutAlign="start center" fxLayoutGap="16px">

    <span class="mat-headline title" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-icon>people</mat-icon>
      <span i18n="@@usersHeader">Users</span>
    </span>


  </div>

  <div class="dark-theme" fxLayoutAlign="start center" fxLayoutGap="16px">

    <mat-form-field color="accent" appearance="standard">
      <input matInput #search (keyup)="applyFilter($event)" type="search" i18n-placeholder="@@searchUsersInputPlaceholder" placeholder="Search users">
    </mat-form-field>

    <button mat-button (click)="clearFilters()" *ngIf="searchActive">
      <mat-icon>clear</mat-icon>
      <span i18n="@@clearFilterBtnLabel">Clear filter</span>
    </button>

    <span class="row-spacer"></span>

    <button *ngIf="authService.can('users-create')" mat-flat-button color="accent" (click)="openNewUserDialog()">
      <mat-icon aria-label="Add new account">person_add</mat-icon>
      <span i18n="@@newUserBtnLabel">New User</span>
    </button>

  </div>

  <mat-card class="mat-elevation-z15 no-container-padding no-padding-horizontal">

    <table [fxHide]="isLoading" mat-table [dataSource]="dataSource" matSort matSortDisableClear matSortActive="name" matSortDirection="asc">

      <ng-container matColumnDef="symbol">
        <th mat-header-cell *matHeaderCellDef></th>
        <td style="width: 30px; padding-right: 24px;" mat-cell *matCellDef="let user">
          <app-letter-logo [letter]="user.name[0]" [color]="user.color"></app-letter-logo>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th i18n="@@nameTableHead" mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let user">{{user.name}}</td>
      </ng-container>

      <!-- Initials Column -->
      <ng-container matColumnDef="initials">
        <th i18n="@@initialsTableHead" mat-header-cell *matHeaderCellDef mat-sort-header>Initials</th>
        <td mat-cell *matCellDef="let user">{{user.initials}}</td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th i18n="@@emailTableHead" mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let user">{{user.email}} </td>
      </ng-container>

      <!-- Roles Column -->
      <ng-container matColumnDef="role">
        <th i18n="@@roleTableHead" mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
        <td mat-cell *matCellDef="let user">{{user.role.display_name}}</td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let user;  let i = index;">
          <div fxLayoutAlign="end">
            <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{user: user}"
                    *ngIf="(authService.hasRole('superadministrator') || user.role.name !== 'superadministrator') && (authService.can('users-delete') || authService.can('users-update')) && user.id !== authService.user.id">
              <mat-icon>more_vert</mat-icon>
            </button>

            <ng-template [ngIf]="user.id === authService.user.id">
              <em>Current user</em>
            </ng-template>

            <mat-menu #menu="matMenu" xPosition="before">
              <ng-template matMenuContent let-name="name">
                <button mat-menu-item (click)="openEditUserDialog(user)" *ngIf="authService.can('users-update')">
                  <mat-icon>edit</mat-icon>
                  <span i18n="@@editBtnLabel">Edit</span><span> "{{user.name}}"</span>
                </button>
                <button mat-menu-item (click)="openDeleteUserDialog(user)" *ngIf="authService.can('users-delete')">
                  <mat-icon>delete</mat-icon>
                  <span i18n="@@deleteBtnLabel">Delete</span><span> "{{user.name}}"</span>
                </button>
              </ng-template>
            </mat-menu>

          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <div *ngIf="isLoading" class="table_spinner">
      <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
    </div>

    <mat-paginator [fxHide]="isLoading" #paginator [pageSize]="10" color="accent" showFirstLastButtons></mat-paginator>

  </mat-card>

</section>
