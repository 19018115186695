<ng-template [ngIf]="isLoadingGlobalInfo">
  <div fxLayoutAlign="center center" fxFill>
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template [ngIf]="!isLoadingGlobalInfo">

  <mat-toolbar color="primary" class="main-toolbar mat-toolbar__header--darker" [class.mat-elevation-z4]="scrolledFromTop">

    <mat-toolbar-row fxFlex fxLayoutAlign="center start" class="dark-theme">

      <div class="container" fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center center">
        <img routerLink="/" src="assets/img/logo_white_transp.png" alt="Seco logo image" class="header_logo">
        <span routerLink="/" class="header_title" i18n="@@appTitle">Risk Analysis Platform</span>

        <nav mat-tab-nav-bar backgroundColor="primary" class="darker">
          <a mat-tab-link *ngFor="let link of links"
             [routerLink]="link.url"
             [active]="activeLink == link.url">
            <mat-icon class="main_nav_tab_icon">{{link.icon}}</mat-icon>
            {{link.text}} </a>
        </nav>

        <span class="row-spacer"></span>

        <button mat-icon-button
                aria-label="Search"
        (click)="openSearchDialog()">
          <mat-icon>search</mat-icon>
        </button>

        <button mat-stroked-button [matMenuTriggerFor]="languageMenu"
                aria-label="Language dropdown">
          <mat-icon>language</mat-icon>
          <span i18n="@@dutchLabel" *ngIf="currentLocale === 'nl'">Dutch</span>
          <span i18n="@@englishLabel" *ngIf="currentLocale === 'en'">English</span>
          <mat-icon>expand_more</mat-icon>
        </button>
        <mat-menu #languageMenu="matMenu" xPosition="before">
          <ng-template matMenuContent let-name="name">
            <button *ngIf="currentLocale !== 'nl'" mat-menu-item (click)="switchLanguage('nl')">
              <mat-icon>language</mat-icon>
              <span i18n="@@dutchLabel">Dutch</span>
            </button>
            <button *ngIf="currentLocale !== 'en'" mat-menu-item (click)="switchLanguage('en')">
              <mat-icon>language</mat-icon>
              <span i18n="@@englishLabel">English</span>
            </button>
          </ng-template>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{user: user}"
                aria-label="Account dropdown">
          <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
          <ng-template matMenuContent let-name="name">
            <button routerLink="my-profile" mat-menu-item>
              <mat-icon>person</mat-icon>
              <span i18n="@@myProfileBtnLabel">My Profile</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="logout()">
              <mat-icon>logout</mat-icon>
              <span i18n="@@logoutBtnLabel">Log out</span><span> "{{user.name}}"</span>
            </button>
          </ng-template>
        </mat-menu>

      </div>

    </mat-toolbar-row>

  </mat-toolbar>

  <router-outlet></router-outlet>

</ng-template>
