<section fxLayout="row" fxFill class="unauthenticated">

  <section fxFlex.gt-md="35" fxFlex="100" fxLayout="row" fxLayoutAlign="center" class="login_section_left">

    <section fxFlex="70" fxFlex.gt-sm="50" fxFlex.gt-md="70" fxLayout="column" fxLayoutGap="16px">

      <span>
        <img src="assets/img/logo_blue.png" alt="Seco logo image" class="logo">
      </span>

      <span class="mat-headline" i18n="@@appTitle">Risk Analysis Platform</span>

      <span class="mat-subheader" i18n="@@loginHeader">Login</span>

      <span class="mat-body" i18n="@@loginText">Login using your credentials. If you don't have an account yet, please contact your Seco contact.</span>

      <mat-error *ngIf="!isProduction" i18n="@@nonProductionWarningText">
        This is not the production version of the application! Dont use this instance to save important work.
      </mat-error>

      <form [formGroup]="form" (ngSubmit)="onSubmit()" fxLayout="column" fxLayoutGap="16px">
        <mat-error *ngIf="loginInvalid" i18n="@@loginInvalidErrorText" class="mat-body">
          The username and password were not recognised, please try again.
        </mat-error>
        <mat-form-field [hideRequiredMarker]="true" class="full-width-input" appearance="outline">
          <mat-label i18n="@@emailInputLabel">Email</mat-label>
          <input matInput i18n-placeholder="@@emailInputPlaceholder" placeholder="Fill in your e-mail address" formControlName="email" required>
          <mat-icon matSuffix>alternate_email</mat-icon>
          <mat-error i18n="@@emailInputError">
            Please provide a valid email address
          </mat-error>
        </mat-form-field>
        <mat-form-field [hideRequiredMarker]="true" class="full-width-input" appearance="outline">
          <mat-label i18n="@@passwordInputLabel">Password</mat-label>
          <input matInput type="password" formControlName="password" required>
          <mat-icon matSuffix>password</mat-icon>
          <mat-error i18n="@@passwordInputError">
            Please provide a valid password
          </mat-error>
        </mat-form-field>

        <button mat-flat-button [loading]="isLoading" color="primary" class="login_button" i18n="@@loginBtnLabel">Login</button>

        <a mat-button color="primary" routerLink="/reset-password" i18n="@@forgotPasswordLinkText">Forgot your password?</a>

      </form>

    </section>

  </section>

  <section fxFlex.gt-md="65" fxFlex="0" class="login_section_right dark-theme" fxLayoutAlign="end end">
    <small class="version_number">v{{version}}</small>
  </section>

</section>
