import {Component, Inject, Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NewUserDialogComponent} from '../users/users.component';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap(() => {
      },
      (error: any) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this.authService.logout().subscribe();
          }
          if (error.status === 403 && this.authService.isLoggedIn()) {
            this.dialog.open(ForbiddenErrorDialogComponent, {data: error.error ? error.error.message : null});
          }
        }
      }));
  }
}

@Component({
  selector: 'app-forbidden-error-dialog-component',
  templateUrl: 'forbidden-error-dialog.html',
  styles: ['mat-form-field { width: 100%; min-width: 350px; }']
})
export class ForbiddenErrorDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<ForbiddenErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message?: string,
  ) {
  }

}

