import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

export interface RiskCategory {
  id: number,
  display_name: string
}

@Injectable({
  providedIn: 'root'
})
export class RiskCategoryService {

  private apiEndpoint = 'risk_categories';

  constructor(
    private http: HttpClient,
  ) {
  }

  index(): Observable<Array<RiskCategory>> {
    return this.http.get<Array<RiskCategory>>(environment.api_url + this.apiEndpoint, {withCredentials: true});
  }

}
