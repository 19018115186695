<section class="container" fxLayout="column">

  <section class="title_and_searchbar dark-theme" fxLayoutAlign="start center">

    <span class="mat-headline title" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-icon>dashboard</mat-icon>
      <span i18n="@@dashboardHeader">Dashboard</span>
    </span>

    <span class="row-spacer"></span>

  </section>

</section>
