import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {map, take, tap} from 'rxjs/operators';
import {SuccessResponse} from '../app.component';
import {HttpClient, HttpParams} from '@angular/common/http';

export interface Project {
  id: string,
  color: string,
  name: string,
  case_number: string,
  last_activity: string,
  created_at: string,
  highest_probability: number,
  highest_consequence: number,
  is_template: boolean,
  deleted_at: string,
}

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  apiEndpoint = 'projects';
  private projects: Array<Project>;

  constructor(
    private http: HttpClient,
  ) {
  }

  index(): Observable<Array<Project>> {
    return this.http.get<Array<Project>>(environment.api_url + this.apiEndpoint, {
      withCredentials: true,
    }).pipe(
      tap((projects: Array<Project>) => this.projects = projects)
    );
  }

  indexTemplates(): Observable<Array<Project>> {
    const params = new HttpParams().set('templates', '1');
    return this.http.get<Array<Project>>(environment.api_url + this.apiEndpoint, {
      withCredentials: true,
      params
    }).pipe(
      tap((projects: Array<Project>) => this.projects = projects)
    );
  }

  indexArchives(): Observable<Array<Project>> {
    const params = new HttpParams().set('archive', '1');
    return this.http.get<Array<Project>>(environment.api_url + this.apiEndpoint, {
      withCredentials: true,
      params
    }).pipe(
      tap((projects: Array<Project>) => this.projects = projects)
    );
  }

  create(project: Project): Observable<Project> {
    return this.http.post<Project>(environment.api_url + this.apiEndpoint, project, {withCredentials: true}).pipe(take(1));
  }

  update(project: Project): Observable<Project> {
    return this.http.put<Project>(environment.api_url + this.apiEndpoint + '/' + project.id, project, {withCredentials: true}).pipe(
      take(1)
    );
  }

  delete(project: Project): Observable<boolean> {
    return this.http.delete<SuccessResponse>(environment.api_url + this.apiEndpoint + '/' + project.id, {withCredentials: true}).pipe(
      map((response: SuccessResponse) => {
        return response.success;
      }),
      take(1)
    );
  }

  get(projectId: string): Observable<Project> {
    // first find the project object in this.projects
    const project: Project = this.projects ? this.projects.find((_project: Project) => _project.id === projectId) : null;
    // return the object or fetch from API if object was not cached
    return project ?
      of(project) :
      this.http.get<Project>(environment.api_url + this.apiEndpoint + '/' + projectId, {withCredentials: true});
  }

  unarchive(project: Project): Observable<Project> {
    return this.http.delete<Project>(environment.api_url + this.apiEndpoint + '/' + project.id + '/unarchive', {withCredentials: true}).pipe(
      take(1)
    );
  }

  archive(project: Project): Observable<Project> {
    return this.http.delete<Project>(environment.api_url + this.apiEndpoint + '/' + project.id + '/archive', {withCredentials: true}).pipe(
      take(1)
    );
  }
}
