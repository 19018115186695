import {AfterContentInit, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RiskActionPhase, RiskActionPhaseService} from '../../services/risk-action-phase.service';
import {User, UserService} from '../../services/user.service';
import {Observable} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Project} from '../../services/project.service';
import {RiskAssessment} from '../../services/risk-assessment.service';
import {Risk} from '../../services/risk.service';
import {RiskAction, RiskActionService} from '../../services/risk-action.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {map, startWith, take} from 'rxjs/operators';
import {formatNumber} from '@angular/common';

@Component({
  selector: 'app-edit-action-dialog',
  templateUrl: './edit-action-dialog.component.html',
  styleUrls: ['./edit-action-dialog.component.scss']
})
export class EditActionDialogComponent implements OnInit, AfterContentInit {

  form: FormGroup;
  phases: Array<RiskActionPhase>;
  users: Array<any>;
  filteredUsers: Observable<any>;
  isLoading: boolean;
  hasError: boolean;
  errorMessage: string;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { project?: Project, riskAssessment: RiskAssessment, risk: Risk, action: RiskAction },
    private fb: FormBuilder,
    private riskActionService: RiskActionService,
    private riskActionPhaseService: RiskActionPhaseService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<EditActionDialogComponent>,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      description: ['', Validators.required],
      risk_action_phase_id: ['', Validators.required],
      responsible: ['', Validators.required],
    });
    this.form.patchValue(this.data.action);
  }

  ngAfterContentInit() {
    this.riskActionPhaseService.index().pipe(take(1)).subscribe((phases: Array<RiskActionPhase>) => {
      this.phases = phases;
      this.form.controls.risk_action_phase_id.setValue(
        this.phases.filter((phase: RiskActionPhase) => phase.id === this.data.action.phase.id).pop().id
      )
    });
    if (this.data.project) {
      this.userService.indexProjectResponsibles(this.data.project).pipe(take(1)).subscribe((users: Array<any>) => {
        this.users = users;
        console.log('found project responsibles:', users);
        this.filteredUsers = this.form.controls.responsible.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterUsers(value))
          );
      });
    } else {
      this.userService.indexRiskAssessmentResponsibles(this.data.riskAssessment).pipe(take(1)).subscribe((users: Array<any>) => {
        this.users = users;
        console.log('found risk assessment responsibles:', users);
        this.filteredUsers = this.form.controls.responsible.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterUsers(value))
          );
      });
    }
    this.form.controls.responsible.setValue(this.data.action.responsible ? this.data.action.responsible : this.data.action.assigned_to);
    console.log('this.form.controls.responsible.value', this.form.controls.responsible.value);
  }

  private filterUsers(value: string): User[] {
    const filterValue = value.toString().toLowerCase();
    return this.users.filter(user => {
      if (user.name) {
        return user.name.toLowerCase().includes(filterValue);
      } else {
        return user.display_name.toLowerCase().includes(filterValue);
      }
    });
  }

  displayUser(user: any) {
    if (!user) {
      return '';
    } else {
      if (typeof user === 'string') {
        return user;
      } else {
        return (user.name ? user.name : user.display_name);
      }
    }
  }

  updateAction() {
    if (this.form.valid) {
      this.form.disable();
      this.isLoading = true;
      const actionData = {
        id: this.data.action.id,
        ...this.form.value,
        assigned_to_user_id: this.form.controls.responsible.value.id
      };
      if (this.form.controls.responsible.value.id) {
        delete actionData.responsible;
      } else {
        delete actionData.assigned_to_user_id;
      }
      this.riskActionService.update(this.data.project, this.data.riskAssessment, this.data.risk,
        actionData
      ).subscribe((action: RiskAction) => {
        this.snackBar.open('Action for risk \"R' + formatNumber(this.data.risk.number, 'en-US', '3.0') + '\" is updated successfully');
        this.dialogRef.close(action);
      }, (e) => {
        if (e.error?.errors) {
          this.errorMessage = Object.values(e.error.errors).join(', ');
        }
        this.hasError = true;
        this.isLoading = false;
        this.form.enable();
      })
    }
  }

}
