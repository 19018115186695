import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Risk} from '../../services/risk.service';
import {RiskAssessment} from '../../services/risk-assessment.service';
import {Project} from '../../services/project.service';
import {RiskAction} from '../../services/risk-action.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RiskActionResult, RiskActionResultService} from '../../services/risk-action-result.service';

@Component({
  selector: 'app-confirm-delete-action-result-dialog',
  templateUrl: './confirm-delete-action-result-dialog.component.html',
  styleUrls: ['./confirm-delete-action-result-dialog.component.scss']
})
export class ConfirmDeleteActionResultDialogComponent implements OnInit {
  isLoading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      risk: Risk,
      riskAssessment: RiskAssessment,
      project: Project,
      action: RiskAction,
      result: RiskActionResult
    },
    private riskActionResultService: RiskActionResultService,
    private dialogRef: MatDialogRef<ConfirmDeleteActionResultDialogComponent>,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
  }

  deleteResult() {
    this.isLoading = true;
    this.riskActionResultService.delete(
      this.data.project,
      this.data.riskAssessment,
      this.data.risk,
      this.data.action,
      this.data.result
    ).subscribe(
      (success: boolean) => {
        if (success) {
          this.dialogRef.close(true);
          this.snackBar.open('Result is deleted');
        }
      }, () => {
        this.isLoading = false;
      }
    );
  }
}
