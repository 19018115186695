import {Injectable} from '@angular/core';
import {
  CanActivate,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree, Router
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {map} from 'rxjs/operators';
import {User} from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let allowed = true;
    return this.authService.getUser().pipe(map((_user: User) => {
      // check if user has route permissions
      const permissions = route.data.permissions as Array<string>;
      permissions.forEach((permissionName: string) => {
        if (!this.authService.can(permissionName)) {
          allowed = false;
          this.router.navigateByUrl('/forbidden');
        }
      });
      return allowed;
    }));
  }
}
