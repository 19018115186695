<section class="container" fxLayout="column">

  <div class="title_and_searchbar dark-theme" fxLayoutAlign="start center" fxLayoutGap="16px">

    <span class="mat-headline title" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-icon>library_books</mat-icon>
      <span i18n="@@templatesHeader">Templates</span>
    </span>

  </div>

  <div class="dark-theme" fxLayoutAlign="start center" fxLayoutGap="16px">

    <mat-form-field color="accent" appearance="standard">
      <input matInput i18n-placeholder="@@searchTemplatesInputPlaceholder" #search (keyup)="applyFilter($event)"
             type="search" placeholder="Search projects">
    </mat-form-field>

    <button mat-button (click)="clearFilters()" *ngIf="searchActive">
      <mat-icon>clear</mat-icon>
      <span i18n="@@clearFilterBtnLabel">Clear filter</span>
    </button>

    <span class="row-spacer"></span>

    <button [matMenuTriggerFor]="createMenu2"
            *ngIf="(
            authService.can('project_templates-create')
            || authService.can('risk_assessments_templates-create')
            )"
            mat-flat-button color="accent">
      <span i18n="@@createNewBtnLabel">Create New</span>
      <mat-icon aria-label="Add new project">expand_more</mat-icon>
    </button>

    <mat-menu #createMenu2="matMenu" xPosition="before">

      <ng-template matMenuContent let-name="name">
        <button mat-menu-item (click)="openNewProjectTemplateDialog()"
                *ngIf="authService.can('project_templates-create')">
          <mat-icon>create_new_folder</mat-icon>
          <span i18n="@@newProjectTemplateBtnLabel">Project Template</span>
        </button>
        <button mat-menu-item (click)="openNewRiskAssessmentTemplate()"
                *ngIf="authService.can('risk_assessments_templates-create')">
          <mat-icon>note_add</mat-icon>
          <span i18n="@@newRiskAssessmentTemplateBtnLabel">Risk Assessment Template</span>
        </button>
      </ng-template>

    </mat-menu>

  </div>

  <mat-card class="mat-elevation-z15 no-container-padding no-padding-horizontal p-t-0">

    <mat-tab-group dynamicHeight animationDuration="0ms"  [selectedIndex]="selectedTabIndex"
                   (selectedIndexChange)="onTabChange($event)">

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">folder</mat-icon>
          <span i18n="@@projectsTemplatesHeader">Project Templates</span>
        </ng-template>

        <app-projects-table #projectsTableComponent [templates]="true"></app-projects-table>

      </mat-tab>
      <mat-tab>

        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">description</mat-icon>
          <span i18n="@@riskAssessmentsTemplatesHeader">Risk Assessment Templates</span>
        </ng-template>

        <app-risk-assessments-list [templates]="true" #riskAssessmentsListComponent></app-risk-assessments-list>

      </mat-tab>

    </mat-tab-group>


  </mat-card>

</section>
