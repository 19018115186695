import {Component, Input, OnChanges} from '@angular/core';

@Component({
  selector: 'app-colored-risk-block',
  templateUrl: './colored-risk-block.component.html',
  styleUrls: ['./colored-risk-block.component.scss']
})
export class ColoredRiskBlockComponent implements OnChanges {

  @Input() probability: number;
  @Input() consequence: number;
  private riskScore: number;
  color: string;

  ngOnChanges(changes) {
    this.riskScore = this.probability * this.consequence * (this.consequence / 2);
    if (this.riskScore <= 0) {
      this.color = '#A4A4A4';
    } else if (this.riskScore <= 5) {
      this.color = '#00b050';
    } else if (this.riskScore <= 10) {
      this.color = '#92d050';
    } else if (this.riskScore <= 20) {
      this.color = '#ffc000';
    } else if (this.riskScore <= 40) {
      this.color = '#ed7d31';
    } else if (this.riskScore <= 80) {
      this.color = '#c00000';
    }
  }

}
