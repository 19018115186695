import {Component, HostListener, Inject, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {User} from '../services/user.service';
import {filter, take} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {DialogPosition, MatDialog} from '@angular/material/dialog';
import {SearchDialogComponent} from '../search/search-dialog/search-dialog.component';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})

export class MainLayoutComponent implements OnInit, OnDestroy {

  links: any;
  activeLink: any;
  scrolledFromTop: boolean;
  user: User;
  isLoadingGlobalInfo: boolean;
  private getUserSub: Subscription;
  private routerEventsSub: Subscription;
  currentLocale: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.currentLocale = this.locale.split('-', 1)[0];
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    const pos = (document.documentElement.scrollTop || document.body.scrollTop);
    if (pos > 1 && this.scrolledFromTop !== true) {
      this.scrolledFromTop = true;
    } else if (pos <= 1) {
      this.scrolledFromTop = false;
    }
  }


  ngOnInit() {
    // set default menu items
    this.links = [
      /* {
         text: $localize`:@@dashboardHeader:Dashboard`,
         url: 'dashboard',
         icon: 'dashboard',
       },*/
    ];

    // load global info (including authenticated user, permission and roles etc);
    this.isLoadingGlobalInfo = true;
    this.getUserSub = this.authService.getUser().subscribe((_user: User) => {
      this.user = _user;
      this.isLoadingGlobalInfo = false;
      // add additional menu items based on user permissions or role if not already added

      if (this.authService.can('projects-read')) { // if user can read users, add 'users' menu item
        if (!this.links.some(link => link.url === 'projects')) {
          this.links.push({
            text: $localize`:@@projectsHeader:Projects`,
            url: 'projects',
            icon: 'folder',
          });
        }
      }

      if (this.authService.can('templates-read')) { // if user can read users, add 'users' menu item
        if (!this.links.some(link => link.url === 'templates')) {
          this.links.push({
            text: $localize`:@@templatesHeader:Templates`,
            url: 'templates',
            icon: 'library_books',
          });
        }
      }

      if (this.authService.can('users-create')) { // if user can read users, add 'users' menu item
        if (!this.links.some(link => link.url === 'users')) {
          this.links.push({
            text: $localize`:@@usersHeader:Users`,
            url: 'users',
            icon: 'people',
          });
        }
      }

    });

    this.route.url.pipe(take(1)).subscribe(() => {
      // set active tab to matching route path (path of first child, so i.e. /dashboard)
      this.activeLink = this.route.snapshot.firstChild.url[0].path;
    });

    // listen to all route changes to check if new url is an this.links item (or child of)
    // so if not, we can deactivate all the this.links menu items
    this.routerEventsSub = this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
    ).subscribe((event: NavigationStart) => {
      const link = this.links.filter(e => {
        return event.url.indexOf(e.url) !== -1 || (event.url === '/' && e.url === 'dashboard')
      });
      if (!link.length) {
        // url not in main tab menu, deactivate all links
        this.activeLink = null;
      } else {
        // url in main tab, activate correct tab
        this.activeLink = link[0].url;
      }
    });

  }

  logout() {
    this.authService.logout().subscribe();
  }

  ngOnDestroy(): void {
    this.getUserSub.unsubscribe();
    this.routerEventsSub.unsubscribe();
  }

  switchLanguage(locale: string) {
    window.location.href = window.location.href.replace(`/${this.currentLocale}/`, `/${locale}/`);
  }

  openSearchDialog() {
    const dialogRef = this.dialog.open(SearchDialogComponent, {
      disableClose: false,
      panelClass: 'search-dialog',
      hasBackdrop: true,
      closeOnNavigation: true,
      minWidth: '50%',
      autoFocus: true,
      position: {
        top: '50px'
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      // todo implement afterClosed procedure
    });
  }
}
