import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {AuthService} from '../services/auth.service';
import {User, UserService} from '../services/user.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  user: User;
  contactForm: FormGroup;
  securityForm: FormGroup;
  isSavingContact: boolean;
  isSavingSecurity: boolean;
  private getUserSub: Subscription;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      initials: ['', Validators.required],
      email: ['', Validators.email],
    });

    this.securityForm = this.fb.group({
      password_old: ['', Validators.required],
      password_new: ['', Validators.required],
      password_new2: ['', Validators.required],
    });


    this.isLoading = true;
    this.getUserSub = this.authService.getUser().subscribe((_user: User) => {
      this.user = _user;
      this.contactForm.patchValue(this.user);
      this.isLoading = false;
    }, () => this.isLoading = false);
  }

  onSubmitContact() {
    if (this.contactForm.valid) {
      this.isSavingContact = true;
      this.userService.update({...this.contactForm.value, id: this.user.id}).subscribe(() => {
        this.isSavingContact = false;
        this.snackBar.open('You profile information has been updated');
      }, () => {
        this.isSavingContact = false;
      });
    }
  }

  onSubmitSecurity(formDirective: FormGroupDirective) {
    if (this.securityForm.valid) {
      this.isSavingSecurity = true;
      this.authService.updateSecurity(this.securityForm.value).subscribe((success) => {
        this.isSavingSecurity = false;
        if (success) {
          this.snackBar.open('Your password has been updated');
          formDirective.resetForm();
          this.securityForm.reset();
        }
      }, () => {
        this.isSavingSecurity = false;
      })
    }

  }

  ngOnDestroy(): void {
    this.getUserSub.unsubscribe();
  }
}
