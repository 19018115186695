<section class="container" fxLayout="column">

  <div class="title_and_searchbar dark-theme" fxLayoutAlign="start center" fxLayoutGap="16px">

    <span class="mat-headline title" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-icon>folder</mat-icon>
      <span i18n="@@projectsHeader">Projects</span>
    </span>

  </div>

  <div class="dark-theme" fxLayoutAlign="start center" fxLayoutGap="16px">

    <mat-form-field color="accent" appearance="standard">
      <input matInput i18n-placeholder="@@searchProjectsInputPlaceholder" #search (keyup)="applyFilter()"
             type="search" placeholder="Search projects">
    </mat-form-field>

    <button mat-button (click)="clearFilters()" *ngIf="searchActive">
      <mat-icon>clear</mat-icon>
      <span i18n="@@clearFilterBtnLabel">Clear filter</span>
    </button>

    <span class="row-spacer"></span>

    <!--<button *ngIf="authService.can('projects-create')" mat-flat-button color="accent" (click)="openNewProjectDialog()">
      <mat-icon aria-label="Add new project">create_new_folder</mat-icon>
      <span i18n="@@newProjectBtnLabel">New Project</span>
    </button>-->

    <button [matMenuTriggerFor]="createMenu"
            *ngIf="(
            authService.can('projects-create')
            || authService.can('project_templates-create')
            )"
            mat-flat-button color="accent">
      <span i18n="@@createNewBtnLabel">Create New</span>
      <mat-icon aria-label="Add new project">expand_more</mat-icon>
    </button>

    <mat-menu #createMenu="matMenu" xPosition="before">
      <ng-template matMenuContent let-name="name">
        <button mat-menu-item (click)="openNewProjectDialog()"
                *ngIf="authService.can('projects-create')">
          <mat-icon>create_new_folder</mat-icon>
          <span i18n="@@newProjectBtnLabel">Blank Project</span>
        </button>
        <button mat-menu-item (click)="openNewProjectFromTemplateDialog()"
                *ngIf="authService.can('project_templates-create')">
          <mat-icon>library_add</mat-icon>
          <span i18n="@@newProjectFromTemplateBtnLabel">Project From Template</span>
        </button>
      </ng-template>
    </mat-menu>

  </div>

  <mat-card class="mat-elevation-z15 no-container-padding no-padding-horizontal p-t-0">

    <mat-tab-group dynamicHeight animationDuration="0ms" [selectedIndex]="selectedTabIndex"
                   (selectedIndexChange)="onTabChange($event)">

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">folder</mat-icon>
          <span i18n="@@projectsHeader">Projecten</span>
        </ng-template>

        <app-projects-table #projectsTableComponent></app-projects-table>


      </mat-tab>

      <mat-tab *ngIf="authService.can('project_archive-read')">
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">archive</mat-icon>
          <span i18n="@@archiveHeader">Archief</span>
        </ng-template>

        <app-projects-table #archiveTableComponent [archive]="true"></app-projects-table>

      </mat-tab>

    </mat-tab-group>

  </mat-card>

</section>
