import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Project, ProjectService} from './project.service';
import {RiskAssessment, RiskAssessmentService} from './risk-assessment.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {map, take} from 'rxjs/operators';
import {Risk, RiskService} from './risk.service';
import {User} from './user.service';
import {RiskActionPhase} from './risk-action-phase.service';
import {SuccessResponse} from '../app.component';
import {RiskActionResult} from './risk-action-result.service';

export interface RiskAction {
  id: string,
  description: string,
  responsible: string | null,
  assigned_to: User | null,
  phase: RiskActionPhase,
  results: RiskActionResult[],
  expanded?: boolean,
  highest_probability: number | null,
  highest_consequence: number | null,
  completed_at: string | null,
  risk_action_phase_id?: number,
  assigned_to_user_id?: string,
}

@Injectable({
  providedIn: 'root'
})
export class RiskActionService {

  apiEndpoint = 'actions';

  constructor(
    private http: HttpClient,
    private projectService: ProjectService,
    private riskService: RiskService,
    private riskAssessmentService: RiskAssessmentService,
  ) {
  }

  // todo remove unused methods

  /*index(project: Project, riskAssessment: RiskAssessment, risk: Risk): Observable<Array<Risk>> {
    return this.http.get<Array<Risk>>(
      environment.api_url +
      this.projectService.apiEndpoint + '/' + project.id + '/' +
      this.riskAssessmentService.apiEndpoint + '/' + riskAssessment.id + '/' +
      this.riskService.apiEndpoint + '/' + risk.id + '/' +
      this.apiEndpoint, {withCredentials: true}
    );
  }*/

  create(project: Project, riskAssessment: RiskAssessment, risk: Risk, riskAction: RiskAction): Observable<RiskAction> {
    if (project) {
      return this.http.post<RiskAction>(
        environment.api_url +
        this.projectService.apiEndpoint + '/' + project.id + '/' +
        this.riskAssessmentService.apiEndpoint + '/' + riskAssessment.id + '/' +
        this.riskService.apiEndpoint + '/' + risk.id + '/' +
        this.apiEndpoint,
        riskAction,
        {withCredentials: true}
      ).pipe(take(1));
    } else {
      return this.http.post<RiskAction>(
        environment.api_url +
        this.riskAssessmentService.apiEndpoint + '/' + riskAssessment.id + '/' +
        this.riskService.apiEndpoint + '/' + risk.id + '/' +
        this.apiEndpoint,
        riskAction,
        {withCredentials: true}
      ).pipe(take(1));
    }
  }

  update(project: Project, riskAssessment: RiskAssessment, risk: Risk, riskAction: RiskAction): Observable<RiskAction> {

    if (!riskAction.risk_action_phase_id && riskAction.phase) {
      riskAction.risk_action_phase_id = riskAction.phase.id;
    }

    if (!riskAction.assigned_to_user_id && riskAction.assigned_to) {
      riskAction.assigned_to_user_id = riskAction.assigned_to.id;
      delete riskAction.responsible;
    }

    if (project) {
      return this.http.put<RiskAction>(
        environment.api_url +
        this.projectService.apiEndpoint + '/' + project.id + '/' +
        this.riskAssessmentService.apiEndpoint + '/' + riskAssessment.id + '/' +
        this.riskService.apiEndpoint + '/' + risk.id + '/' +
        this.apiEndpoint + '/' + riskAction.id,
        riskAction,
        {withCredentials: true}
      ).pipe(take(1));
    } else {
      return this.http.put<RiskAction>(
        environment.api_url +
        this.riskAssessmentService.apiEndpoint + '/' + riskAssessment.id + '/' +
        this.riskService.apiEndpoint + '/' + risk.id + '/' +
        this.apiEndpoint + '/' + riskAction.id,
        riskAction,
        {withCredentials: true}
      ).pipe(take(1));
    }
  }

  delete(project: Project, riskAssessment: RiskAssessment, risk: Risk, riskAction: RiskAction): Observable<boolean> {
    if (project) {
      return this.http.delete<SuccessResponse>(
        environment.api_url +
        this.projectService.apiEndpoint + '/' + project.id + '/' +
        this.riskAssessmentService.apiEndpoint + '/' + riskAssessment.id + '/' +
        this.riskService.apiEndpoint + '/' + risk.id + '/' +
        this.apiEndpoint + '/' + riskAction.id,
        {withCredentials: true}
      ).pipe(
        map((response: SuccessResponse) => {
          return response.success;
        }),
        take(1)
      );
    } else {
      return this.http.delete<SuccessResponse>(
        environment.api_url +
        this.riskAssessmentService.apiEndpoint + '/' + riskAssessment.id + '/' +
        this.riskService.apiEndpoint + '/' + risk.id + '/' +
        this.apiEndpoint + '/' + riskAction.id,
        {withCredentials: true}
      ).pipe(
        map((response: SuccessResponse) => {
          return response.success;
        }),
        take(1)
      );
    }
  }

}
