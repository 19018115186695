import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {RequestCacheService} from '../services/request-cache.service';
import {startWith, tap} from 'rxjs/operators';

const TTL = 3600; // caching invalidation in seconds

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  constructor(private cache: RequestCacheService) {
  }


  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // continue if not cacheable.
    /*if (!isCacheable(req)) {
      return next.handle(req);
    }*/
    if (req.method !== 'GET') {
      return next.handle(req);
    }

    const cachedResponse = this.cache.get(req.url);

    // cache-then-refresh
    const results$ = this.sendRequest(req, next);
    return cachedResponse ? results$.pipe(startWith(cachedResponse)) : results$;

    // cache-or-fetch
    // return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next);
  }


  sendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.cache.set(req.url, event, TTL);
        }
      })
    );
  }
}
