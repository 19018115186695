import {AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../services/auth.service';
import {RiskAssessment, RiskAssessmentService} from '../../services/risk-assessment.service';
import {MatTableDataSource} from '@angular/material/table';
import {Subscription} from 'rxjs';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {take} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {Project, ProjectService} from '../../services/project.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Risk} from '../../services/risk.service';

@Component({
  selector: 'app-risk-assessments-list',
  templateUrl: './risk-assessments-list.component.html',
  styleUrls: ['./risk-assessments-list.component.scss']
})
export class RiskAssessmentsListComponent implements OnInit, AfterViewInit, OnDestroy {

  displayedColumns: string[];
  @Input() templates: boolean;
  @Input() project: Project;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<RiskAssessment> = new MatTableDataSource<RiskAssessment>([]);
  isLoading = true;
  private indexRiskAssessmentsSub: Subscription;
  resultsLength = 0;

  constructor(
    private riskAssessmentService: RiskAssessmentService,
    private projectService: ProjectService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    if (!this.project || this.project.is_template) {
      this.displayedColumns = ['name', 'last_activity', 'created_at', 'actions'];
    } else {
      this.displayedColumns = ['number', 'name', 'probability_consequence', 'last_activity', 'created_at', 'actions'];
    }
  }

  ngAfterViewInit(): void {
    if (!this.project) {
      this.indexRiskAssessmentsSub = this.riskAssessmentService.indexTemplates().subscribe(
        (riskAssessments: Array<RiskAssessment>) => {
          this.dataSource = new MatTableDataSource(riskAssessments);
          Promise.resolve(null).then(() => {
            this.isLoading = false;
          });
          // custom sorting data accessor (when using child property's of objects)
          this.dataSource.sortingDataAccessor = (riskAssessment: RiskAssessment, property: string) => {
            switch (property) {
              case 'name':
                return riskAssessment.name.toLowerCase();
              case 'probability_consequence':
                return riskAssessment.highest_probability * riskAssessment.highest_consequence * (riskAssessment.highest_consequence / 2);
              default:
                return riskAssessment[property];
            }
          };

          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        }
      );
    } else {
      this.indexRiskAssessmentsSub = this.riskAssessmentService.index(this.project.id).subscribe(
        (riskAssessments: Array<RiskAssessment>) => {
          Promise.resolve(null).then(() => {
            this.isLoading = false;
            this.resultsLength = riskAssessments.length;
          });
          this.dataSource = new MatTableDataSource(riskAssessments);

          // custom sorting data accessor (when using child property's of objects)
          this.dataSource.sortingDataAccessor = (riskAssessment: RiskAssessment, property: string) => {
            switch (property) {
              case 'name':
                return riskAssessment.name.toLowerCase();
              case 'probability_consequence':
                return riskAssessment.highest_probability * riskAssessment.highest_consequence * (riskAssessment.highest_consequence / 2);
              default:
                return riskAssessment[property];
            }
          };

          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        },
        () => this.isLoading = false
      );
    }
  }

  openEditRiskAssessmentDialog(_riskAssessment: RiskAssessment) {
    const dialogRef = this.dialog.open(EditRiskAssessmentDialogComponent, {
      data: {
        riskAssessment: _riskAssessment,
        project: this.project ? this.project : null
      }, disableClose: true
    });
    dialogRef.afterClosed().subscribe((riskAssesment: RiskAssessment) => {
      if (riskAssesment) {
        const data = this.dataSource.data;
        const projectIndex = data.findIndex((_obj => _obj.id === riskAssesment.id));
        data[projectIndex] = riskAssesment;
        this.dataSource.data = data;
      }
    });
  }

  openDeleteRiskAssessmentDialog(_riskAssessment: RiskAssessment) {
    const dialogRef = this.dialog.open(ConfirmDeleteRiskAssessmentDialogComponent, {
      data: {
        riskAssessment: _riskAssessment,
        project: this.project ? this.project : null
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // remove the project from the table
        let data = this.dataSource.data;
        data = data.filter(obj => obj !== _riskAssessment);
        this.dataSource.data = data;
      }
    });
  }

  ngOnDestroy(): void {
    this.indexRiskAssessmentsSub.unsubscribe();
  }

  openSaveAsTemplateDialog(_riskAssessment: any) {

    const dialogRef = this.dialog.open(SaveRiskAssessmentAsTemplateDialogComponent, {
      data: {
        riskAssessment: _riskAssessment,
      }, disableClose: true
    });
  }
}

@Component({
  selector: 'app-confirm-delete-risk-assessment-dialog-component',
  templateUrl: '../confirm-delete-risk-assessment-dialog.html',
  styles: ['p:first-child { margin-top: 0; }', 'p:last-child { margin-bottom: 0; }']
})
export class ConfirmDeleteRiskAssessmentDialogComponent {
  isLoading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { riskAssessment: RiskAssessment, project: Project },
    private riskAssessmentService: RiskAssessmentService,
    private dialogRef: MatDialogRef<ConfirmDeleteRiskAssessmentDialogComponent>,
    private snackBar: MatSnackBar,
  ) {
  }

  deleteRiskAssessment() {
    this.isLoading = true;
    this.riskAssessmentService.delete(this.data.project, this.data.riskAssessment).subscribe((success: boolean) => {
      if (success) {
        this.dialogRef.close(true);
        this.snackBar.open('Risk assessment \"' + this.data.riskAssessment.name + '\" is deleted');
      }
    }, () => {
      this.isLoading = false;
    });
  }

}


@Component({
  selector: 'app-edit-risk-assessment-dialog',
  templateUrl: '../edit-risk-assessment-dialog.html',
  styles: ['mat-form-field { width: 100%; min-width: 350px; }']
})
export class EditRiskAssessmentDialogComponent implements OnInit {
  form: FormGroup;
  isLoading: boolean;
  hasError: boolean;
  errorMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { riskAssessment: RiskAssessment, project?: Project },
    private fb: FormBuilder,
    private riskAssessmentService: RiskAssessmentService,
    private dialogRef: MatDialogRef<EditRiskAssessmentDialogComponent>,
    public authService: AuthService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    if (this.data.project) {
      if (this.data.project.is_template) {
        this.form = this.fb.group({
          name: ['', Validators.required],
        });
      } else {
        this.form = this.fb.group({
          name: ['', Validators.required],
          number: ['', Validators.required],
        });
      }
    } else {
      this.form = this.fb.group({
        name: ['', Validators.required],
        is_templates: [true, Validators.required],
      });
    }
    this.form.patchValue(this.data.riskAssessment);
  }

  updateRiskAssessment() {
    if (this.form.valid) {
      this.form.disable();
      this.isLoading = true;
      this.data.riskAssessment = {...this.data.riskAssessment, ...this.form.value};
      this.riskAssessmentService.update(this.data.project, this.data.riskAssessment).subscribe((riskAssessment: RiskAssessment) => {
        this.snackBar.open('Risk Assessment \"' + this.data.riskAssessment.name + '\" was updated successfully');
        this.isLoading = false;
        this.dialogRef.close(riskAssessment);
      }, (e) => {
        if (e.error?.errors) {
          this.errorMessage = Object.values(e.error.errors).join(', ');
        }
        this.hasError = true;
        this.isLoading = false;
      })
    }
  }
}

@Component({
  selector: 'app-new-risk-assessment-dialog',
  templateUrl: '../new-risk-assessment-dialog.html',
  styles: ['mat-form-field { width: 100%; min-width: 350px; }']
})
export class NewRiskAssessmentDialogComponent
  implements OnInit {
  form: FormGroup;
  isLoading: boolean;
  hasError: boolean;
  errorMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { isTemplate?: boolean, project?: Project },
    private fb: FormBuilder,
    private riskAssessmentService: RiskAssessmentService,
    private dialogRef: MatDialogRef<NewRiskAssessmentDialogComponent>,
    public authService: AuthService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    if (this.data.project) {
      if (this.data.project.is_template) {
        this.form = this.fb.group({
          name: ['', Validators.required],
        });
      } else {
        this.form = this.fb.group({
          number: ['', Validators.required], // todo validate numbers only
          name: ['', Validators.required],
        });
      }
    } else {
      this.form = this.fb.group({
        name: ['', Validators.required],
        is_template: [true, Validators.required]
      });
    }
  }

  createNewRiskAssessment() {
    if (this.form.valid) {
      this.form.disable();
      this.isLoading = true;
      this.riskAssessmentService.create(this.data.project, this.form.value).subscribe((riskAssessment: RiskAssessment) => {
        this.snackBar.open('Risk Assessment \"' + this.form.controls.name.value + '\" is created successfully');
        this.dialogRef.close(riskAssessment);
      }, (e) => {
        if (e.error?.errors) {
          this.errorMessage = Object.values(e.error.errors).join(', ');
        }
        this.hasError = true;
        this.isLoading = false;
        this.form.enable();
      })
    }
  }

}


@Component({
  selector: 'app-save-risk-assessment-as-template-dialog',
  templateUrl: '../save-risk-assessment-as-template-dialog.html',
  styles: ['mat-form-field { width: 100%; min-width: 350px; }']
})
export class SaveRiskAssessmentAsTemplateDialogComponent
  implements OnInit {
  form: FormGroup;
  isLoading: boolean;
  hasError: boolean;
  errorMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { riskAssessment: RiskAssessment },
    private fb: FormBuilder,
    private riskAssessmentService: RiskAssessmentService,
    private dialogRef: MatDialogRef<NewRiskAssessmentDialogComponent>,
    public authService: AuthService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', Validators.required],
    });
    this.form.patchValue(this.data.riskAssessment);
  }

  createTemplate() {
    if (this.form.valid) {
      this.form.disable();
      this.isLoading = true;

      this.riskAssessmentService.create(null, {
        name: this.form.controls.name.value,
        is_template: true,
        from_template_id: this.data.riskAssessment.id,
      } as RiskAssessment).subscribe((riskAssessment: RiskAssessment) => {
        this.snackBar.open('Risk Assessment Template \"' + this.form.controls.name.value + '\" is created successfully');
        this.dialogRef.close(riskAssessment);
      }, (e) => {
        if (e.error?.errors) {
          this.errorMessage = Object.values(e.error.errors).join(', ');
        }
        this.hasError = true;
        this.isLoading = false;
        this.form.enable();
      });
    }
  }

}
