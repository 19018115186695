import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Role} from './role.service';
import {SuccessResponse} from '../app.component';
import {map, take} from 'rxjs/operators';
import {Project} from "./project.service";
import {RiskScope} from "./risk-scope.service";
import {RiskAssessment} from "./risk-assessment.service";

export interface Permission {
  id: number,
  name: string,
  display_name: string,
  description: string,
}

export interface User {
  id: string,
  initials: string,
  name: string,
  color: string,
  email?: string,
  created_at?: string,
  updated_at?: string,
  role?: Role,
  permissions?: Array<Permission>,
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  apiEndpoint = 'users';

  constructor(
    private http: HttpClient,
  ) {
  }

  index(): Observable<Array<User>> {
    return this.http.get<Array<User>>(environment.api_url + this.apiEndpoint, {withCredentials: true});
  }

  create(user: User): Observable<User> {
    return this.http.post<User>(environment.api_url + this.apiEndpoint, user, {withCredentials: true}).pipe(take(1));
  }

  update(user: User): Observable<User> {
    return this.http.put<User>(environment.api_url + this.apiEndpoint + '/' + user.id, user, {withCredentials: true}).pipe(take(1));
  }

  delete(user: User): Observable<boolean> {
    return this.http.delete<SuccessResponse>(environment.api_url + this.apiEndpoint + '/' + user.id, {withCredentials: true}).pipe(
      map((response: SuccessResponse) => {
        return response.success;
      }),
      take(1)
    );
  }

  indexProjectResponsibles(project: Project) {
    return this.http.get<Array<RiskScope>>(
      environment.api_url +
      'projects/' + project.id +
      '/responsibles',
      {withCredentials: true});
  }

  indexRiskAssessmentResponsibles(riskAssessment: RiskAssessment) {
    return this.http.get<Array<RiskScope>>(
      environment.api_url +
      'risk_assessments/' + riskAssessment.id +
      '/responsibles',
      {withCredentials: true});
  }

}
