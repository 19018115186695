import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Project, ProjectService} from '../services/project.service';
import {AuthService} from '../services/auth.service';
import {RiskAssessment} from '../services/risk-assessment.service';
import {
  NewRiskAssessmentDialogComponent,
  RiskAssessmentsListComponent
} from './risk-assessments-list/risk-assessments-list.component';
import {MatDialog} from '@angular/material/dialog';
import {take} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {NewRiskAssessmentFromTemplateDialogComponent} from './new-risk-assessment-from-template-dialog/new-risk-assessment-from-template-dialog.component';

@Component({
  selector: 'app-risk-assessments',
  templateUrl: './risk-assessments.component.html',
  styleUrls: ['./risk-assessments.component.scss']
})
export class RiskAssessmentsComponent implements OnInit, AfterViewInit {
  loadingProject: boolean;
  project: Project;
  private isLoading = true;
  private projectId: string;
  @ViewChild('riskAssessmentsListComponent') riskAssessmentsListComponent: RiskAssessmentsListComponent;


  constructor(
    public authService: AuthService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private projectService: ProjectService,
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.loadingProject = true;
  }


  openNewRiskAssessmentDialog() {
    const dialogRef = this.dialog.open(NewRiskAssessmentDialogComponent, {
      disableClose: true,
      data: {project: this.project}
    });
    dialogRef.afterClosed().subscribe((riskAssessment: RiskAssessment) => {
      if (riskAssessment) {
        const data = this.riskAssessmentsListComponent.dataSource.data;
        data.push(riskAssessment);
        this.riskAssessmentsListComponent.dataSource.data = data;
      }
    });
  }

  ngAfterViewInit(): void {
    this.route.params.pipe(take(1)).subscribe(params => {
      this.projectId = params.projectId;
      this.projectService.get(this.projectId).subscribe((project: Project) => {
        this.project = project;
        Promise.resolve(null).then(() => {
          this.loadingProject = false;
        });
      });
    });
  }


  openNewRiskAssessmentFromTemplateDialog() {
    const dialogRef = this.dialog.open(NewRiskAssessmentFromTemplateDialogComponent, {
      disableClose: true,
      data: {project: this.project}
    });
    dialogRef.afterClosed().subscribe((riskAssessment: RiskAssessment) => {
      if (riskAssessment) {
        const data = this.riskAssessmentsListComponent.dataSource.data;
        data.push(riskAssessment);
        this.riskAssessmentsListComponent.dataSource.data = data;
      }
    });
  }
}
