<h1 mat-dialog-title i18n="@@exportRiskAssessmentDialogHeader">Export Risk Assessment</h1>
<mat-dialog-content>
  <p i18n="@@exportRiskAssessmentDialogText">Download an .pdf export of this risk assessment. Select the desired format below.</p>
  <mat-form-field appearance="fill">
    <mat-label i18n="@@formatSelectLabel">Format</mat-label>
    <mat-select [(ngModel)]="chosenFormat">
      <mat-option *ngFor="let format of formats" [value]="format.name">
        {{format.display_name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label i18n="@@languageSelectLabel">Language</mat-label>
    <mat-select [(ngModel)]="chosenLanguage">
      <mat-option value="en">English</mat-option>
      <mat-option value="nl">Nederlands</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false" i18n="@@cancelBtnLabel">Cancel</button>
  <button mat-button color="warn" (click)="close()" i18n="@@exportBtnLabel">Export</button>
</mat-dialog-actions>
