<h1 mat-dialog-title i18n="@@editProjectDialogHeader" *ngIf="!project.is_template">Edit Project</h1>
<h1 mat-dialog-title i18n="@@editProjectTemplateDialogHeader" *ngIf="project.is_template">Edit Project Template</h1>
<form [formGroup]="form" (ngSubmit)="updateProject()">
  <div mat-dialog-content fxLayout="column" fxLayoutGap="16px">
    <span i18n="@@editProjectDialogText" *ngIf="!project.is_template">Edit the project information below</span>
    <span i18n="@@editProjectTemplateDialogText"
          *ngIf="project.is_template">Edit the project template information below</span>
    <div class="error" *ngIf="hasError">
      <span *ngIf="this.errorMessage">{{this.errorMessage}}</span>
      <span *ngIf="!this.errorMessage" i18n="@@dialogError">There was an error, please try it again.</span>
    </div>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@projectNameInputLabel" *ngIf="!project.is_template">Project name</mat-label>
        <mat-label i18n="@@templateNameInputLabel" *ngIf="project.is_template">Template name</mat-label>
        <input matInput i18n-placeholder="@@projectNameInputPlaceholder" placeholder="Example project" required
               formControlName="name">
        <mat-hint i18n="@@projectNameInputHint" *ngIf="!project.is_template">Enter the name of the project</mat-hint>
        <mat-hint i18n="@@templateNameInputHint" *ngIf="project.is_template">Enter the name of the template</mat-hint>
        <mat-error i18n="@@projectNameInputError" *ngIf="!project.is_template">
          Please provide the name of the project
        </mat-error>
        <mat-error i18n="@@templateNameInputError" *ngIf="project.is_template">
          Please provide the name of the template
        </mat-error>
      </mat-form-field>
    </p>
    <p *ngIf="!project.is_template">
      <mat-form-field appearance="fill">
        <mat-label i18n="@@caseNumberInputLabel">Case number</mat-label>
        <input matInput placeholder="PSNL-000000" required formControlName="case_number">
        <mat-hint i18n="@@caseNumberInputHint">Enter the case number of the project</mat-hint>
        <mat-error i18n="@@caseNumberInputError">
          Please use numbers only
        </mat-error>
      </mat-form-field>
    </p>
  </div>
  <mat-dialog-actions fxLayoutAlign="end" fxLayoutGap="16px">
    <a mat-button mat-dialog-close [disabled]="isLoading" i18n="@@cancelBtnLabel">Cancel</a>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-button color="primary" [loading]="isLoading" i18n="@@updateProjectBtnLabel" *ngIf="!project.is_template">Update project</button>
    <button mat-button color="primary" [loading]="isLoading" i18n="@@saveBtnLabel" *ngIf="project.is_template">Save</button>
  </mat-dialog-actions>
</form>

