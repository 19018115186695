<h1 mat-dialog-title i18n="@@newResultDialogHeader">New Result</h1>
<form [formGroup]="form" (ngSubmit)="createNewResult()">
  <div mat-dialog-content fxLayout="column" fxLayoutGap="16px">
    <span i18n="@@newResultDialogText">Add a new result for this action</span>
    <div class="error" *ngIf="hasError">
      <span *ngIf="this.errorMessage">{{this.errorMessage}}</span>
      <span *ngIf="!this.errorMessage" i18n="@@newResultDialogError">There was an error, please try it again.</span>
    </div>
    <div fxLayout="row" fxLayoutGap="16px">

      <section>
        <span i18n="@@newProbabilityInputLabel">New probability</span>
        <p>
          <mat-button-toggle-group formControlName="new_probability">
            <mat-button-toggle [value]="1" aria-label="1">
              1
            </mat-button-toggle>
            <mat-button-toggle [value]="2" aria-label="2">
              2
            </mat-button-toggle>
            <mat-button-toggle [value]="3" aria-label="3">
              3
            </mat-button-toggle>
            <mat-button-toggle [value]="4" aria-label="4">
              4
            </mat-button-toggle>
            <mat-button-toggle [value]="5" aria-label="5">
              5
            </mat-button-toggle>
          </mat-button-toggle-group>
        </p>
      </section>

      <span class="row-spacer"></span>

      <section>
        <span i18n="@@newConsequenceInputLabel">New consequence</span>
        <p style="text-align: right">
          <mat-button-toggle-group formControlName="new_consequence">
            <mat-button-toggle [value]="1" aria-label="1">
              1
            </mat-button-toggle>
            <mat-button-toggle [value]="2" aria-label="2">
              2
            </mat-button-toggle>
            <mat-button-toggle [value]="3" aria-label="3">
              3
            </mat-button-toggle>
            <mat-button-toggle [value]="4" aria-label="4">
              4
            </mat-button-toggle>
            <mat-button-toggle [value]="5" aria-label="5">
              5
            </mat-button-toggle>
          </mat-button-toggle-group>
        </p>
      </section>

    </div>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@descriptionInputLabel">Description</mat-label>
        <textarea formControlName="description" matInput cdkTextareaAutosize></textarea>
        <mat-hint i18n="@@resultDescriptionInputHint">Explain the new result</mat-hint>
        <mat-error i18n="@@resultDescriptionInputError">
          Please provide a explanation for the new result
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@executedAtInputLabel">Executed at</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="executed_at" (focus)="picker.open()">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-hint i18n="@@executedAtInputHint">Click the calendar icon to choose a date</mat-hint>
        <mat-error i18n="@@executedAtInputError">
          Please provide when the action was executed
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@executedByInputLabel">Executed By</mat-label>
        <mat-select formControlName="executed_by" placeholder="Executed By" #executedBySelect>
          <mat-option>
            <ngx-mat-select-search
              [formControl]="userFilterCtrl"
              placeholderLabel="Search"
              i18n-placeholderLabel="@@executedByInputPlaceholder"
              noEntriesFoundLabel="Nothing found"
              i18n-noEntriesFoundLabel="@@executedByInputNoEntriesFound"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
            {{user.name}}
          </mat-option>
        </mat-select>
        <mat-hint i18n="@@executedByInputHint">Change the user that executed the action if necessary</mat-hint>
        <mat-error i18n="@@executedByInputError">
          Please select a user
        </mat-error>
      </mat-form-field>
    </p>

  </div>
  <mat-dialog-actions fxLayoutAlign="end" fxLayoutGap="16px">
    <a mat-button mat-dialog-close="" [disabled]="isLoading" i18n="@@cancelBtnLabel">Cancel</a>
    <button mat-button color="primary" [loading]="isLoading" i18n="@@saveResultBtnLabel">Save result</button>
  </mat-dialog-actions>
</form>

