import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

export interface Role {
  name: string,
  display_name: string,
  description: string,
}


@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private apiEndpoint = 'roles';

  constructor(
    private http: HttpClient,
  ) {
  }

  index(): Observable<Array<Role>> {
    return this.http.get<Array<Role>>(environment.api_url + this.apiEndpoint, {withCredentials: true});
  }


}
