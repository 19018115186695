<table [fxHide]="isLoading" mat-table [dataSource]="dataSource" matSort matSortDisableClear
       matSortActive="last_activity"
       matSortDirection="desc">

  <ng-container matColumnDef="number">
    <th i18n="@@caseNumberTableHead" mat-header-cell *matHeaderCellDef mat-sort-header>Case Number</th>
    <td mat-cell *matCellDef="let riskAssessment">{{project.case_number + '.' + riskAssessment.number}}</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th i18n="@@riskAssessmentNameTableHead" mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let riskAssessment">{{riskAssessment.name}}</td>
  </ng-container>

  <ng-container matColumnDef="probability_consequence">
    <th i18n="@@highestProbConsTableHead" mat-header-cell *matHeaderCellDef mat-sort-header>Highest risk<br>Prob/Cons
    </th>
    <td mat-cell *matCellDef="let riskAssessment">
      <app-colored-risk-block *ngIf="riskAssessment.highest_consequence && riskAssessment.highest_probability"
                              [consequence]="riskAssessment.highest_consequence"
                              [probability]="riskAssessment.highest_probability"></app-colored-risk-block>
    </td>
  </ng-container>

  <!-- Last Activiy Column -->
  <ng-container matColumnDef="last_activity">
    <th i18n="@@lastActivityTableHead" mat-header-cell *matHeaderCellDef mat-sort-header>Last Activity</th>
    <td mat-cell *matCellDef="let riskAssessment">{{riskAssessment.last_activity | date: 'longDate'}}</td>
  </ng-container>

  <!-- Created At Column -->
  <ng-container matColumnDef="created_at">
    <th i18n="@@createdAtTableHead" mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
    <td mat-cell *matCellDef="let riskAssessment">{{riskAssessment.created_at | date: 'longDate'}}</td>
  </ng-container>

  <!-- Actions Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let riskAssessment;  let i = index;">
      <div fxLayoutAlign="end">
        <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{riskAssessment: riskAssessment}"
                (click)="$event.stopPropagation()"
                *ngIf="(authService.can('risk_assessments-delete') || authService.can('risk_assessments-update'))">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu" xPosition="before">
          <ng-template matMenuContent let-name="name">
            <button mat-menu-item (click)="openEditRiskAssessmentDialog(riskAssessment)"
                    *ngIf="authService.can('risk_assessments-update')">
              <mat-icon>edit</mat-icon>
              <span i18n="@@editBtnLabel">Edit</span><span> "{{riskAssessment.name}}"</span>
            </button>
            <button mat-menu-item (click)="openDeleteRiskAssessmentDialog(riskAssessment)"
                    *ngIf="authService.can('risk_assessments-delete')">
              <mat-icon>delete</mat-icon>
              <span i18n="@@deleteBtnLabel">Delete</span><span> "{{riskAssessment.name}}"</span>
            </button>
            <button mat-menu-item (click)="openSaveAsTemplateDialog(riskAssessment)"
                    *ngIf="authService.can('risk_assessments_templates-create') && project">
              <mat-icon>library_add</mat-icon>
              <span i18n="@@saveAsTemplateBtnLabel">Save as Template</span>
            </button>
          </ng-template>
        </mat-menu>

      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let riskAssessment; columns: displayedColumns;"
      [routerLink]="project ? (project.is_template ? ['/templates/project', project.id, 'risk-assessments', riskAssessment.id] : ['/projects', project.id, 'risk-assessments', riskAssessment.id]) : ['/templates/risk-assessments/', riskAssessment.id]"
      class="clickable hover"></tr>

</table>

<div *ngIf="isLoading" class="table_spinner">
  <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
</div>

<mat-paginator [fxHide]="isLoading" [length]="resultsLength" [pageSize]="10" color="accent"
               showFirstLastButtons></mat-paginator>
