import {Component} from '@angular/core';

export interface SuccessResponse {
  success?: boolean,
  message?: string,
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'seconed-risicoanalyse-platform-frontend';
}
