import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {NewProjectDialogComponent, ProjectsTableComponent} from '../projects/projects-table/projects-table.component';
import {
  NewRiskAssessmentDialogComponent,
  RiskAssessmentsListComponent
} from '../risk-assessments/risk-assessments-list/risk-assessments-list.component';
import {Project} from '../services/project.service';
import {MatDialog} from '@angular/material/dialog';
import {RiskAssessment} from '../services/risk-assessment.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {

  @ViewChild('search') search: ElementRef;
  @ViewChild('projectsTableComponent') projectsTableComponent: ProjectsTableComponent;
  @ViewChild('riskAssessmentsListComponent') riskAssessmentsListComponent: RiskAssessmentsListComponent;

  searchActive: boolean;
  selectedTabIndex: number;

  constructor(
    private dialog: MatDialog,
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.selectedTabIndex = parseInt(this.route.snapshot.queryParamMap.get('tab'), 10);
    if (isNaN(this.selectedTabIndex) || this.selectedTabIndex < 0) {
      this.selectedTabIndex = 0;
    }
  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchActive = filterValue.length > 0;
    this.projectsTableComponent.dataSource.filter = filterValue.trim().toLowerCase();
    this.riskAssessmentsListComponent.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilters() {
    this.search.nativeElement.value = '';
    this.projectsTableComponent.dataSource.filter = '';
    this.riskAssessmentsListComponent.dataSource.filter = '';
    this.searchActive = false;
  }

  openNewProjectTemplateDialog() {
    const dialogRef = this.dialog.open(NewProjectDialogComponent, {disableClose: true, data: {isTemplate: true}});
    dialogRef.afterClosed().subscribe((project: Project) => {
      if (project) {
        const data = this.projectsTableComponent.dataSource.data;
        data.push(project);
        this.projectsTableComponent.dataSource.data = data;
      }
    });
  }

  openNewRiskAssessmentTemplate() {
    const dialogRef = this.dialog.open(NewRiskAssessmentDialogComponent, {
      disableClose: true,
      data: {project: false}
    });
    dialogRef.afterClosed().subscribe((riskAssessment: RiskAssessment) => {
      if (riskAssessment) {
        const data = this.riskAssessmentsListComponent.dataSource.data;
        data.push(riskAssessment);
        this.riskAssessmentsListComponent.dataSource.data = data;
      }
    });
  }

  onTabChange(selectedTabIndex: number): void {
    this.router.navigate([], {
      relativeTo: this.route, queryParams: {
        tab: selectedTabIndex
      }
    });
    this.selectedTabIndex = selectedTabIndex;
  }
}
