<h1 mat-dialog-title i18n="@@newProjectDialogHeader" *ngIf="!isTemplate">New Project</h1>
<h1 mat-dialog-title i18n="@@newProjectTemplateDialogHeader" *ngIf="isTemplate">New Project Template</h1>
<form [formGroup]="form" (ngSubmit)="createNewProject()">
  <div mat-dialog-content fxLayout="column" fxLayoutGap="16px">
    <span i18n="@@newProjectDialogText" *ngIf="!isTemplate">Create a new project</span>
    <span i18n="@@newProjectTemplateDialogText" *ngIf="isTemplate">Create a new project template</span>
    <div class="error" *ngIf="hasError">
      <span *ngIf="this.errorMessage">{{this.errorMessage}}</span>
      <span *ngIf="!this.errorMessage" i18n="@@dialogError">There was an error, please try it again.</span>
    </div>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@projectNameInputLabel" *ngIf="!isTemplate">Project name</mat-label>
        <mat-label i18n="@@templateNameInputLabel" *ngIf="isTemplate">Template name</mat-label>
        <input matInput i18n-placeholder="@@projectNameInputPlaceholder" placeholder="Example project" required
               formControlName="name">
        <mat-hint i18n="@@projectNameInputHint" *ngIf="!isTemplate">Enter the name of the project</mat-hint>
        <mat-hint i18n="@@templateNameInputHint" *ngIf="isTemplate">Enter the name of the template</mat-hint>
        <mat-error i18n="@@projectNameInputError" *ngIf="!isTemplate">
          Please provide the name of the project
        </mat-error>
        <mat-error i18n="@@templateNameInputError" *ngIf="isTemplate">
          Please provide the name of the template
        </mat-error>
      </mat-form-field>
    </p>
    <p *ngIf="!isTemplate">
      <mat-form-field appearance="fill">
        <mat-label i18n="@@caseNumberInputLabel">Case number</mat-label>
        <input matInput placeholder="PSNL-000000" required formControlName="case_number">
        <mat-hint i18n="@@caseNumberInputHint">Enter the case number of the project</mat-hint>
        <mat-error i18n="@@caseNumberInputError">
          Please use numbers only
        </mat-error>
      </mat-form-field>
    </p>
  </div>
  <mat-dialog-actions fxLayoutAlign="end" fxLayoutGap="16px">
    <a mat-button mat-dialog-close [disabled]="isLoading" i18n="@@cancelBtnLabel">Cancel</a>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-button color="primary" [loading]="isLoading">
      <span i18n="@@createProjectBtnLabel" *ngIf="!isTemplate">Create Project</span>
      <span i18n="@@createTemplateBtnLabel" *ngIf="isTemplate">Create Template</span>
    </button>
  </mat-dialog-actions>
</form>

