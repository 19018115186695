import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Risk, RiskService} from '../../services/risk.service';
import {ProjectService} from '../../services/project.service';
import {RiskAssessment, RiskAssessmentService} from '../../services/risk-assessment.service';
import {Observable, of, Subscription} from 'rxjs';
import {RiskScope} from '../../services/risk-scope.service';
import {User} from '../../services/user.service';
import {RiskCategory} from '../../services/risk-category.service';
import {RiskActionPhase} from '../../services/risk-action-phase.service';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog,} from '@angular/material/dialog';
import {RiskAction} from '../../services/risk-action.service';
import {take} from 'rxjs/operators';
import {NewActionDialogComponent} from '../../risks/new-action-dialog/new-action-dialog.component';
import {EditActionDialogComponent} from '../../risks/edit-action-dialog/edit-action-dialog.component';
import {ConfirmDeleteActionDialogComponent} from '../../risks/confirm-delete-action-dialog/confirm-delete-action-dialog.component';
import {
  ConfirmDeleteRiskDialogComponent,
  EditRiskDialogComponent,
  NewRiskDialogComponent
} from '../../risks/risks.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ImportRisksDialogComponent} from '../../risks/import-risks-dialog/import-risks-dialog.component';

@Component({
  selector: 'app-risk-templates',
  templateUrl: './risk-templates.component.html',
  styleUrls: ['./risk-templates.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({height: '0px'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
})
export class RiskTemplatesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;

  isLoading: boolean;
  loadingRiskAssessment: boolean;
  risksDataSource: MatTableDataSource<Risk> = new MatTableDataSource<Risk>([]);
  displayedColumns: string[] = ['risk_buttons_prefix', 'number', 'category', 'created_by', 'scope', 'description', 'identified_at', 'probability_consequence', 'risk_buttons_suffix'];
  actionDisplayedColumns: string[] = ['description', 'responsible', 'phase', 'action_probability_consequence', 'action_buttons_suffix'];
  riskAssessment: RiskAssessment;
  private projectId: string;
  private riskAssessmentId: string;
  private indexRisksSub: Subscription;
  someExpanded: boolean;
  scrolledFromTop: boolean;
  $scopes: Observable<Array<RiskScope>>;
  $createdBys: Observable<Array<User>>;
  $categories: Observable<Array<RiskCategory>>;
  $responsibles: Observable<Array<User | string>>;
  $phases: Observable<Array<RiskActionPhase>>;
  private filterValues = {};
  private actionFilterValues = {};
  filterActive: boolean;
  scopeFilterModel = [];
  assignedToFilterModel = [];
  categoryFilterModel = [];
  phaseFilterModel = [];
  createdByFilterModel = [];
  actionsDataSources = {};
  actionResultsDataSources = {};
  actionFilterActive: boolean;
  nobodyString: any;

  constructor(
    private projectService: ProjectService,
    private riskAssessmentService: RiskAssessmentService,
    public authService: AuthService,
    public riskService: RiskService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {

  }

  @HostListener('window:scroll', ['$event']) onScrollEvent() {
    const pos = (document.documentElement.scrollTop || document.body.scrollTop);
    if (pos > 1 && this.scrolledFromTop !== true) {
      this.scrolledFromTop = true;
    } else if (pos <= 1) {
      this.scrolledFromTop = false;
    }
  }

  ngOnInit(): void {
    this.nobodyString = $localize`:@@nobodyLabel:Nobody`;
    this.isLoading = true;
    this.loadingRiskAssessment = true;
  }

  // Called on Risk filters change
  riskFilterChange(filter, value) {
    this.filterValues[filter] = value;
    this.risksDataSource.filter = JSON.stringify(this.filterValues)
  }

  // Called on Action filters change
  actionFilterChange(filter, value) {
    this.actionFilterValues[filter] = value;
    if(value === '' || value.length === 0){
      delete this.actionFilterValues[filter];
    }
    this.actionFilterActive = !!Object.keys(this.actionFilterValues).length;
    const keys = Object.keys(this.actionsDataSources);
    keys.forEach(key => {
      console.log('this.actionFilterValues', this.actionFilterValues);
      this.actionsDataSources[key].filter = JSON.stringify(this.actionFilterValues);
    });
  }

  // Custom filter method for Risks table
  createFilter() {
    /*This method is called for each risk row, to check if it should be filtered out (hidden, found = false) or not (shown, found = true)*/
    return (risk: Risk, filterData: string): boolean => {
      const filters = JSON.parse(filterData);

      this.filterActive = false;
      const allFilters = {...filters, ...this.actionFilterValues};
      Object.keys(allFilters).forEach(
        (filter: string) => {
          const values: [any] = allFilters[filter];
          if (values.length) {
            this.filterActive = true;
          } else {
            delete filters[filter];
          }
        }
      );

      let found = false;

      if (!this.filterActive) {
        found = true;
      } else {
        // loop through all the filters and values
        found = true;
        Object.keys(filters).forEach(
          (filter: string) => {
            const values: [any] = filters[filter];
            switch (filter) {
              case 'scopes':
                // custom risk scopes filter
                if (!values.includes(risk.scope.id)) {
                  found = false;
                }
                break;
              case 'created_by':
                // custom risk created_by filter
                if (!values.includes(risk.created_by?.id)) {
                  found = false;
                }
                break;
              case 'category':
                // custom risk category filter
                if (!values.includes(risk.category.id)) {
                  found = false;
                }
                break;
            }
          }
        );
      }

      // when found === false,
      // the current risk does not meet the filter values,
      // so it's hidden in the table overview
      // when found === true,
      // the current risk complies to the filters,
      // so it's shown in the table overview

      return found;
    }
  }

  // Custom filter method for R=risks action tables
  createActionFilter() {
    return (action: RiskAction, filterData: string): boolean => {
      const filters = JSON.parse(filterData);

      this.filterActive = false;
      const allFilters = {...filters, ...this.filterValues};
      Object.keys(allFilters).forEach(
        (filter: string) => {
          const values: [any] = allFilters[filter];
          if (values.length) {
            this.filterActive = true;
          } else {
            delete filters[filter];
          }
        }
      );

      let found = false;

      if (!this.filterActive) {
        found = true;
      } else {
        // loop through all the filters and values
        found = true;
        Object.keys(filters).forEach(
          (filter: string) => {
            const values: [any] = filters[filter];
            switch (filter) {
              case 'assigned_to':
                if (
                  !values.includes(`${action.assigned_to?.name} (${action.assigned_to?.initials})`)
                  && !values.includes(action.responsible)
                  && !(values.includes(this.nobodyString) && (!action.assigned_to && !action.responsible) )
                ) {
                  found = false;
                }
                break;
              case 'phase':
                if (!values.includes(action.phase.id)) {
                  found = false;
                }
                break;
            }
          }
        );
      }

      return found;
    }
  }

  // Reset table filters
  resetFilters() {
    this.filterValues = {};
    this.actionFilterValues = {};
    this.risksDataSource.filter = '';
    const keys = Object.keys(this.actionsDataSources);
    keys.forEach(key => {
      this.actionsDataSources[key].filter = '';
    });
    this.filterActive = false;
    this.actionFilterActive = false;
    this.scopeFilterModel = [];
    this.assignedToFilterModel = [];
    this.categoryFilterModel = [];
    this.phaseFilterModel = [];
    this.createdByFilterModel = [];
  }

  ngAfterViewInit(): void {
    this.route.params.pipe(take(1)).subscribe(params => {
      this.projectId = params.projectId;
      this.riskAssessmentId = params.riskAssessmentId;
      this.riskAssessmentService.getTemplate(this.riskAssessmentId).subscribe(
        (riskAssessment: RiskAssessment) => {
          this.riskAssessment = riskAssessment;

          Promise.resolve(null).then(() => {
            this.loadingRiskAssessment = false;
          });

          this.indexRisksSub = this.riskService.indexTemplates(this.riskAssessment).subscribe(
            (risks: Array<Risk>) => {
              Promise.resolve(null).then(() => {
                this.isLoading = false;
              });
              this.risksDataSource = new MatTableDataSource(risks);

              this.updateFilterOptions();

              this.risksDataSource.filterPredicate = this.createFilter();

              risks.forEach((risk: Risk) => {
                this.actionsDataSources[risk.id] = new MatTableDataSource(risk.actions);
                this.actionsDataSources[risk.id].filterPredicate = this.createActionFilter();
                risk.actions.forEach((action: RiskAction) => {
                  this.actionResultsDataSources[action.id] = new MatTableDataSource(action.results);
                });
              });

              // custom sorting data accessor (when using child property's of objects)
              this.risksDataSource.sortingDataAccessor = (risk: Risk, property: string) => {
                switch (property) {
                  case 'category':
                    return risk.category.display_name.toLowerCase();
                  case 'scope':
                    return risk.scope.display_name.toLowerCase();
                  case 'created_by':
                    return risk.created_by?.name.toLowerCase();
                  case 'probability_consequence':
                    return risk.highest_probability * risk.highest_consequence * (risk.highest_consequence / 2);
                  default:
                    return risk[property];
                }
              };

              this.risksDataSource.sort = this.sort;
            },
            () => {
              this.isLoading = false;
              this.loadingRiskAssessment = false;
            }
          );
        },
        () => {
          this.isLoading = false;
          this.loadingRiskAssessment = false;
        }
      );
    });
  }

  ngOnDestroy(): void {
    this.indexRisksSub.unsubscribe();
  }

  toggleExpandAllRisks() {
    // check if needs to expand or collapse all
    if (this.someExpanded) {
      // collapse all risks because some where already expanded
      this.risksDataSource.data.forEach((risk: Risk) => {
        risk.expanded = false;
        // collapse all risk actions
        risk.actions.forEach((action: RiskAction) => action.expanded = false);
      });
      this.someExpanded = false;
    } else {
      // expand all risks because none where expanded
      this.risksDataSource.data.forEach((risk: Risk) => risk.expanded = true);
      this.someExpanded = true;
    }
  }

  toggleExpandRisk(risk: any) {
    if (!risk.expanded) {
      // if risk is not already expanded, its gonna expand, so set someExpanded to true
      this.someExpanded = true;
    } else {
      // Risk is gonna collapse, so collapse all the underlying actions as well
      risk.actions.forEach((action: RiskAction) => action.expanded = false);
    }
    // toggle expanded boolean on risk
    risk.expanded = !risk.expanded;
    // if risk is not expanded now, it was expanded before,
    // so check if there is still an expanded risk left, if not toggle this.someExpanded
    if (!risk.expanded) {
      this.someExpanded = (this.risksDataSource.data.filter((r: Risk) => r.expanded === true).length > 0);
    }
  }

  openNewRiskDialog() {
    const dialogRef = this.dialog.open(NewRiskDialogComponent, {
      disableClose: true,
      data: {riskAssessment: this.riskAssessment}
    });
    dialogRef.afterClosed().subscribe((risk: Risk) => {
      if (risk) {
        const data = this.risksDataSource.data;
        data.push(risk);
        this.risksDataSource.data = data;
        this.updateFilterOptions();
      }
    });
  }

  openEditRiskDialog(_risk: RiskAssessment) {
    const dialogRef = this.dialog.open(EditRiskDialogComponent, {
      data: {
        risk: _risk,
        riskAssessment: this.riskAssessment,
      }, disableClose: true
    });
    dialogRef.afterClosed().subscribe((risk: Risk) => {
      if (risk) {
        const data = this.risksDataSource.data;
        const index = data.findIndex((_obj => _obj.id === risk.id));
        risk.expanded = _risk.expanded;
        data[index] = risk;
        this.risksDataSource.data = data;
        this.updateFilterOptions();
      }
    });
  }

  scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  openDeleteRiskDialog(_risk: Risk) {
    const dialogRef = this.dialog.open(ConfirmDeleteRiskDialogComponent, {
      data: {
        risk: _risk,
        riskAssessment: this.riskAssessment,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // remove the project from the table
        let data = this.risksDataSource.data;
        data = data.filter(obj => obj !== _risk);
        this.risksDataSource.data = data;
        this.updateFilterOptions()
      }
    });
  }

  private updateFilterOptions() {
    const risks = this.risksDataSource.data;
    // update scope filter options
    // tslint:disable-next-line:max-line-length
    const scopesDistinct = risks.map((risk: Risk) => risk.scope).filter((scope, i, arr) => arr.findIndex(t => t.id === scope.id) === i);
    this.$scopes = of(scopesDistinct);

    // update category filter options
    // tslint:disable-next-line:max-line-length
    const categoriesDistinct = risks.map((risk: Risk) => risk.category).filter((category, i, arr) => arr.findIndex(t => t.id === category.id) === i);
    this.$categories = of(categoriesDistinct);

    // update create by user filter options
    // tslint:disable-next-line:max-line-length
    const createdBysDistinct = risks.map((risk: Risk) => risk.created_by).filter((user, i, arr) => arr.findIndex(t => t?.id === user?.id) === i);
    this.$createdBys = of(createdBysDistinct);

    // update create by user filter options
    // tslint:disable-next-line:max-line-length
    let allActions = [];
    risks.forEach((_risk: Risk) => allActions = allActions.concat(_risk.actions));

    const responsiblesDistinct = allActions
      .map((action: RiskAction) => action.responsible ? action.responsible : (action.assigned_to ? `${action.assigned_to.name} (${action.assigned_to?.initials})` : this.nobodyString))
      .filter((name, i, arr) => arr.findIndex(t => t === name) === i
      );
    this.$responsibles = of(responsiblesDistinct);

    const phasesDistinct = allActions
      .map((action: RiskAction) => action.phase)
      .filter((phase, i, arr) => arr.findIndex(t => t.id === phase.id) === i
      );
    this.$phases = of(phasesDistinct);
  }

  openNewActionDialog(risk: Risk) {
    const dialogRef = this.dialog.open(NewActionDialogComponent, {
      disableClose: true,
      data: {riskAssessment: this.riskAssessment, risk}
    });
    dialogRef.afterClosed().subscribe((action: RiskAction) => {
      if (action) {
        // create actionsDataSources for risk if not already existed
        if (!this.actionsDataSources[risk.id]) {
          this.actionsDataSources[risk.id] = new MatTableDataSource(risk.actions);
          this.actionsDataSources[risk.id].filterPredicate = this.createActionFilter();
        }

        // add newly created action to nested action table data source
        const actionData = this.actionsDataSources[risk.id].data;
        if (actionData) {
          actionData.push(action);
        }
        this.actionsDataSources[risk.id].data = actionData;

        // add newly created action to risk table data source
        const riskIndex = this.risksDataSource.data.indexOf(risk);
        const riskData = this.risksDataSource.data;
        riskData[riskIndex].actions = actionData;
        this.risksDataSource.data = riskData;

        this.updateFilterOptions();
      }
    });
  }

  openEditActionDialog(risk: Risk, _action: RiskAction) {
    const dialogRef = this.dialog.open(EditActionDialogComponent, {
      data: {
        riskAssessment: this.riskAssessment,
        risk,
        action: _action,
      }, disableClose: true
    });
    dialogRef.afterClosed().subscribe((action: RiskAction) => {
      if (action) {
        // update action in nested actions table data source
        const actionData = this.actionsDataSources[risk.id].data;
        const actionIndex = actionData.indexOf(_action);
        if (actionData) {
          action.expanded = _action.expanded;
          actionData[actionIndex] = action;
        }
        this.actionsDataSources[risk.id].data = actionData;

        // add newly created action to risk table data source
        const riskIndex = this.risksDataSource.data.indexOf(risk);
        const riskData = this.risksDataSource.data;
        riskData[riskIndex].actions = actionData;
        this.risksDataSource.data = riskData;

        this.updateFilterOptions();
      }
    });
  }

  openDeleteActionDialog(risk: Risk, action: RiskAction) {
    const dialogRef = this.dialog.open(ConfirmDeleteActionDialogComponent, {
      data: {
        riskAssessment: this.riskAssessment,
        risk,
        action,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // remove the action from the risk action data source
        let actionsData = this.actionsDataSources[risk.id].data;
        actionsData = actionsData.filter(obj => obj.id !== action.id);
        this.actionsDataSources[risk.id].data = actionsData;

        // remove the action from the risk table data source
        const risksData = this.risksDataSource.data;
        const riskIndex = this.risksDataSource.data.indexOf(risk);
        risksData[riskIndex].actions = risksData[riskIndex].actions.filter(obj => obj.id !== action.id);
        this.risksDataSource.data = risksData;

        this.updateFilterOptions()
      }
    });
  }

  openImportRiskDialog() {
    const dialogRef = this.dialog.open(ImportRisksDialogComponent, {
      disableClose: true,
      data: {project: null, riskAssessment: this.riskAssessment}
    });
    dialogRef.afterClosed().subscribe((risks: Risk[]) => {
      if (risks) {
        const data = this.risksDataSource.data;
        risks.forEach((risk: Risk) => {
          data.push(risk);
          if (!this.actionsDataSources[risk.id]) {
            this.actionsDataSources[risk.id] = new MatTableDataSource(risk.actions);
            this.actionsDataSources[risk.id].filterPredicate = this.createActionFilter();
          }
        });
        this.risksDataSource.data = data;

        risks.forEach((risk: Risk) => {
          risk.actions.forEach((action: RiskAction) => {

            // create actionsDataSources for risk if not already existed
            if (!this.actionsDataSources[risk.id]) {
              this.actionsDataSources[risk.id] = new MatTableDataSource(risk.actions);
              this.actionsDataSources[risk.id].filterPredicate = this.createActionFilter();
            }

            // add newly created action to nested action table data source
            const actionData = this.actionsDataSources[risk.id].data;
            if (actionData) {
              actionData.push(action);
            }
            this.actionsDataSources[risk.id].data = actionData;

            // add newly created action to risk table data source
            const riskIndex = this.risksDataSource.data.indexOf(risk);
            const riskData = this.risksDataSource.data;
            riskData[riskIndex].actions = actionData;
            this.risksDataSource.data = riskData;

          });
        });
        this.updateFilterOptions();
      }
    });
  }

}
