import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-download-risk-assessment-pdfdialog',
  templateUrl: './download-risk-assessment-pdfdialog.component.html',
  styleUrls: ['./download-risk-assessment-pdfdialog.component.scss']
})
export class DownloadRiskAssessmentPDFDialogComponent implements OnInit {
  chosenFormat: string;
  chosenLanguage: string;
  formats = [
    {
      name: 'full',
      display_name: $localize`:@@fullPrintoutLabel:Full`,
    },
    {
      name: 'risks',
      display_name: $localize`:@@onlyRisksLabel:Only Risks`
    },
    {
      name: 'actions',
      display_name: $localize`:@@risksAndActionsLabel:Risks and Actions`,
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<DownloadRiskAssessmentPDFDialogComponent>,
    @Inject(LOCALE_ID) public locale: string
  ) {
  }

  ngOnInit(): void {
    this.chosenFormat = this.formats[0].name;
    this.chosenLanguage = this.locale.split('-', 1)[0];
  }

  close() {
    this.dialogRef.close({format: this.chosenFormat, language: this.chosenLanguage});
  }
}
