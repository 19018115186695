import {AfterContentInit, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Project} from '../../services/project.service';
import {RiskAssessment, RiskAssessmentService} from '../../services/risk-assessment.service';
import {AuthService} from '../../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ReplaySubject, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-new-risk-assessment-from-template-dialog',
  templateUrl: './new-risk-assessment-from-template-dialog.component.html',
  styleUrls: ['./new-risk-assessment-from-template-dialog.component.scss']
})
export class NewRiskAssessmentFromTemplateDialogComponent implements OnInit, AfterContentInit {
  form: FormGroup;
  isLoading: boolean;
  hasError: boolean;
  errorMessage: string;
  protected _onDestroy = new Subject<void>();
  templateFilterCtrl: FormControl = new FormControl();
  filteredTemplates: ReplaySubject<RiskAssessment[]> = new ReplaySubject<RiskAssessment[]>(1);
  private riskAssessments: Array<RiskAssessment>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { isTemplate?: boolean, project?: Project },
    private fb: FormBuilder,
    private riskAssessmentService: RiskAssessmentService,
    private dialogRef: MatDialogRef<NewRiskAssessmentFromTemplateDialogComponent>,
    public authService: AuthService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
    if (this.data.project.is_template) {
      this.form = this.fb.group({
        from_template_ids: ['', Validators.required],
        name: ['', Validators.required],
        is_template: [true, Validators.required],
      });
    } else {
      this.form = this.fb.group({
        from_template_ids: ['', Validators.required],
        number: ['', Validators.required],
        name: ['', Validators.required],
      });
    }
  }

  createNewRiskAssessment() {
    if (this.form.valid) {
      this.form.disable();
      this.isLoading = true;
      this.riskAssessmentService.create(this.data.project, this.form.value).subscribe((riskAssessment: RiskAssessment) => {
        this.snackBar.open('Risk Assessment \"' + this.form.controls.name.value + '\" is created successfully');
        this.dialogRef.close(riskAssessment);
      }, (e) => {
        if (e.error?.errors) {
          this.errorMessage = Object.values(e.error.errors).join(', ');
        }
        this.hasError = true;
        this.isLoading = false;
        this.form.enable();
      })
    }
  }

  ngAfterContentInit(): void {

    this.riskAssessmentService.indexTemplates().pipe(take(1)).subscribe((riskAssessments: Array<RiskAssessment>) => {
      this.riskAssessments = riskAssessments;
      this.filteredTemplates.next(this.riskAssessments.slice());
    });

    this.templateFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTemplates();
      });
  }

  protected filterTemplates() {
    if (!this.riskAssessments) {
      return;
    }
    // get the search keyword
    let search = this.templateFilterCtrl.value;
    if (!search) {
      this.filteredTemplates.next(this.riskAssessments.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredTemplates.next(
      this.riskAssessments.filter(user => user.name.toLowerCase().indexOf(search) > -1)
    );
  }

}
