<section class="container" fxFlex.gt-md="50" fxLayout="column" fxLayoutGap="16px">

  <div class="title_and_searchbar dark-theme" fxLayoutAlign="start center">

    <span class="mat-headline title" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-icon>person</mat-icon>
      <span i18n="@@myAccountHeader">My Account</span>
    </span>

    <span class="row-spacer"></span>

  </div>

  <form [formGroup]="contactForm" (ngSubmit)="onSubmitContact()">

    <mat-card class="mat-elevation-z15 no-container-padding">

      <mat-card-title i18n="@@personalInformationHeader">Personal information</mat-card-title>

      <mat-card-subtitle i18n="@@personalInformationText">Edit your personal information below if necessary</mat-card-subtitle>

      <mat-card-content fxLayout="column" fxLayoutGap="16px" *ngIf="!isLoading">

        <mat-form-field appearance="fill">
          <mat-label i18n="@@fullNameInputLabel">Full name</mat-label>
          <input matInput i18n-placeholder="@@fullNameInputPlaceholder" placeholder="Fill in your full name" formControlName="name" required>
          <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
          <mat-error i18n="@@fullNameInputError">
            Please provide a full name
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label i18n="@@initialsInputLabel">Initials</mat-label>
          <input matInput i18n-placeholder="@@initialsInputPlaceholder" placeholder="Initials" required formControlName="initials">
          <mat-icon matSuffix>label</mat-icon>
          <mat-hint i18n="@@initialsInputHint">Enter the initials of the user</mat-hint>
          <mat-error i18n="@@initialsInputError">
            Please provide the initials of the user
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label i18n="@@emailInputLabel">Email</mat-label>
          <input matInput i18n-placeholder="@@emailInputPlaceholder" placeholder="Fill in your e-mail address" formControlName="email" required>
          <mat-icon matSuffix>alternate_email</mat-icon>
          <mat-error i18n="@@emailInputError">
            Please provide a valid email address
          </mat-error>
        </mat-form-field>

        <div fxLayoutAlign="end">
          <button mat-flat-button [loading]="isSavingContact" color="primary" i18n="@@saveBtnLabel">Save</button>
        </div>

      </mat-card-content>

      <div fxLayoutAlign="center center" *ngIf="isLoading">
        <mat-spinner color="accent"></mat-spinner>
      </div>

    </mat-card>

  </form>

  <form [formGroup]="securityForm" #formDirective="ngForm" (ngSubmit)="onSubmitSecurity(formDirective)">

    <mat-card class="mat-elevation-z15 no-container-padding">

      <mat-card-title i18n="@@accountSecurityHeader">Account security</mat-card-title>

      <mat-card-subtitle i18n="@@accountSecurityText">Edit your security information below if necessary</mat-card-subtitle>

      <mat-card-content fxLayout="column" fxLayoutGap="16px" *ngIf="!isLoading">

        <mat-form-field appearance="fill">
          <mat-label i18n="@@currentPasswordInputLabel">Current password</mat-label>
          <input matInput type="password" i18n-placeholder="@@currentPasswordInputPlaceholder" placeholder="Fill in your current password" formControlName="password_old"
                 required (keydown.enter)='$event.target.blur();onSubmitSecurity(formDirective);'>
          <mat-icon matSuffix>password</mat-icon>
          <mat-error i18n="@@currentPasswordInputError">
            Please type your old password
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label i18n="@@newPasswordInputLabel">New password</mat-label>
          <input matInput i18n-placeholder="@@newPasswordInputPlaceholder" type="password" placeholder="Fill in your new password" formControlName="password_new"
                 required (keydown.enter)='$event.target.blur();onSubmitSecurity(formDirective);'>
          <mat-icon matSuffix>password</mat-icon>
          <mat-error i18n="@@newPasswordInputError">
            Please type your new password
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label i18n="@@repeatPasswordInputLabel">Repeat new password</mat-label>
          <input matInput type="password" i18n-placeholder="@@repeatPasswordInputPlaceholder" placeholder="Repeat your new password" formControlName="password_new2"
                 required (keydown.enter)='$event.target.blur();onSubmitSecurity(formDirective);'>
          <mat-icon matSuffix>password</mat-icon>
          <mat-error i18n="@@repeatPasswordInputError">
            Please repeat your new password
          </mat-error>
        </mat-form-field>

        <div fxLayoutAlign="end">
          <button mat-flat-button [loading]="isSavingSecurity" color="primary" i18n="@@updatePasswordBtnLabel">Update password</button>
        </div>

      </mat-card-content>

      <div fxLayoutAlign="center center" *ngIf="isLoading">
        <mat-spinner color="accent"></mat-spinner>
      </div>

    </mat-card>

  </form>


</section>
