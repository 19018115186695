<table [fxHide]="isLoading" mat-table [dataSource]="dataSource" matSort matSortDisableClear
       matSortActive="last_activity"
       matSortDirection="desc">

  <ng-container matColumnDef="symbol">
    <th mat-header-cell *matHeaderCellDef></th>
    <td style="width: 30px; padding-right: 24px;" mat-cell *matCellDef="let project">
      <app-letter-logo [letter]="project.name[0]" [color]="project.color"></app-letter-logo>
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span i18n="@@projectNameTableHead" *ngIf="!templates">Project Name</span>
      <span i18n="@@templatesNameTableHead" *ngIf="templates">Template Name</span>
    </th>
    <td mat-cell *matCellDef="let project">{{project.name}}</td>
  </ng-container>

  <!-- Email Column -->
  <ng-container matColumnDef="case_number" *ngIf="!templates">
    <th i18n="@@caseNumberTableHead" mat-header-cell *matHeaderCellDef mat-sort-header>Case Number</th>
    <td mat-cell *matCellDef="let project">{{project.case_number}} </td>
  </ng-container>

  <!-- Roles Column -->
  <ng-container matColumnDef="last_activity">
    <th i18n="@@lastActivityTableHead" mat-header-cell *matHeaderCellDef mat-sort-header>Last Activity</th>
    <td mat-cell *matCellDef="let project">{{project.last_activity | date: 'longDate'}}</td>
  </ng-container>

  <!-- Roles Column -->
  <ng-container matColumnDef="created_at">
    <th i18n="@@createdAtTableHead" mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
    <td mat-cell *matCellDef="let project">{{project.created_at | date: 'longDate'}}</td>
  </ng-container>

  <!-- Actions Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let project;  let i = index;">
      <div fxLayoutAlign="end">
        <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{project: project}"
                (click)="$event.stopPropagation()"
                *ngIf="(authService.can('projects-delete') || authService.can('projects-update'))">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu" xPosition="before">
          <ng-template matMenuContent let-name="name">
            <button mat-menu-item (click)="openEditProjectDialog(project)"
                    *ngIf="authService.can('projects-update')">
              <mat-icon>edit</mat-icon>
              <span i18n="@@editBtnLabel">Edit</span><span> "{{project.name}}"</span>
            </button>
            <button mat-menu-item (click)="openSaveAsTemplateDialog(project)"
                    *ngIf="authService.can('project_templates-create') && !templates">
              <mat-icon>library_add</mat-icon>
              <span i18n="@@saveAsTemplateBtnLabel">Save as Template</span>
            </button>
            <button mat-menu-item (click)="archiveProject(project)"
                    *ngIf="authService.can('projects-delete') && !project.deleted_at && !templates">
              <mat-icon>archive</mat-icon>
              <span i18n="@@archiveBtnLabel">Archive</span><span> "{{project.name}}"</span>
            </button>
            <button mat-menu-item (click)="unarchiveProject(project)"
                    *ngIf="authService.can('projects-delete') && project.deleted_at">
              <mat-icon>unarchive</mat-icon>
              <span i18n="@@unarchiveBtnLabel">Unarchive</span><span> "{{project.name}}"</span>
            </button>
            <button mat-menu-item (click)="openDeleteProjectDialog(project)"
                    *ngIf="authService.can('projects-delete') && (project.deleted_at || templates)">
              <mat-icon>delete</mat-icon>
              <span i18n="@@deleteBtnLabel2">Delete</span><span> "{{project.name}}"</span>
            </button>
          </ng-template>
        </mat-menu>

      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let project; columns: displayedColumns;"
      [routerLink]="project.is_template ? ['/templates/project', project.id, 'risk-assessments'] : ['/projects', project.id, 'risk-assessments']" [ngClass]="'clickable hover'"></tr>

</table>

<div *ngIf="isLoading" class="table_spinner">
  <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
</div>

<mat-paginator [fxHide]="isLoading" [length]="resultsLength" [pageSize]="10" color="accent"
               showFirstLastButtons></mat-paginator>
