import {Injectable} from '@angular/core';
import {User} from './user.service';
import {HttpClient} from '@angular/common/http';
import {Project, ProjectService} from './project.service';
import {Risk, RiskService} from './risk.service';
import {RiskAssessment, RiskAssessmentService} from './risk-assessment.service';
import {RiskAction, RiskActionService} from './risk-action.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {map, take} from 'rxjs/operators';
import {isDate} from 'util';
import {SuccessResponse} from '../app.component';

export interface RiskActionResult {
  id: string,
  executed_by: User | null,
  executed_at: string,
  description: string,
  new_probability: number,
  new_consequence: number,
}

@Injectable({
  providedIn: 'root'
})
export class RiskActionResultService {

  apiEndpoint = 'results';

  constructor(
    private http: HttpClient,
    private projectService: ProjectService,
    private riskService: RiskService,
    private riskAssessmentService: RiskAssessmentService,
    private riskActionService: RiskActionService,
  ) {
  }

  create(project: Project, riskAssessment: RiskAssessment, risk: Risk, riskAction: RiskAction, riskActionResult: RiskActionResult): Observable<RiskActionResult> {
    return this.http.post<RiskActionResult>(
      environment.api_url +
      this.projectService.apiEndpoint + '/' + project.id + '/' +
      this.riskAssessmentService.apiEndpoint + '/' + riskAssessment.id + '/' +
      this.riskService.apiEndpoint + '/' + risk.id + '/' +
      this.riskActionService.apiEndpoint + '/' + riskAction.id + '/' +
      this.apiEndpoint,
      riskActionResult,
      {withCredentials: true}
    ).pipe(take(1));
  }

  update(project: Project, riskAssessment: RiskAssessment, risk: Risk, riskAction: RiskAction, riskActionResult: RiskActionResult): Observable<RiskActionResult> {
    return this.http.put<RiskActionResult>(
      environment.api_url +
      this.projectService.apiEndpoint + '/' + project.id + '/' +
      this.riskAssessmentService.apiEndpoint + '/' + riskAssessment.id + '/' +
      this.riskService.apiEndpoint + '/' + risk.id + '/' +
      this.riskActionService.apiEndpoint + '/' + riskAction.id + '/' +
      this.apiEndpoint + '/' + riskActionResult.id,
      riskActionResult,
      {withCredentials: true}
    ).pipe(take(1));
  }

  delete(project: Project, riskAssessment: RiskAssessment, risk: Risk, riskAction: RiskAction, riskActionResult: RiskActionResult): Observable<boolean> {
    return this.http.delete<SuccessResponse>(
      environment.api_url +
      this.projectService.apiEndpoint + '/' + project.id + '/' +
      this.riskAssessmentService.apiEndpoint + '/' + riskAssessment.id + '/' +
      this.riskService.apiEndpoint + '/' + risk.id + '/' +
      this.riskActionService.apiEndpoint + '/' + riskAction.id + '/' +
      this.apiEndpoint + '/' + riskActionResult.id,
      {withCredentials: true}
    ).pipe(
      map((response: SuccessResponse) => {
        return response.success;
      }),
      take(1)
    );
  }

}
