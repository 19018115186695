<h1 mat-dialog-title i18n="@@newActionDialogHeader">New Action</h1>
<form [formGroup]="form" (ngSubmit)="createNewAction()">
  <div mat-dialog-content fxLayout="column" fxLayoutGap="16px">
    <span i18n="@@newActionDialogText">Create a new action below</span>
    <div class="error" *ngIf="hasError">
      <span *ngIf="this.errorMessage">{{this.errorMessage}}</span>
      <span *ngIf="!this.errorMessage" i18n="@@dialogError">There was an error, please try it again.</span>
    </div>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@descriptionInputLabel">Description</mat-label>
        <textarea formControlName="description" matInput cdkTextareaAutosize></textarea>
        <mat-hint i18n="@@actionDescriptionInputHint">Describe what action that should be taken</mat-hint>
        <mat-error i18n="@@actionDescriptionInputError">
          Please provide a description of the action
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@phaseInputLabel">Phase</mat-label>
        <mat-select formControlName="risk_action_phase_id" required>
          <mat-option *ngFor="let phase of phases" [value]="phase.id">
            {{phase.display_name}}
          </mat-option>
        </mat-select>
        <mat-hint i18n="@@phaseInputHint">Select the risk action phase</mat-hint>
        <mat-error i18n="@@phaseInputError">
          Please select a phase
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@assignToInputLabel">Assign to</mat-label>
        <input type="text"
               placeholder="Type a job title or select a user"
               aria-label="Assign to"
               matInput
               formControlName="responsible"
               [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayUser">
          <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
            {{user.name ? user.name : user.display_name}}
          </mat-option>
        </mat-autocomplete>
        <mat-hint i18n="@@assignToInputHint">Search existing users or type a job title to assign this action to a person or function</mat-hint>
        <mat-error i18n="@@assignToInputError">
          Assign this action to a job title or user
        </mat-error>
      </mat-form-field>
    </p>

  </div>
  <mat-dialog-actions fxLayoutAlign="end" fxLayoutGap="16px">
    <a mat-button mat-dialog-close [disabled]="isLoading" i18n="@@cancelBtnLabel">Cancel</a>
    <button mat-button color="primary" [loading]="isLoading" i18n="@@saveBtnLabel">Create action</button>
  </mat-dialog-actions>
</form>

