import {
  AfterContentInit,
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {Project, ProjectService} from '../services/project.service';
import {AuthService} from '../services/auth.service';
import {MatTableDataSource} from '@angular/material/table';
import {Risk, RiskScoreStep, RiskService} from '../services/risk.service';
import {map, startWith, take, takeUntil, timeout} from 'rxjs/operators';
import {RiskAssessment, RiskAssessmentService} from '../services/risk-assessment.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, of, ReplaySubject, Subject, Subscription} from 'rxjs';
import {MatSort} from '@angular/material/sort';
import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RiskCategory, RiskCategoryService} from '../services/risk-category.service';
import {RiskScope, RiskScopeService} from '../services/risk-scope.service';
import {User, UserService} from '../services/user.service';
import {MatSelect} from '@angular/material/select';
import {formatNumber} from '@angular/common';
import {RiskAction, RiskActionService} from '../services/risk-action.service';
import {NewActionDialogComponent} from './new-action-dialog/new-action-dialog.component';
import {EditActionDialogComponent} from './edit-action-dialog/edit-action-dialog.component';
import {
  ConfirmDeleteActionDialogComponent
} from './confirm-delete-action-dialog/confirm-delete-action-dialog.component';
import {RiskActionPhase} from '../services/risk-action-phase.service';
import {NewActionResultDialogComponent} from './new-action-result-dialog/new-action-result-dialog.component';
import {RiskActionResult} from '../services/risk-action-result.service';
import {EditActionResultDialogComponent} from './edit-action-result-dialog/edit-action-result-dialog.component';
import {ConfirmDeleteActionResultDialogComponent}
  from './confirm-delete-action-result-dialog/confirm-delete-action-result-dialog.component';
import {
  DownloadRiskAssessmentPDFDialogComponent
} from './download-risk-assessment-pdfdialog/download-risk-assessment-pdfdialog.component';
import {ImportRisksDialogComponent} from './import-risks-dialog/import-risks-dialog.component';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {ForbiddenErrorDialogComponent} from "../interceptors/AuthInterceptor";
import {CopyRiskToDialogComponent} from "./copy-risk-to-dialog/copy-risk-to-dialog.component";

@Component({
  selector: 'app-risks',
  templateUrl: './risks.component.html',
  styleUrls: ['./risks.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({height: '0px'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
})
export class RisksComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;

  isLoading: boolean;
  isReordering: boolean;
  isDragging: boolean;
  loadingProject: boolean;
  loadingRiskAssessment: boolean;
  risksDataSource: MatTableDataSource<Risk> = new MatTableDataSource<Risk>([]);
  displayedColumns: string[] = [
      'risk_buttons_prefix',
      'number',
      'category',
      'created_by',
      'scope',
      'description',
      'identified_at',
      'status',
      'probability_consequence',
      'risk_buttons_suffix'
  ];
  actionDisplayedColumns: string[];
  resultsDisplayedColumns: string[] = ['executed_by', 'executed_at', 'description', 'result_probability_consequence', 'result_buttons_suffix'];
  project: Project;
  riskAssessment: RiskAssessment;
  private projectId: string;
  private riskAssessmentId: string;
  private indexRisksSub: Subscription;
  someExpanded: boolean;
  scrolledFromTop: boolean;
  $scopes: Observable<Array<RiskScope>>;
  $createdBys: Observable<Array<User>>;
  $categories: Observable<Array<RiskCategory>>;
  $responsibles: Observable<Array<User | string>>;
  $phases: Observable<Array<RiskActionPhase>>;
  private filterValues = {};
  private actionFilterValues = {};
  filterActive: boolean;
  actionFilterActive: boolean;
  scopeFilterModel = [];
  assignedToFilterModel = [];
  categoryFilterModel = [];
  phaseFilterModel = [];
  createdByFilterModel = [];
  riskScoreFilterModel = [];
  statusFilterModel = [];
  actionsDataSources = [];
  actionResultsDataSources = {};
  nobodyString: any;
  private scrollToRisk: string;

  constructor(
    private projectService: ProjectService,
    private riskAssessmentService: RiskAssessmentService,
    public authService: AuthService,
    public riskService: RiskService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private riskActionService: RiskActionService,
  ) {

  }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    const pos = (document.documentElement.scrollTop || document.body.scrollTop);
    if (pos > 1 && this.scrolledFromTop !== true) {
      this.scrolledFromTop = true;
    } else if (pos <= 1) {
      this.scrolledFromTop = false;
    }
  }

  ngOnInit(): void {
    this.nobodyString = $localize`:@@nobodyLabel:Nobody`;
    this.isLoading = true;
    this.loadingProject = true;
    this.loadingRiskAssessment = true;
  }

  // Called on Risk filters change
  riskFilterChange(filter, value) {
    this.filterValues[filter] = value;
    this.risksDataSource.filter = JSON.stringify(this.filterValues)
  }

  // Called on Action filters change
  actionFilterChange(filter, value) {
    this.actionFilterValues[filter] = value;
    if (value === '' || value.length === 0) {
      delete this.actionFilterValues[filter];
    }
    this.actionFilterActive = !!Object.keys(this.actionFilterValues).length;
    const keys = Object.keys(this.actionsDataSources);
    keys.forEach(key => {
      this.actionsDataSources[key].filter = JSON.stringify(this.actionFilterValues);
    });
  }

  // Custom filter method for Risks table
  createFilter() {
    /*This method is called for each risk row, to check if it should be filtered out (hidden, found = false) or not (shown, found = true)*/
    return (risk: Risk, filterData: string): boolean => {
      const filters = JSON.parse(filterData);

      this.filterActive = false;
      const allFilters = {...filters, ...this.actionFilterValues};
      Object.keys(allFilters).forEach(
        (filter: string) => {
          const values: [any] = allFilters[filter];
          if (values.length) {
            this.filterActive = true;
          } else {
            delete filters[filter];
          }
        }
      );

      let found = false;

      if (!this.filterActive) {
        found = true;
      } else {
        // loop through all the filters and values
        found = true;
        Object.keys(filters).forEach(
          (filter: string) => {
            const values: [any] = filters[filter];
            switch (filter) {
              case 'scopes':
                // custom risk scopes filter
                if (!values.includes(risk.scope.id)) {
                  found = false;
                }
                break;
              case 'created_by':
                // custom risk created_by filter
                if (!values.includes(risk.created_by?.id)) {
                  found = false;
                }
                break;
              case 'category':
                // custom risk category filter
                if (!values.includes(risk.category.id)) {
                  found = false;
                }
                break;
              case 'risk_score_step':
                // custom risk risk_score_step filter
                const riskScore = risk.highest_probability * risk.highest_consequence * (risk.highest_consequence / 2);
                console.log(riskScore, risk.number);
                let withinRiskScore = false;
                values.forEach((riskScoreStep: RiskScoreStep) => {
                  if ((riskScoreStep.score_from === riskScoreStep.score_to && riskScore === riskScoreStep.score_to) ||
                    (riskScore > riskScoreStep.score_from && riskScore <= riskScoreStep.score_to)
                  ) {
                    withinRiskScore = true;
                  }
                });
                found = withinRiskScore;
                break;
            }
          }
        );
      }

      // when found === false,
      // the current risk does not meet the filter values,
      // so it's hidden in the table overview
      // when found === true,
      // the current risk complies to the filters,
      // so it's shown in the table overview

      return found;
    }
  }

  // Custom filter method for R=risks action tables
  createActionFilter() {
    return (action: RiskAction, filterData: string): boolean => {
      const filters = JSON.parse(filterData);

      this.filterActive = false;
      const allFilters = {...filters, ...this.filterValues};
      Object.keys(allFilters).forEach(
        (filter: string) => {
          const values: [any] = allFilters[filter];
          if (values.length) {
            this.filterActive = true;
          } else {
            delete filters[filter];
          }
        }
      );

      let found = false;

      if (!this.filterActive) {
        found = true;
      } else {
        // loop through all the filters and values
        found = true;
        Object.keys(filters).forEach(
          (filter: string) => {
            const values: [any] = filters[filter];
            switch (filter) {
              case 'assigned_to':
                if (
                  !values.includes(`${action.assigned_to?.name} (${action.assigned_to?.initials})`)
                  && !values.includes(action.responsible)
                  && !(values.includes(this.nobodyString) && (!action.assigned_to && !action.responsible))
                ) {
                  found = false;
                }
                break;
              case 'phase':
                if (!values.includes(action.phase.id)) {
                  found = false;
                }
                break;
              case 'status':
                console.log('values', values);
                if (values.toString() !== 'finished' && action.completed_at !== null) {
                  found = false;
                } else if (values.toString() !== 'unfinished' && !action.completed_at) {
                  found = false;
                }
                break;
            }
          }
        );
      }

      return found;
    }
  }

  // Reset table filters
  resetFilters() {
    this.filterValues = {};
    this.actionFilterValues = {};
    this.risksDataSource.filter = '';
    const keys = Object.keys(this.actionsDataSources);
    keys.forEach(key => {
      this.actionsDataSources[key].filter = '';
    });
    this.filterActive = false;
    this.actionFilterActive = false;
    this.scopeFilterModel = [];
    this.assignedToFilterModel = [];
    this.categoryFilterModel = [];
    this.phaseFilterModel = [];
    this.createdByFilterModel = [];
    this.riskScoreFilterModel = [];
    this.statusFilterModel = [];
  }

  ngAfterViewInit(): void {
    this.route.queryParams.pipe(take(1)).subscribe(params => {
      this.scrollToRisk = params.risk;
    })
    this.route.params.pipe(take(1)).subscribe(params => {
      this.projectId = params.projectId;
      this.riskAssessmentId = params.riskAssessmentId;
      this.projectService.get(this.projectId).subscribe((project: Project) => {
        Promise.resolve(null).then(() => {
          this.loadingProject = false;
          this.project = project;
          if (this.project.is_template) {
            this.actionDisplayedColumns = ['description', 'responsible', 'phase', 'action_probability_consequence', 'action_buttons_suffix'];
          } else {
            this.actionDisplayedColumns = ['action_buttons_prefix', 'description', 'responsible', 'phase', 'status', 'action_probability_consequence', 'action_buttons_suffix'];
          }
          this.riskAssessmentService.get(this.project, this.riskAssessmentId).subscribe(
            (riskAssessment: RiskAssessment) => {
              this.riskAssessment = riskAssessment;

              Promise.resolve(null).then(() => {
                this.loadingRiskAssessment = false;
              });

              this.indexRisksSub = this.riskService.index(this.project, this.riskAssessment).subscribe(
                (risks: Array<Risk>) => {
                  Promise.resolve(null).then(() => {
                    this.isLoading = false;
                  });
                  this.risksDataSource = new MatTableDataSource(risks);

                  if (this.scrollToRisk) {
                    setTimeout(() => {
                      const el = document.getElementById('risk-' + this.scrollToRisk);
                      el.scrollIntoView({behavior: 'smooth', block: 'end'});
                      setTimeout(() => {
                        el.style.backgroundColor = '#ffe0b2';
                      }, 0);
                      setTimeout(() => {
                        el.style.backgroundColor = '';
                      }, 500);
                      setTimeout(() => {
                        el.style.backgroundColor = '#ffe0b2';
                      }, 1000);
                      setTimeout(() => {
                        el.style.backgroundColor = '';
                      }, 1500);
                      setTimeout(() => {
                        el.style.backgroundColor = '#ffe0b2';
                      }, 2000);
                      setTimeout(() => {
                        el.style.backgroundColor = '';
                      }, 2500);
                      this.scrollToRisk = null;
                    });
                  }

                  this.updateFilterOptions();

                  this.risksDataSource.filterPredicate = this.createFilter();

                  risks.forEach((risk: Risk) => {
                    this.actionsDataSources[risk.id] = new MatTableDataSource(risk.actions);
                    this.actionsDataSources[risk.id].filterPredicate = this.createActionFilter();
                    risk.actions.forEach((action: RiskAction) => {
                      this.actionResultsDataSources[action.id] = new MatTableDataSource(action.results);
                    });
                  });

                  // custom sorting data accessor (when using child property's of objects)
                  this.risksDataSource.sortingDataAccessor = (risk: Risk, property: string) => {
                    switch (property) {
                      case 'category':
                        return risk.category.display_name.toLowerCase();
                      case 'scope':
                        return risk.scope.display_name.toLowerCase();
                      case 'created_by':
                        return risk.created_by?.name.toLowerCase();
                      case 'probability_consequence':
                        return risk.highest_probability * risk.highest_consequence * (risk.highest_consequence / 2);
                      default:
                        return risk[property];
                    }
                  };

                  this.risksDataSource.sort = this.sort;
                },
                () => {
                  this.isLoading = false;
                  this.loadingRiskAssessment = false;
                  this.loadingProject = false;
                }
              );
            },
            () => {
              this.isLoading = false;
              this.loadingRiskAssessment = false;
              this.loadingProject = false;
            }
          );
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.indexRisksSub.unsubscribe();
  }

  toggleExpandAllRisks() {
    // check if needs to expand or collapse all
    if (this.someExpanded) {
      // collapse all risks because some where already expanded
      this.collapseAllRisks();
    } else {
      // expand all risks because none where expanded
      this.risksDataSource.data.forEach((risk: Risk) => risk.expanded = true);
      this.someExpanded = true;
    }
  }

  collapseAllRisks() {
    this.risksDataSource.data.forEach((risk: Risk) => {
      risk.expanded = false;
      // collapse all risk actions
      risk.actions.forEach((action: RiskAction) => action.expanded = false);
    });
    this.someExpanded = false;
  }

  toggleExpandRisk(risk: any) {
    if (!risk.expanded) {
      // if risk is not already expanded, its gonna expand, so set someExpanded to true
      this.someExpanded = true;
    } else {
      // Risk is gonna collapse, so collapse all the underlying actions as well
      risk.actions.forEach((action: RiskAction) => action.expanded = false);
    }
    // toggle expanded boolean on risk
    risk.expanded = !risk.expanded;
    // if risk is not expanded now, it was expanded before,
    // so check if there is still an expanded risk left, if not toggle this.someExpanded
    if (!risk.expanded) {
      this.someExpanded = (this.risksDataSource.data.filter((r: Risk) => r.expanded === true).length > 0);
    }
  }

  openNewRiskDialog(belowRisk: Risk = null, above: boolean = false) {
    const dialogRef = this.dialog.open(NewRiskDialogComponent, {
      disableClose: true,
      data: {project: this.project, riskAssessment: this.riskAssessment}
    });
    dialogRef.afterClosed().subscribe((risk: Risk) => {
      if (risk) {
        const data = this.risksDataSource.data;
        data.push(risk);
        this.risksDataSource.data = data;
        if (!this.actionsDataSources[risk.id]) {
          this.actionsDataSources[risk.id] = new MatTableDataSource(risk.actions);
          this.actionsDataSources[risk.id].filterPredicate = this.createActionFilter();
        }
        this.updateFilterOptions();
        this.reCalculateRiskScore(risk);

        if (belowRisk) {
          this.moveRisk(this.risksDataSource.data.indexOf(risk), this.risksDataSource.data.indexOf(belowRisk) + (above ? 0 : 1));
        } else {
          // scroll to the newly created risk (ignoring when it's inserted between other risks)
          setTimeout(() => {
            const el = document.getElementById('risk-' + risk.id);
            el.scrollIntoView({behavior: 'smooth'});
          });
        }
      }
    });
  }

  openEditRiskDialog(_risk: RiskAssessment) {
    const dialogRef = this.dialog.open(EditRiskDialogComponent, {
      data: {
        risk: _risk,
        riskAssessment: this.riskAssessment,
        project: this.project
      }, disableClose: true
    });
    dialogRef.afterClosed().subscribe((risk: Risk) => {
      if (risk) {
        const data = this.risksDataSource.data;
        const index = data.findIndex((_obj => _obj.id === risk.id));
        risk.expanded = _risk.expanded;
        data[index] = risk;
        this.risksDataSource.data = data;
        this.updateFilterOptions();
        this.reCalculateRiskScore(risk);
      }
    });
  }

  scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  scrollToBottom(): void {
    window.scrollTo(0, document.body.scrollHeight);
  }

  openDeleteRiskDialog(_risk: Risk) {
    const dialogRef = this.dialog.open(ConfirmDeleteRiskDialogComponent, {
      data: {
        risk: _risk,
        riskAssessment: this.riskAssessment,
        project: this.project
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // remove the project from the table
        let data = this.risksDataSource.data;
        data = data.filter(obj => obj !== _risk);
        this.risksDataSource.data = data;
        this.updateFilterOptions()
      }
    });
  }

  private updateFilterOptions() {
    const risks = this.risksDataSource.data;
    // update scope filter options
    // tslint:disable-next-line:max-line-length
    const scopesDistinct = risks.map((risk: Risk) => risk.scope).filter((scope, i, arr) => arr.findIndex(t => t.id === scope.id) === i);
    this.$scopes = of(scopesDistinct);

    // update category filter options
    // tslint:disable-next-line:max-line-length
    const categoriesDistinct = risks.map((risk: Risk) => risk.category).filter((category, i, arr) => arr.findIndex(t => t.id === category.id) === i);
    this.$categories = of(categoriesDistinct);

    // update create by user filter options
    // tslint:disable-next-line:max-line-length
    const createdBysDistinct = risks.map((risk: Risk) => risk.created_by).filter((user, i, arr) => arr.findIndex(t => t?.id === user?.id) === i);
    this.$createdBys = of(createdBysDistinct);

    // update create by user filter options
    // tslint:disable-next-line:max-line-length
    let allActions = [];
    risks.forEach((_risk: Risk) => allActions = allActions.concat(_risk.actions));

    const responsiblesDistinct = allActions
      .map((action: RiskAction) => action.responsible ? action.responsible : (action.assigned_to ? `${action.assigned_to.name} (${action.assigned_to?.initials})` : this.nobodyString))
      .filter((name, i, arr) => arr.findIndex(t => t === name) === i
      );
    this.$responsibles = of(responsiblesDistinct);

    const phasesDistinct = allActions
      .map((action: RiskAction) => action.phase)
      .filter((phase, i, arr) => arr.findIndex(t => t.id === phase.id) === i
      );
    this.$phases = of(phasesDistinct);
  }

  openNewActionDialog(risk: Risk) {
    const dialogRef = this.dialog.open(NewActionDialogComponent, {
      disableClose: true,
      data: {project: this.project, riskAssessment: this.riskAssessment, risk}
    });
    dialogRef.afterClosed().subscribe((action: RiskAction) => {
      if (action) {
        // create actionsDataSources for risk if not already existed
        if (!this.actionsDataSources[risk.id]) {
          this.actionsDataSources[risk.id] = new MatTableDataSource(risk.actions);
          this.actionsDataSources[risk.id].filterPredicate = this.createActionFilter();
        }

        // add newly created action to nested action table data source
        const actionData = this.actionsDataSources[risk.id].data;
        if (actionData) {
          actionData.push(action);
        }
        this.actionsDataSources[risk.id].data = actionData;

        // add newly created action to risk table data source
        const riskIndex = this.risksDataSource.data.indexOf(risk);
        const riskData = this.risksDataSource.data;
        riskData[riskIndex].actions = actionData;
        this.risksDataSource.data = riskData;

        this.reCalculateRiskScore(risk);
        this.reCalculateRiskStatus(risk);
        this.updateFilterOptions();
      }
    });
  }

  openEditActionDialog(risk: Risk, _action: RiskAction) {
    const dialogRef = this.dialog.open(EditActionDialogComponent, {
      data: {
        project: this.project,
        riskAssessment: this.riskAssessment,
        risk,
        action: _action,
      }, disableClose: true
    });
    dialogRef.afterClosed().subscribe((action: RiskAction) => {
      if (action) {
        // update action in nested actions table data source
        const actionData = this.actionsDataSources[risk.id].data;
        const actionIndex = actionData.indexOf(_action);
        if (actionData) {
          action.expanded = _action.expanded;
          actionData[actionIndex] = action;
        }
        this.actionsDataSources[risk.id].data = actionData;

        // add newly created action to risk table data source
        const riskIndex = this.risksDataSource.data.indexOf(risk);
        const riskData = this.risksDataSource.data;
        riskData[riskIndex].actions = actionData;
        this.risksDataSource.data = riskData;

        this.reCalculateRiskScore(risk);
        this.updateFilterOptions();
      }
    });
  }

  openDeleteActionDialog(risk: Risk, action: RiskAction) {
    const dialogRef = this.dialog.open(ConfirmDeleteActionDialogComponent, {
      data: {
        project: this.project,
        riskAssessment: this.riskAssessment,
        risk,
        action,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // remove the action from the risk action data source
        let actionsData = this.actionsDataSources[risk.id].data;
        actionsData = actionsData.filter(obj => obj.id !== action.id);
        this.actionsDataSources[risk.id].data = actionsData;

        // remove the action from the risk table data source
        const risksData = this.risksDataSource.data;
        const riskIndex = this.risksDataSource.data.indexOf(risk);
        risksData[riskIndex].actions = risksData[riskIndex].actions.filter(obj => obj.id !== action.id);
        this.risksDataSource.data = risksData;

        this.reCalculateRiskScore(risk);
        this.updateFilterOptions()
      }
    });
  }

  toggleExpandAllActions(risk: Risk) {
    // check if needs to expand or collapse all actions of this risk
    const riskIndex = this.risksDataSource.data.indexOf(risk);
    // collapse all actions of specific risk because some where already expanded
    if (this.someActionsExpanded(risk)) {
      this.risksDataSource.data[riskIndex].actions.forEach((action: RiskAction) => action.expanded = false);
    } else {
      // expand all actions of specific risk because none where expanded
      this.risksDataSource.data[riskIndex].actions.forEach((action: RiskAction) => action.expanded = true);
    }
  }

  someActionsExpanded(risk: Risk, setValue?): boolean {
    const riskIndex = this.risksDataSource.data.indexOf(risk);
    return this.risksDataSource.data[riskIndex].actions.filter((action: RiskAction) => action.expanded).length > 0;
  }

  toggleExpandAction(risk: Risk, action: RiskAction) {
    if (!action.expanded) {
      // if action is not already expanded, its gonna expand, so set someExpanded to true
      this.someActionsExpanded(risk, true);
    }
    // toggle expanded boolean on risk
    action.expanded = !action.expanded;
    // if action is not expanded now, it was expanded before,
    // so check if there is still an expanded action left, if not toggle this.someExpanded
    if (!action.expanded) {
      const riskIndex = this.risksDataSource.data.indexOf(risk);
      this.someActionsExpanded(
        risk,
        this.risksDataSource.data[riskIndex].actions.filter((a: RiskAction) => a.expanded === true).length > 0);
    }
  }

  openNewResultDialog(risk: Risk, action: RiskAction) {
    const dialogRef = this.dialog.open(NewActionResultDialogComponent, {
      disableClose: true,
      data: {project: this.project, riskAssessment: this.riskAssessment, risk, action}
    });
    dialogRef.afterClosed().subscribe((result: RiskActionResult) => {
      if (result) {
        // create actionResultsDataSources for results table if not already existed
        if (!this.actionResultsDataSources[action.id]) {
          this.actionResultsDataSources[action.id] = new MatTableDataSource(action.results);
        }

        // add newly created result to results table data source
        const resultsData = this.actionResultsDataSources[action.id].data;
        if (resultsData) {
          resultsData.push(result);
          // sort results by executed_at
          resultsData.sort((a, b) => {
            const c = new Date(a.executed_at);
            const d = new Date(b.executed_at);
            // @ts-ignore
            return c - d;
          });
        }
        this.actionResultsDataSources[action.id].data = resultsData;

        // add newly created result to action table data source
        const actionIndex = this.actionsDataSources[risk.id].data.indexOf(action);
        const actionData = this.actionsDataSources[risk.id].data;
        actionData[actionIndex].results = resultsData;
        this.actionsDataSources[risk.id].data = actionData;

        this.reCalculateActionScore(risk, action);
        this.reCalculateRiskScore(risk);
      }
    });
  }

  openEditResultDialog(risk: Risk, action: RiskAction, result: RiskActionResult) {
    const dialogRef = this.dialog.open(EditActionResultDialogComponent, {
      data: {
        project: this.project,
        riskAssessment: this.riskAssessment,
        risk,
        action,
        result,
      }, disableClose: true
    });
    dialogRef.afterClosed().subscribe((_result: RiskActionResult) => {
      if (_result) {

        // update result in nested risks table data source
        const resultsData = this.actionResultsDataSources[action.id].data;
        const resultIndex = resultsData.indexOf(result);
        if (resultsData) {
          resultsData[resultIndex] = _result;
          // sort results by executed_at
          resultsData.sort((a, b) => {
            const c = new Date(a.executed_at);
            const d = new Date(b.executed_at);
            // @ts-ignore
            return c - d;
          });
        }
        this.actionResultsDataSources[action.id].data = resultsData;

        // add newly created result to action table data source
        const actionIndex = this.actionsDataSources[risk.id].data.indexOf(action);
        const actionData = this.actionsDataSources[risk.id].data;
        actionData[actionIndex].results = resultsData;
        this.actionsDataSources[risk.id].data = actionData;

        this.reCalculateActionScore(risk, action);
        this.reCalculateRiskScore(risk);
      }
    });
  }

  openDeleteResultDialog(risk: Risk, action: RiskAction, result: RiskActionResult) {
    const dialogRef = this.dialog.open(ConfirmDeleteActionResultDialogComponent, {
      data: {
        project: this.project,
        riskAssessment: this.riskAssessment,
        risk,
        action,
        result,
      },
    });
    dialogRef.afterClosed().subscribe(_result => {
      if (_result) {
        // remove the action from the risk action data source
        let resultsData = this.actionResultsDataSources[action.id].data;
        resultsData = resultsData.filter(obj => obj.id !== result.id);
        this.actionResultsDataSources[action.id].data = resultsData;

        // remove the result from the action table data source
        const actionIndex = this.actionsDataSources[risk.id].data.indexOf(action);
        const actionData = this.actionsDataSources[risk.id].data;
        actionData[actionIndex].results = actionData[actionIndex].results.filter(obj => obj.id !== result.id);
        this.actionsDataSources[risk.id].data = actionData;

        this.reCalculateActionScore(risk, action);
        this.reCalculateRiskScore(risk);
      }
    });
  }

  private reCalculateRiskScore(risk: Risk) {
    const riskIndex = this.risksDataSource.data.indexOf(risk);
    let highestProb = risk.initial_probability;
    let highestCon = risk.initial_consequence;
    let highestRiskScore = 0;
    let hasActionsWithoutResults = false;
    const actionData = this.actionsDataSources[risk.id].data;
    actionData.forEach((action: RiskAction) => {
      if (!action.results.length) {
        hasActionsWithoutResults = true;
      }
    });
    if (this.risksDataSource.data[riskIndex].actions.length) {
      // if risk has actions left, iterate actions and check for action with the highest riskscore
      this.risksDataSource.data[riskIndex].actions.forEach((action: RiskAction) => {
        const riskScore = action.highest_probability * action.highest_consequence * (action.highest_consequence / 2);
        if (riskScore > highestRiskScore) {
          highestRiskScore = riskScore;
          highestCon = action.highest_consequence;
          highestProb = action.highest_probability;
        }
      });
      if (hasActionsWithoutResults) {
        const initialRiskScore = risk.initial_probability * risk.initial_consequence * (risk.initial_consequence / 2);
        if (highestRiskScore > initialRiskScore) {
          risk.highest_probability = highestProb;
          risk.highest_consequence = highestCon;
        } else {
          risk.highest_probability = risk.initial_probability;
          risk.highest_consequence = risk.initial_consequence;
        }
      } else {
        risk.highest_probability = highestProb;
        risk.highest_consequence = highestCon;
      }
    } else {
      // if risk has no actions left, set to initial cons and prob score
      risk.highest_probability = risk.initial_probability;
      risk.highest_consequence = risk.initial_consequence;
    }
  }

  private reCalculateActionScore(risk: Risk, action: RiskAction) {
    const actionIndex = this.actionsDataSources[risk.id].data.indexOf(action);
    let highestProb = null;
    let highestCon = null;
    let latestDate = null;
    this.actionsDataSources[risk.id].data[actionIndex].results.forEach((result: RiskActionResult) => {
      if (latestDate === null || result.executed_at > latestDate) {
        latestDate = result.executed_at;
        highestCon = result.new_consequence;
        highestProb = result.new_probability;
      }
    });
    action.highest_probability = highestProb;
    action.highest_consequence = highestCon;
  }

  openDownloadRiskAssessmentPDFDialog() {
    const dialogRef = this.dialog.open(DownloadRiskAssessmentPDFDialogComponent);
    dialogRef.afterClosed().subscribe((result: { format: string, language: string }) => {
        if (result) {
          const filteredActionData = [];
          if (result.format === 'full' || result.format === 'actions') {
            const keys = Object.keys(this.actionsDataSources);
            keys.forEach(key => {
              this.actionsDataSources[key].filteredData.forEach(
                (riskAction: RiskAction) => {
                  filteredActionData.push(riskAction.id)
                }
              );
            });
          }
          this.riskAssessmentService.export(
            this.project,
            this.riskAssessment,
            result.format,
            this.risksDataSource.sort.active,
            this.risksDataSource.sort.direction,
            this.risksDataSource.filteredData.filter((risk: Risk) => !(this.actionFilterActive && this.actionsDataSources[risk.id].filteredData.length === 0)).map((risk: Risk) => risk.id),
            /*this.filterActive ? this.risksDataSource.filteredData.map((risk: Risk) => risk.id).filter((riskId: string) => this.actionsDataSources[riskId] ? this.actionsDataSources[riskId].filteredData.length !== 0 : true) : this.risksDataSource.data.map((risk: Risk) => risk.id),*/
            filteredActionData,
            result.language
          ).subscribe((data: BlobPart) => {
            const blob = new Blob([data], {type: 'application/pdf'});
            const downloadURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = this.project.case_number + '.' + this.riskAssessment.number + '-' + this.riskAssessment.name + '-export.pdf';
            link.click();
          });
        }
      }
    );
  }

  openImportRiskDialog() {
    const dialogRef = this.dialog.open(ImportRisksDialogComponent, {
      disableClose: true,
      data: {project: this.project, riskAssessment: this.riskAssessment}
    });
    dialogRef.afterClosed().subscribe((risks: Risk[]) => {
      if (risks) {
        const data = this.risksDataSource.data;
        risks.forEach((risk: Risk) => {
          data.push(risk);
          if (!this.actionsDataSources[risk.id]) {
            this.actionsDataSources[risk.id] = new MatTableDataSource(risk.actions);
            this.actionsDataSources[risk.id].filterPredicate = this.createActionFilter();
          }
        });
        this.risksDataSource.data = data;

        risks.forEach((risk: Risk) => {
          risk.actions.forEach((action: RiskAction) => {

            // create actionsDataSources for risk if not already existed
            if (!this.actionsDataSources[risk.id]) {
              this.actionsDataSources[risk.id] = new MatTableDataSource(risk.actions);
              this.actionsDataSources[risk.id].filterPredicate = this.createActionFilter();
            }

            /*// add newly created action to nested action table data source
            const actionData = this.actionsDataSources[risk.id].data;
            if (actionData) {
              actionData.push(action);
            }
            this.actionsDataSources[risk.id].data = actionData;

            // add newly created action to risk table data source
            const riskIndex = this.risksDataSource.data.indexOf(risk);
            const riskData = this.risksDataSource.data;
            riskData[riskIndex].actions = actionData;
            this.risksDataSource.data = riskData;*/

          });
          this.reCalculateRiskScore(risk);
        });
        this.updateFilterOptions();
      }
    });
  }

  initReorderList() {
    this.resetFilters();
    this.collapseAllRisks();
    this.sort.direction = 'desc';
    this.sort.sort({id: 'number', start: 'asc', disableClear: true});
    this.isReordering = true;
  }

  stopReorderList() {
    this.risksDataSource.data.forEach((risk: Risk) => risk.selected = false);
    this.sort.disableClear = false;
    this.isReordering = false;
  }

  toggleRiskSelect(risk: Risk) {
    risk.selected = !risk.selected;
  }

  someRiskSelected(): boolean {
    return this.risksDataSource.data.filter((risk: Risk) => risk.selected).length > 0
  }

  getSelectedRisks(): Risk[] {
    return this.risksDataSource.data.filter((risk: Risk) => risk.selected);
  }

  onReorderTableRowDrop($event: CdkDragDrop<string[]>) {
    if ($event.previousIndex !== $event.currentIndex) {
      this.moveRisk($event.previousIndex, $event.currentIndex);
    }
    // disable dragging
    this.isDragging = false;
  }

  onDragStarted($event: any) {
    this.isDragging = true; // hide selected risks when dragging starts
  }

  private moveRisk(fromIndex: number, toIndex: number) {

    // get the dragged risk
    const movedRisk = this.risksDataSource.data[fromIndex];

    // get the other selected risks
    const selectedRisks = this.risksDataSource.data.filter((risk: Risk) => risk.selected && risk.id !== movedRisk.id);

    // get risk array
    const risksData = this.risksDataSource.data;

    // move the dragged risk to the new index
    risksData.splice(fromIndex, 1);
    risksData.splice(toIndex, 0, movedRisk);

    let newIndex = toIndex + 1;

    // move the other selected risks underneath the dragged risk
    selectedRisks.forEach((risk: Risk) => {
      const index = risksData.indexOf(risk);
      risksData.splice(index, 1);
      risksData.splice(newIndex, 0, risk);
      ++newIndex;
    });

    // recalculate risk numbers for all risks using array index + 1
    // and set selected to false
    for (let i = 0; i < risksData.length; i++) {
      risksData[i].number = i + 1;
      risksData[i].selected = false;
    }

    this.riskService.updateRiskNumbers(this.project, this.riskAssessment, risksData).subscribe((success: boolean) => {
      if (success) {
        // set the new risk data array to the table data source on API call success
        this.risksDataSource.data = risksData;
        this.snackBar.open('Risks reordered successfully');
      } else {
        this.dialog.open(ForbiddenErrorDialogComponent, {data: 'Could not sort the risk assessment due to an error'});
      }
    }, () => {
      this.dialog.open(ForbiddenErrorDialogComponent, {data: 'Could not sort the risk assessment due to an error'});
    });
  }

  openCopySelectedRiskToDialog() {
    const dialogRef = this.dialog.open(CopyRiskToDialogComponent, {
      disableClose: true,
      data: this.getSelectedRisks()
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response.project && response.risk_assessment) {
        this.snackBar.open('Risks copied successfully');
        this.router.navigate(['/projects', response.project.id, 'risk-assessments', response.risk_assessment.id],
          {
            queryParams: {
              risk: response.risk.id
            }
          }).then(() => {
          location.reload();
        });
      }
    });
  }

  setActionComplete(project: Project, riskAssessment: RiskAssessment, risk: Risk, action: RiskAction) {
    action.completed_at = new Date().toISOString();
    this.riskActionService.update(project, riskAssessment, risk, action).subscribe();
    this.reCalculateRiskStatus(risk);
    this.actionFilterChange('status', this.statusFilterModel);
  }

  setActionNotComplete(project: Project, riskAssessment: RiskAssessment, risk: Risk, action: RiskAction) {
    action.completed_at = null;
    this.riskActionService.update(project, riskAssessment, risk, action).subscribe();
    this.reCalculateRiskStatus(risk);
    this.actionFilterChange('status', this.statusFilterModel);
  }

  copyRisk(risk) {
    risk.selected = true;
    this.openCopySelectedRiskToDialog();
  }

  reCalculateRiskStatus(risk) {
    const riskIndex = this.risksDataSource.data.indexOf(risk);
    const filteredActions = this.risksDataSource.data[riskIndex].actions.filter((_action: RiskAction) => _action.completed_at === null);
    this.risksDataSource.data[riskIndex].completed = filteredActions.length === 0;
  }
}

@Component({
  selector: 'app-confirm-delete-risk-dialog-component',
  templateUrl: 'confirm-delete-risk-dialog.html',
  styles: ['p:first-child { margin-top: 0; }', 'p:last-child { margin-bottom: 0; }']
})
export class ConfirmDeleteRiskDialogComponent {
  isLoading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { risk: Risk, riskAssessment: RiskAssessment, project?: Project },
    private riskService: RiskService,
    private dialogRef: MatDialogRef<ConfirmDeleteRiskDialogComponent>,
    private snackBar: MatSnackBar,
  ) {
  }

  deleteRiskAssessment() {
    this.isLoading = true;
    this.riskService.deleteTemplate(this.data.riskAssessment, this.data.risk).subscribe((success: boolean) => {
      if (success) {
        this.dialogRef.close(true);
        this.snackBar.open('Risk \"R' + formatNumber(this.data.risk.number, 'en-US', '3.0') + '\" is deleted');
      }
    }, () => {
      this.isLoading = false;
    });
  }

}

@Component({
  selector: 'app-edit-risk-dialog',
  templateUrl: 'edit-risk-dialog.html',
  styles: ['mat-form-field { width: 100%; min-width: 350px; }']
})
export class EditRiskDialogComponent implements OnInit, AfterContentInit, OnDestroy {
  form: FormGroup;
  isLoading: boolean;
  hasError: boolean;
  categories: Array<RiskCategory>;
  scopes: Array<RiskScope>;
  filteredScopes: Observable<RiskScope[]>;
  users: Array<User>;
  userFilterCtrl: FormControl = new FormControl();
  filteredUsers: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  @ViewChild('createdBySelect', {static: true}) singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  errorMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { risk: Risk | any, riskAssessment: RiskAssessment, project?: Project },
    private fb: FormBuilder,
    private riskAssessmentService: RiskAssessmentService,
    private dialogRef: MatDialogRef<EditRiskDialogComponent>,
    public authService: AuthService,
    private snackBar: MatSnackBar,
    private riskService: RiskService,
    private riskCategoryService: RiskCategoryService,
    private riskScopeService: RiskScopeService,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      category: ['', Validators.required],
      scope: ['', Validators.required],
      created_by: ['', Validators.required],
      description: ['', Validators.required],
      identified_at: ['', Validators.required],
      initial_probability: ['', Validators.required],
      initial_consequence: ['', Validators.required],
    });
    this.form.patchValue(this.data.risk);
  }

  ngAfterContentInit() {
    this.riskCategoryService.index().pipe(take(1)).subscribe((categories: Array<RiskCategory>) => {
      this.categories = categories;
      this.form.controls.category.setValue(this.categories.filter((cat: RiskCategory) => cat.id === this.data.risk.category.id).pop().id)
    });
    this.riskScopeService.index().pipe(take(1)).subscribe((scopes: Array<RiskScope>) => {
      this.scopes = scopes;
      this.filteredScopes = this.form.controls.scope.valueChanges
        .pipe(
          startWith(''),
          map(value => this.filterScopes(value))
        );
    });
    this.userService.index().pipe(take(1)).subscribe((users: Array<User>) => {
      this.users = users;
      // set initial created by to be current user

      this.setInitialUserSearchValue();
      this.filteredUsers.next(this.users.slice());
    });

    this.userFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterUsers();
      });
  }

  updateRisk() {
    if (this.form.valid) {
      this.form.disable();
      this.isLoading = true;
      this.form.controls.created_by.setValue(this.form.controls.created_by.value.id);
      this.data.risk = {
        ...this.data.risk,
        ...this.form.value,
        category_id: this.form.controls.category.value,
      };
      if (this.form.controls.scope.value.id) {
        this.data.risk = {...this.data.risk, scope_id: this.form.controls.scope.value.id};
        delete this.data.risk.scope;
      }
      this.riskService.updateTemplate(this.data.riskAssessment, this.data.risk).subscribe((risk: Risk) => {
        this.snackBar.open('Risk \"R' + formatNumber(risk.number, 'en-US', '3.0') + '\" was updated successfully');
        this.isLoading = false;
        this.dialogRef.close(risk);
      }, (e) => {
        if (e.error?.errors) {
          this.errorMessage = Object.values(e.error.errors).join(', ');
        }
        this.hasError = true;
        this.isLoading = false;
      })
    }
  }

  private filterScopes(value: string): RiskScope[] {
    const filterValue = value.toString().toLowerCase();
    return this.scopes.filter(scope => scope.display_name.toLowerCase().includes(filterValue));
  }

  displayScope(scope: RiskScope) {
    return scope ? scope.display_name : undefined;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialUserSearchValue() {
    this.filteredUsers
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: User, b: User) => a && b && a.id === b.id;
      });
  }

  protected filterUsers() {
    if (!this.users) {
      return;
    }
    // get the search keyword
    let search = this.userFilterCtrl.value;
    if (!search) {
      this.filteredUsers.next(this.users.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredUsers.next(
      this.users.filter(user => user.name.toLowerCase().indexOf(search) > -1)
    );
  }
}

@Component({
  selector: 'app-new-risk-dialog',
  templateUrl: 'new-risk-dialog.html',
  styles: ['mat-form-field { width: 100%; min-width: 450px; }']
})
export class NewRiskDialogComponent
  implements OnInit, AfterContentInit, OnDestroy {
  form: FormGroup;
  isLoading: boolean;
  hasError: boolean;
  categories: Array<RiskCategory>;
  scopes: Array<RiskScope>;
  filteredScopes: Observable<RiskScope[]>;
  users: Array<User>;
  userFilterCtrl: FormControl = new FormControl();
  filteredUsers: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  @ViewChild('createdBySelect', {static: true}) singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  errorMessage: string;
  private scopeSub: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { project?: Project, riskAssessment: RiskAssessment },
    private fb: FormBuilder,
    private riskAssessmentService: RiskAssessmentService,
    private dialogRef: MatDialogRef<NewRiskDialogComponent>,
    public authService: AuthService,
    private snackBar: MatSnackBar,
    private riskService: RiskService,
    private riskCategoryService: RiskCategoryService,
    private riskScopeService: RiskScopeService,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      category: ['', Validators.required],
      scope: ['', Validators.required],
      created_by: ['', Validators.required],
      description: ['', Validators.required],
      identified_at: ['', Validators.required],
      initial_probability: ['', Validators.required],
      initial_consequence: ['', Validators.required],
    });
    this.form.controls.identified_at.setValue(new Date());
  }

  ngAfterContentInit() {
    this.riskCategoryService.index().pipe(take(1)).subscribe((categories: Array<RiskCategory>) => {
      this.categories = categories;
    });
    if (this.data.project) {
      this.scopeSub = this.riskScopeService.projectRiskScopesIndex(this.data.project).pipe().subscribe((scopes: Array<RiskScope>) => {
        this.scopes = scopes;
        this.filteredScopes = this.form.controls.scope.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterScopes(value))
          );
      });
    } else {
      this.scopeSub = this.riskScopeService.riskAssessmentRiskScopesIndex(this.data.riskAssessment).pipe().subscribe((scopes: Array<RiskScope>) => {
        this.scopes = scopes;
        this.filteredScopes = this.form.controls.scope.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterScopes(value))
          );
      });
    }
    this.userService.index().pipe(take(1)).subscribe((users: Array<User>) => {
      this.users = users;
      // set initial created by to be current user
      this.authService.getUser().pipe(take(1)).subscribe((user: User) => {
        this.form.controls.created_by.setValue(this.users.filter((obj: User) => obj.id === user.id).pop());
        this.setInitialUserSearchValue();
      });
      this.filteredUsers.next(this.users.slice());
    });

    this.userFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterUsers();
      });
  }

  createNewRisk() {
    if (this.form.valid) {
      this.form.disable();
      this.isLoading = true;
      this.form.controls.created_by.setValue(this.form.controls.created_by.value.id);
      const _risk = {
        ...this.form.value,
        category_id: this.form.controls.category.value,
        scope_id: this.form.controls.scope.value.id
      };
      if (_risk.scope_id) {
        // remove the scope property if scope_id was set,
        // if scope_id is not set the scope will be a name (string) for creating a new scope
        delete _risk.scope;
      }
      if (this.data.project) {
        this.riskService.create(
          this.data.project,
          this.data.riskAssessment,
          _risk
        ).subscribe((risk: Risk) => {
          this.snackBar.open('Risk \"R' + formatNumber(risk.number, 'en-US', '3.0') + '\" is created successfully');
          this.dialogRef.close(risk);
        }, (e) => {
          if (e.error?.errors) {
            this.errorMessage = Object.values(e.error.errors).join(', ');
          }
          this.hasError = true;
          this.isLoading = false;
          this.form.enable();
        })
      } else {
        this.riskService.createTemplate(this.data.riskAssessment,
          _risk
        ).subscribe((risk: Risk) => {
          this.snackBar.open('Risk \"R' + formatNumber(risk.number, 'en-US', '3.0') + '\" is created successfully');
          this.dialogRef.close(risk);
        }, (e) => {
          if (e.error?.errors) {
            this.errorMessage = Object.values(e.error.errors).join(', ');
          }
          this.hasError = true;
          this.isLoading = false;
          this.form.enable();
        })
      }
    }
  }

  private filterScopes(value: string): RiskScope[] {
    const filterValue = value.toString().toLowerCase();
    return this.scopes.filter(scope => scope.display_name.toLowerCase().includes(filterValue));
  }

  displayScope(scope: RiskScope) {
    return scope ? scope.display_name : undefined;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.scopeSub.unsubscribe();
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialUserSearchValue() {
    this.filteredUsers
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: User, b: User) => a && b && a.id === b.id;
      });
  }

  protected filterUsers() {
    if (!this.users) {
      return;
    }
    // get the search keyword
    let search = this.userFilterCtrl.value;
    if (!search) {
      this.filteredUsers.next(this.users.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredUsers.next(
      this.users.filter(user => user.name.toLowerCase().indexOf(search) > -1)
    );
  }
}
