<section class="container" fxLayout="column">

  <mat-toolbar color="primary" class="dark-theme risk-toolbar" fxLayoutGap="16px"
               [class.mat-elevation-z4]="scrolledFromTop">

    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="dark-theme">

      <button mat-button [loading]="loadingProject" color="accent" *ngIf="loadingProject"></button>
      <button mat-button routerLink="/projects" *ngIf="!loadingProject && !project.is_template">
        <mat-icon>folder</mat-icon>
        <span i18n="@@projectsBreadcrumbLabel">Projects</span>
      </button>
      <button mat-button routerLink="/templates" *ngIf="!loadingProject && project.is_template">
        <mat-icon>library_books</mat-icon>
        <span i18n="@@templatesBreadcrumbLabel">Templates</span>
      </button>
      <mat-icon>arrow_right</mat-icon>

      <button mat-button [loading]="loadingProject" color="accent" *ngIf="loadingProject"></button>


      <button mat-button
              [routerLink]="project.is_template ? ['/templates/project', project.id, 'risk-assessments'] : ['/projects', project.id, 'risk-assessments']"
              *ngIf="!loadingProject">
        <span *ngIf="!loadingProject"><span *ngIf="!project.is_template">{{project.case_number}}
          - </span>{{project.name}}</span>
      </button>
      <mat-icon>arrow_right</mat-icon>
      <button mat-button [disableRipple]="true" [loading]="loadingRiskAssessment"
              [color]="loadingRiskAssessment ? 'accent' : null">
      <span *ngIf="!loadingRiskAssessment"><span *ngIf="!project.is_template">{{riskAssessment.number}}
        - </span>{{riskAssessment.name}}</span>
      </button>

      <div class="row-spacer"></div>

      <button mat-flat-button
              *ngIf="isReordering"
              color="accent"
              [disabled]="!someRiskSelected()"
              (click)="openCopySelectedRiskToDialog()">
        <mat-icon aria-label="Copy risk to another assessment">content_copy</mat-icon>
        <span i18n="@@copyRiskBtnLabel">Copy to</span>
      </button>

      <div *ngIf="!loadingRiskAssessment && !isReordering">
        <button
          [loading]="loadingRiskAssessment"
          mat-stroked-button
          color="primary"
          (click)="initReorderList()">
          <mat-icon aria-label="Reorder list">playlist_add_check</mat-icon>
          <span i18n="@@reorderBtnLabel">Select</span>
        </button>
      </div>

      <div *ngIf="!loadingRiskAssessment && isReordering">
        <button
          [loading]="loadingRiskAssessment"
          mat-flat-button
          color="accent"
          (click)="stopReorderList()">
          <mat-icon aria-label="Reorder list">playlist_remove</mat-icon>
          <span i18n="@@stopReorderBtnLabel">Stop Select</span>
        </button>
      </div>

      <div *ngIf="!loadingProject">
        <button *ngIf="authService.can('risk_assessment_exports-create') && !project.is_template"
                [loading]="loadingRiskAssessment"
                mat-stroked-button
                color="primary"
                (click)="openDownloadRiskAssessmentPDFDialog()">
          <mat-icon aria-label="Add new risk">download</mat-icon>
          <span i18n="@@exportBtnLabel">Export</span>
        </button>
      </div>

      <button [matMenuTriggerFor]="createMenu"
              *ngIf="(
            authService.can('projects-create')
            || authService.can('project_templates-create')
            )"
              [loading]="loadingRiskAssessment"
              mat-flat-button
              color="accent">
        <mat-icon aria-label="Add new item to risk assessment">add</mat-icon>
        <span i18n="@@createRiskBtnLabel">Create Risk</span>
      </button>

      <mat-menu #createMenu="matMenu" xPosition="before">
        <ng-template matMenuContent let-name="name">

          <button mat-menu-item (click)="openNewRiskDialog()"
                  *ngIf="authService.can('risks-create')">
            <mat-icon>playlist_add</mat-icon>
            <span i18n="@@newRiskBtnLabel">New Risk</span>
          </button>

          <button mat-menu-item (click)="openImportRiskDialog()"
                  *ngIf="authService.can('risks-create')">
            <mat-icon>manage_search</mat-icon>
            <span i18n="@@importRisk">Import risk</span>
          </button>

        </ng-template>
      </mat-menu>

    </mat-toolbar-row>

  </mat-toolbar>

  <!--
    <div class="dark-theme" fxLayoutAlign="end center" fxLayoutGap="16px">

      &lt;!&ndash;<span class="row-spacer"></span>&ndash;&gt;

      &lt;!&ndash;    <button *ngIf="authService.can('risks-create')" [loading]="loadingRiskAssessment" mat-flat-button color="accent"
                  (click)="openNewRiskDialog()">
            <mat-icon aria-label="Add new risk">playlist_add</mat-icon>
            <span i18n="@@newRiskBtnLabel">New Risk</span>
          </button>&ndash;&gt;



    </div>
  -->

  <div class="dark-theme" fxLayoutAlign="start center" fxLayoutGap="16px" [fxHide]="isReordering">

    <mat-form-field color="accent">
      <mat-label i18n="@@scopeFilterInputLabel">Scope</mat-label>
      <mat-select
        (selectionChange)="riskFilterChange('scopes', $event.value)"
        multiple
        [(ngModel)]="scopeFilterModel">
        <mat-option *ngFor="let scope of $scopes | async" [value]="scope.id">
          {{scope.display_name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-label i18n="@@assignedToFilterInputLabel">Assigned to</mat-label>
      <mat-select
        (selectionChange)="actionFilterChange('assigned_to', $event.value)"
        multiple
        [(ngModel)]="assignedToFilterModel">
        <mat-option *ngFor="let name of $responsibles | async" [value]="name">
          {{name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-label i18n="@@categoryFilterInputLabel">Category</mat-label>
      <mat-select
        (selectionChange)="riskFilterChange('category', $event.value)"
        multiple
        [(ngModel)]="categoryFilterModel">
        <mat-option *ngFor="let category of $categories | async" [value]="category.id">
          {{category.display_name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-label i18n="@@phaseFilterInputLabel">Phase</mat-label>
      <mat-select
        (selectionChange)="actionFilterChange('phase', $event.value)"
        multiple
        [(ngModel)]="phaseFilterModel">
        <mat-option *ngFor="let phase of $phases | async" [value]="phase.id">
          {{phase.display_name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-label i18n="@@createdByInputLabel">Created by</mat-label>
      <mat-select
        (selectionChange)="riskFilterChange('created_by', $event.value)"
        multiple
        [(ngModel)]="createdByFilterModel">
        <mat-option *ngFor="let user of $createdBys | async" [hidden]="!user" [value]="user ? user.id : null">
          {{user ? user.initials : '-'}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-label i18n="@@riskScoreInputLabel">Risk score</mat-label>
      <mat-select
        (selectionChange)="riskFilterChange('risk_score_step', $event.value)"
        multiple
        [(ngModel)]="riskScoreFilterModel">
        <mat-option class="text-white" *ngFor="let riskScoreStep of riskService.riskScoreSteps"
                    [value]="riskScoreStep"
                    [ngStyle]="{'background-color': riskScoreStep.color, 'color': riskScoreStep.textColor ? riskScoreStep.textColor : 'initial'}"
        >
          <span>{{riskScoreStep.score_from === riskScoreStep.score_to ? '<= ' + riskScoreStep.score_to : riskScoreStep.score_from + ' - ' + riskScoreStep.score_to}}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-label i18n="@@statusInputLabel">Status</mat-label>
      <mat-select
        (selectionChange)="actionFilterChange('status', $event.value)"
        [(ngModel)]="statusFilterModel">
        <mat-option value="finished" i18n="@@statusDoneLabel">Finished</mat-option>
        <mat-option value="unfinished" i18n="@@statusNotDoneLabel">Unfinished</mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-button (click)="resetFilters()" *ngIf="filterActive">
      <mat-icon>clear</mat-icon>
      Clear filter
    </button>

  </div>

  <mat-card class="mat-elevation-z15 no-container-padding no-padding-horizontal">

    <!--risks table-->
    <table [fxHide]="isLoading"
           mat-table
           matSort
           matSortDisableClear
           multiTemplateDataRows
           [dataSource]="risksDataSource"
           matSortActive="number"
           matSortDirection="asc"
           class="clickable"
           cdkDropList
           (cdkDropListDropped)="onReorderTableRowDrop($event)"
    >

      <ng-container matColumnDef="risk_buttons_prefix">
        <th mat-header-cell *matHeaderCellDef>
          <button
            *ngIf="!isReordering"
            mat-icon-button
            (click)="toggleExpandAllRisks()"
            [matTooltip]="someExpanded ? 'Collapse all' : 'Expand all'"
          >
            <mat-icon *ngIf="!someExpanded">expand_more</mat-icon>
            <mat-icon *ngIf="someExpanded">expand_less</mat-icon>
          </button>

        </th>
        <td mat-cell *matCellDef="let risk">


          <button
            *ngIf="!isReordering"
            mat-icon-button
          >
            <mat-icon *ngIf="!risk.expanded">expand_more</mat-icon>
            <mat-icon *ngIf="risk.expanded">expand_less</mat-icon>
          </button>

          <div fxLayout="row" fxLayoutAlign="start center" *ngIf="isReordering">

            <mat-icon>drag_indicator</mat-icon>

            <mat-checkbox
              [(ngModel)]="risk.selected"
              (click)="$event.stopPropagation()"
              aria-label="Select risk row">
            </mat-checkbox>

          </div>

        </td>
      </ng-container>

      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isReordering">Nr</th>
        <td mat-cell *matCellDef="let risk">R{{risk.number | number:'3.0'}}</td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th i18n="@@riskCategoryTableHead" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isReordering">
          Category
        </th>
        <td mat-cell *matCellDef="let risk" class="nowrap">{{risk.category.display_name}}</td>
      </ng-container>

      <ng-container matColumnDef="created_by">
        <th i18n="@@createdByTableHead" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isReordering">
          Created By
        </th>
        <td mat-cell *matCellDef="let risk">{{risk.created_by ? risk.created_by.initials : '-'}}</td>
      </ng-container>

      <ng-container matColumnDef="scope">
        <th i18n="@@scopeTableHead" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isReordering">Scope
        </th>
        <td mat-cell *matCellDef="let risk" class="nowrap">{{risk.scope.display_name}}</td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th i18n="@@descriptionTableHead" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isReordering">
          Description
        </th>
        <td mat-cell *matCellDef="let risk">{{risk.description}}</td>
      </ng-container>

      <ng-container matColumnDef="identified_at">
        <th i18n="@@dateTableHead" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isReordering">Date</th>
        <td mat-cell *matCellDef="let risk" class="nowrap">{{risk.identified_at | date: 'longDate'}}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th i18n="@@statusTableHead" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isReordering">
          Voltooid
        </th>
        <td mat-cell *matCellDef="let risk" class="nowrap">
          <mat-icon *ngIf="risk.completed" color="primary">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!risk.completed" color="accent">radio_button_unchecked</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="probability_consequence">
        <th i18n="@@highestProbConsTableHead" class="mat-header-cell-right nowrap" mat-header-cell
            arrowPosition="before"
            *matHeaderCellDef mat-sort-header [disabled]="isReordering">
          Highest risk<br>Prob/Cons
        </th>
        <td mat-cell *matCellDef="let risk">
          <div fxLayoutAlign="end">
            <app-colored-risk-block [consequence]="risk.highest_consequence"
                                    [probability]="risk.highest_probability"></app-colored-risk-block>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="risk_buttons_suffix">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let risk;  let i = index;">
          <div fxLayoutAlign="end">
            <button mat-icon-button [matMenuTriggerFor]="menu"
                    (click)="$event.stopPropagation()"
                    *ngIf="(authService.can('risks-delete') || authService.can('risks-update'))">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu" xPosition="before">
              <ng-template matMenuContent let-name="name">
                <button mat-menu-item (click)="openEditRiskDialog(risk)"
                        *ngIf="authService.can('risks-update')">
                  <mat-icon>edit</mat-icon>
                  <span i18n="@@editBtnLabel">Edit</span><span> "R{{risk.number | number:'3.0'}}"</span>
                </button>
                <button mat-menu-item (click)="openNewRiskDialog(risk, true)"
                        *ngIf="authService.can('risks-create')">
                  <mat-icon>add</mat-icon>
                  <span i18n="@@addAboveBtnLabel">Insert risk above</span>
                </button>
                <button mat-menu-item (click)="openNewRiskDialog(risk)"
                        *ngIf="authService.can('risks-create')">
                  <mat-icon>add</mat-icon>
                  <span i18n="@@addBelowBtnLabel">Insert risk below</span>
                </button>
                <button mat-menu-item (click)="copyRisk(risk)"
                        *ngIf="authService.can('risks-create')">
                  <mat-icon>content_copy</mat-icon>
                  <span i18n="@@copyRiskBtnLabel">Copy to</span>
                </button>
                <button mat-menu-item (click)="openDeleteRiskDialog(risk)"
                        *ngIf="authService.can('risks-delete')">
                  <mat-icon>delete</mat-icon>
                  <span i18n="@@deleteBtnLabel">Delete</span><span> "R{{risk.number | number: '3.0'}}"</span>
                </button>
              </ng-template>
            </mat-menu>

          </div>
        </td>
      </ng-container>

      <!--Expanded risk container-->
      <ng-container matColumnDef="expandedRisk">
        <!--Expanded risk table cell-->
        <td mat-cell *matCellDef="let risk" [attr.colspan]="displayedColumns.length">
          <!--Expanded risk content (this part is hidden or shown)-->
          <div class="risk-detail"
               [@detailExpand]="risk.expanded ? 'expanded' : 'collapsed'" fxLayout="column"
               [style.height]="risk.expanded ? 'unset' : '0 !important'">

            <!--Title and initial risk row-->
            <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
              <span class="mat-title" i18n="@@actionsLabel">Actions</span>
              <span class="row-spacer"></span>
              <span i18n="@@intialRiskLabel">Initial risk</span>
              <app-colored-risk-block
                class="initial_risk"
                [probability]="risk.initial_probability"
                [consequence]="risk.initial_consequence">
              </app-colored-risk-block>
            </div>

            <table
              *ngIf="!project.is_template"
              #actionTables
              mat-table
              multiTemplateDataRows
              [dataSource]="actionsDataSources[risk.id]"
              class="clickable nested-table">

              <ng-container matColumnDef="action_buttons_prefix">
                <th mat-header-cell *matHeaderCellDef>
                  <button mat-icon-button (click)="toggleExpandAllActions(risk)"
                          [matTooltip]="someActionsExpanded(risk) ? 'Collapse all' : 'Expand all'">
                    <mat-icon *ngIf="!someActionsExpanded(risk)">expand_more</mat-icon>
                    <mat-icon *ngIf="someActionsExpanded(risk)">expand_less</mat-icon>
                  </button>

                </th>
                <td mat-cell *matCellDef="let action">
                  <button mat-icon-button>
                    <mat-icon *ngIf="!action.expanded">expand_more</mat-icon>
                    <mat-icon *ngIf="action.expanded">expand_less</mat-icon>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="description">
                <th i18n="@@descriptionTableHead" mat-header-cell *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let action">{{action.description}}</td>
              </ng-container>

              <ng-container matColumnDef="responsible">
                <th i18n="@@assignedToTableHead" mat-header-cell *matHeaderCellDef>Assigned to</th>
                <td mat-cell *matCellDef="let action">
                  {{action.assigned_to ? action.assigned_to.initials : action.responsible ? action.responsible : nobodyString}}
                </td>
              </ng-container>

              <ng-container matColumnDef="phase">
                <th i18n="@@phaseTableHead" mat-header-cell *matHeaderCellDef>Phase</th>
                <td mat-cell *matCellDef="let action" class="nowrap">{{action.phase.display_name}}</td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th i18n="@@statusTableHead" mat-header-cell *matHeaderCellDef>Voltooid</th>
                <td mat-cell *matCellDef="let action" class="nowrap">
                  <mat-icon *ngIf="action.completed_at" color="primary">check_circle_outline</mat-icon>
                  <mat-icon *ngIf="!action.completed_at" color="accent">radio_button_unchecked</mat-icon>
                </td>
              </ng-container>

              <ng-container matColumnDef="action_probability_consequence">
                <th i18n="@@highestProbConsTableHead" class="mat-header-cell-right nowrap" mat-header-cell
                    *matHeaderCellDef>
                  Highest risk<br>Prob/Cons
                </th>
                <td mat-cell *matCellDef="let action">
                  <div fxLayoutAlign="end">
                    <app-colored-risk-block
                      *ngIf="action.highest_consequence !== null && action.highest_probability !== null"
                      [consequence]="action.highest_consequence"
                      [probability]="action.highest_probability">
                    </app-colored-risk-block>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="action_buttons_suffix">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let action;  let i = index;">
                  <div fxLayoutAlign="end">

                    <button mat-icon-button [matMenuTriggerFor]="menu"
                            (click)="$event.stopPropagation()"
                            *ngIf="(authService.can('risk_actions-delete') || authService.can('risk_actions-update'))">
                      <mat-icon>more_vert</mat-icon>
                    </button>

                    <mat-menu #menu="matMenu" xPosition="before">
                      <ng-template matMenuContent let-name="name">
                        <button
                          *ngIf="authService.can('risk_actions-update') && action.completed_at != null"
                          (click)="setActionNotComplete(project, riskAssessment, risk, action)"
                          mat-menu-item>
                          <mat-icon>remove_done</mat-icon>
                          <span i18n="@@markAsUnDoneLabel">Mark as unfinished</span>
                        </button>
                        <button
                          *ngIf="authService.can('risk_actions-update') && !action.completed_at"
                          (click)="setActionComplete(project, riskAssessment, risk, action)"
                          mat-menu-item>
                          <mat-icon>done</mat-icon>
                          <span i18n="@@markAsDoneLabel">Mark as finished</span>
                        </button>
                        <button mat-menu-item (click)="openEditActionDialog(risk, action)"
                                *ngIf="authService.can('risk_actions-update')">
                          <mat-icon>edit</mat-icon>
                          <span i18n="@@editBtnLabel">Edit</span>
                        </button>
                        <button mat-menu-item (click)="openDeleteActionDialog(risk, action)"
                                *ngIf="authService.can('risk_actions-delete')">
                          <mat-icon>delete</mat-icon>
                          <span i18n="@@deleteBtnLabel">Delete</span>
                        </button>
                      </ng-template>
                    </mat-menu>

                  </div>
                </td>
              </ng-container>

              <!--expanded action row-->
              <ng-container matColumnDef="expandedAction" *ngIf="!project.is_template">
                <!--expanded action table cell-->
                <td mat-cell *matCellDef="let action" [attr.colspan]="actionDisplayedColumns.length">
                  <!--expanded action detail div, this is the part that's begin collapsed or expanded-->
                  <div class="action-detail"
                       [@detailExpand]="action.expanded ? 'expanded' : 'collapsed'" fxLayout="column"
                       [style.height]="action.expanded ? 'unset' : '0 !important'">
                    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">

                      <table
                        #actionResultsTables
                        mat-table
                        multiTemplateDataRows
                        [dataSource]="actionResultsDataSources[action.id]"
                        class="clickable nested-table">

                        <ng-container matColumnDef="executed_by">
                          <th i18n="@@executedByTableHead" mat-header-cell *matHeaderCellDef>Executed by</th>
                          <td mat-cell *matCellDef="let result">{{result.executed_by ? result.executed_by.initials : '-'}}</td>
                        </ng-container>

                        <ng-container matColumnDef="executed_at">
                          <th i18n="@@executedAtTableHead" mat-header-cell *matHeaderCellDef>Executed At</th>
                          <td mat-cell *matCellDef="let result"
                              class="nowrap">{{result.executed_at | date: 'mediumDate'}}</td>
                        </ng-container>

                        <ng-container matColumnDef="description">
                          <th i18n="@@descriptionTableHead" mat-header-cell *matHeaderCellDef>Description</th>
                          <td mat-cell *matCellDef="let result">{{result.description}}</td>
                        </ng-container>

                        <ng-container matColumnDef="result_probability_consequence">
                          <th i18n="@@highestProbConsTableHead" class="mat-header-cell-right nowrap" mat-header-cell
                              *matHeaderCellDef>
                            Highest risk<br>Prob/Cons
                          </th>
                          <td mat-cell *matCellDef="let result">
                            <div fxLayoutAlign="end">
                              <app-colored-risk-block
                                [consequence]="result.new_consequence"
                                [probability]="result.new_probability">
                              </app-colored-risk-block>
                            </div>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="result_buttons_suffix">
                          <th mat-header-cell *matHeaderCellDef></th>
                          <td mat-cell *matCellDef="let result;  let i = index;">
                            <div fxLayoutAlign="end">
                              <button mat-icon-button [matMenuTriggerFor]="menu"
                                      (click)="$event.stopPropagation()"
                                      *ngIf="(authService.can('risk_action_results-delete') || authService.can('risk_action_results-update'))">
                                <mat-icon>more_vert</mat-icon>
                              </button>

                              <mat-menu #menu="matMenu" xPosition="before">
                                <ng-template matMenuContent let-name="name">
                                  <button mat-menu-item (click)="openEditResultDialog(risk, action, result)"
                                          *ngIf="authService.can('risk_action_results-update')">
                                    <mat-icon>edit</mat-icon>
                                    <span i18n="@@editBtnLabel">Edit</span>
                                  </button>
                                  <button mat-menu-item (click)="openDeleteResultDialog(risk, action, result)"
                                          *ngIf="authService.can('risk_action_results-delete')">
                                    <mat-icon>delete</mat-icon>
                                    <span i18n="@@deleteBtnLabel">Delete</span>
                                  </button>
                                </ng-template>
                              </mat-menu>

                            </div>
                          </td>
                        </ng-container>


                        <!--results table header row-->
                        <tr mat-header-row *matHeaderRowDef="resultsDisplayedColumns"></tr>

                        <!--result row-->
                        <tr mat-row *matRowDef="let result; columns: resultsDisplayedColumns;"></tr>

                      </table>

                    </div>

                    <!--risks table inside expanded action-->
                    <p>
                      <button mat-flat-button color="primary" (click)="openNewResultDialog(risk, action)">
                        <mat-icon>add</mat-icon>
                        <span i18n="@@addResultBtnLabel">Add Result</span>
                      </button>
                    </p>

                  </div>

                </td>

              </ng-container>

              <!--actions table header row-->
              <tr mat-header-row *matHeaderRowDef="actionDisplayedColumns"></tr>

              <!--action row-->
              <tr mat-row *matRowDef="let action; columns: actionDisplayedColumns;"
                  [class.risk-expanded-row]="action.expanded"
                  (click)="toggleExpandAction(risk, action)" class="clickable hover"></tr>

              <!--action detail row-->
              <tr mat-row *matRowDef="let row; columns: ['expandedAction']" class="action-detail-row"></tr>
            </table>

            <table
              *ngIf="project.is_template"
              #actionTables
              mat-table
              multiTemplateDataRows
              [dataSource]="actionsDataSources[risk.id]"
              class="clickable nested-table">

              <ng-container matColumnDef="description">
                <th i18n="@@descriptionTableHead" mat-header-cell *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let action">{{action.description}}</td>
              </ng-container>

              <ng-container matColumnDef="responsible">
                <th i18n="@@assignedToTableHead" mat-header-cell *matHeaderCellDef>Assigned to</th>
                <td mat-cell *matCellDef="let action">
                  {{action.assigned_to ? action.assigned_to.initials : action.responsible ? action.responsible : nobodyString}}
                </td>
              </ng-container>

              <ng-container matColumnDef="phase">
                <th i18n="@@phaseTableHead" mat-header-cell *matHeaderCellDef>Phase</th>
                <td mat-cell *matCellDef="let action">{{action.phase.display_name}}</td>
              </ng-container>

              <ng-container matColumnDef="action_probability_consequence">
                <th i18n="@@highestProbConsTableHead" class="mat-header-cell-right nowrap" mat-header-cell
                    *matHeaderCellDef>
                  Highest risk<br>Prob/Cons
                </th>
                <td mat-cell *matCellDef="let action">
                  <div fxLayoutAlign="end">
                    <app-colored-risk-block
                      *ngIf="action.highest_consequence !== null && action.highest_probability !== null"
                      [consequence]="action.highest_consequence"
                      [probability]="action.highest_probability">
                    </app-colored-risk-block>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="action_buttons_suffix">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let action;  let i = index;">
                  <div fxLayoutAlign="end">
                    <button mat-icon-button [matMenuTriggerFor]="menu"
                            (click)="$event.stopPropagation()"
                            *ngIf="(authService.can('risk_actions-delete') || authService.can('risk_actions-update'))">
                      <mat-icon>more_vert</mat-icon>
                    </button>

                    <mat-menu #menu="matMenu" xPosition="before">
                      <ng-template matMenuContent let-name="name">
                        <button mat-menu-item (click)="openEditActionDialog(risk, action)"
                                *ngIf="authService.can('risk_actions-update')">
                          <mat-icon>edit</mat-icon>
                          <span i18n="@@editBtnLabel">Edit</span>
                        </button>
                        <button mat-menu-item (click)="openDeleteActionDialog(risk, action)"
                                *ngIf="authService.can('risk_actions-delete')">
                          <mat-icon>delete</mat-icon>
                          <span i18n="@@deleteBtnLabel">Delete</span>
                        </button>
                      </ng-template>
                    </mat-menu>

                  </div>
                </td>
              </ng-container>

              <!--actions table header row-->
              <tr mat-header-row *matHeaderRowDef="actionDisplayedColumns"></tr>

              <!--action row-->
              <tr mat-row *matRowDef="let action; columns: actionDisplayedColumns;"
                  [class.risk-expanded-row]="action.expanded"
                  (click)="toggleExpandAction(risk, action)" class="clickable hover"></tr>

            </table>

            <!--actions table inside expanded risk-->
            <p>
              <button mat-flat-button color="primary" (click)="openNewActionDialog(risk)">
                <mat-icon>add</mat-icon>
                <span i18n="@@addActionBtnLabel">Add Action</span>
              </button>
            </p>
          </div>

        </td>

      </ng-container>

      <!--Risk table headers row-->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

      <!--Risk row-->
      <tr mat-row
          id="risk-{{risk.id}}"
          class="clickable"
          cdkDrag
          (cdkDragStarted)="onDragStarted($event)"
          [cdkDragDisabled]="!isReordering"
          *matRowDef="let risk; columns: displayedColumns;"
          [class.risk-expanded-row]="risk.expanded"
          [class.hover]="!isReordering"
          [class.selected]="risk.selected"
          (click)="isReordering ? toggleRiskSelect(risk) : toggleExpandRisk(risk)"
          [class.hidden]="(actionFilterActive && actionsDataSources[risk.id].filteredData.length === 0) || (isDragging && risk.selected)"
      ></tr>

      <!--Risk detail row-->
      <tr mat-row *matRowDef="let risk; columns: ['expandedRisk']"
          [class.hidden]="(actionFilterActive && actionsDataSources[risk.id].filteredData.length === 0)"
          class="risk-detail-row"></tr>
    </table>

    <div *ngIf="isLoading" class="table_spinner">
      <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
    </div>

  </mat-card>

</section>

<button mat-mini-fab color="primary" *ngIf="scrolledFromTop" (click)="scrollToTop()" matTooltip="Scroll to top"
        [matTooltipPosition]="'left'" aria-label="Button for scolling to the top of the page">
  <mat-icon>expand_less</mat-icon>
</button>

<button mat-mini-fab color="primary" *ngIf="!scrolledFromTop" (click)="scrollToBottom()" matTooltip="Scroll to bottom"
        [matTooltipPosition]="'left'" aria-label="Button for scrolling to the bottom of the page">
  <mat-icon>expand_more</mat-icon>
</button>
