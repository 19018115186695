<h1 mat-dialog-title i18n="@@importRisk">Import existing risks</h1>
<form [formGroup]="form" (ngSubmit)="importRisks()">
  <div mat-dialog-content fxLayout="column" fxLayoutGap="16px">
    <span i18n="@@importRiskDialogText">Search and import existing risks into this risk assessment</span>
    <div class="error" *ngIf="hasError">
      <span *ngIf="this.errorMessage">{{this.errorMessage}}</span>
      <span *ngIf="!this.errorMessage" i18n="@@dialogError">There was an error, please try it again.</span>
    </div>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@risksInputLabel">Risks</mat-label>
        <mat-select [multiple]="true" formControlName="from_risk_ids" i18n-placeholder="@@risksInputPlaceholder"
                    placeholder="Choose on or more risks" #templateSelect>
          <mat-option>
            <ngx-mat-select-search
              [formControl]="riskFilterCtrl"
              i18n-placeholderLabel="@@executedByInputPlaceholder"
              placeholderLabel="Search"
              noEntriesFoundLabel="No matching risks found"
              i18n-noEntriesFoundLabel="@@risksInputNotFoundError"
              [searching]="searching"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let risk of filteredRisks | async" [value]="risk.id">
            {{risk.description}}, {{risk.scope.display_name}}, {{risk.category.display_name}}
          </mat-option>
        </mat-select>
        <mat-hint i18n="@@risksInputHint">Choose one or more risks to import into the risk assessment</mat-hint>
        <mat-error i18n="@@risksInputError">
          Please select at least one risk
        </mat-error>
      </mat-form-field>
    </p>
  </div>
  <mat-dialog-actions fxLayoutAlign="end" fxLayoutGap="16px">
    <a mat-button mat-dialog-close [disabled]="isLoading" i18n="@@cancelBtnLabel">Cancel</a>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-button color="primary" [loading]="isLoading" i18n="@@importBtnLabel">Import</button>
  </mat-dialog-actions>
</form>
