import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {Project, ProjectService} from './project.service';
import {map, take} from 'rxjs/operators';
import {SuccessResponse} from '../app.component';

export interface RiskAssessment {
  id: string,
  number: number,
  name: string,
  last_activity: string,
  created_at: string,
  expanded?: boolean,
  highest_probability: number,
  highest_consequence: number,
  is_template?: boolean,
  from_template_ids?: Array<string>,
  from_template_id?: string,
  project?: Project,
}


@Injectable({
  providedIn: 'root'
})
export class RiskAssessmentService {

  apiEndpoint = 'risk_assessments';

  constructor(
    private http: HttpClient,
    private projectService: ProjectService,
    @Inject(LOCALE_ID) public locale: string
  ) {
  }

  index(projectId: Project['id']): Observable<Array<RiskAssessment>> {
    return this.http.get<Array<RiskAssessment>>(
      environment.api_url + this.projectService.apiEndpoint + '/' + projectId + '/' + this.apiEndpoint, {withCredentials: true}
    );
  }

  indexTemplates(): Observable<Array<RiskAssessment>> {
    return this.http.get<Array<RiskAssessment>>(
      environment.api_url + this.apiEndpoint, {withCredentials: true}
    );
  }

  get(project: Project, riskAssessmentId: RiskAssessment['id']) {
    return this.http.get<RiskAssessment>(
      environment.api_url + this.projectService.apiEndpoint + '/' + project.id + '/' + this.apiEndpoint + '/' + riskAssessmentId,
      {withCredentials: true}
    ).pipe(take(1));
  }

  getTemplate(riskAssessmentId: RiskAssessment['id']) {
    return this.http.get<RiskAssessment>(
      environment.api_url +
      this.apiEndpoint + '/' +
      riskAssessmentId,
      {withCredentials: true}
    ).pipe(take(1));
  }

  create(project: Project, riskAssessment: RiskAssessment): Observable<RiskAssessment> {
    if (project) {
      return this.http.post<RiskAssessment>(
        environment.api_url + this.projectService.apiEndpoint + '/' + project.id + '/' + this.apiEndpoint, riskAssessment,
        {withCredentials: true}
      ).pipe(take(1));
    } else {
      return this.http.post<RiskAssessment>(
        environment.api_url +
        this.apiEndpoint, riskAssessment,
        {withCredentials: true}
      ).pipe(take(1));
    }
  }

  update(project: Project, riskAssessment: RiskAssessment): Observable<RiskAssessment> {
    if (project) {
      return this.http.put<RiskAssessment>(
        environment.api_url + this.projectService.apiEndpoint + '/' + project.id + '/' + this.apiEndpoint + '/' + riskAssessment.id,
        riskAssessment,
        {withCredentials: true}
      ).pipe(take(1));
    } else {
      return this.http.put<RiskAssessment>(
        environment.api_url +
        this.apiEndpoint + '/' + riskAssessment.id,
        riskAssessment,
        {withCredentials: true}
      ).pipe(take(1));
    }
  }

  delete(project: Project, riskAssessment: RiskAssessment) {
    if (project) {
      return this.http.delete<SuccessResponse>(
        environment.api_url + this.projectService.apiEndpoint + '/' + project.id + '/' + this.apiEndpoint + '/' + riskAssessment.id,
        {withCredentials: true}).pipe(
        map((response: SuccessResponse) => {
          return response.success;
        }),
        take(1)
      );
    } else {
      return this.http.delete<SuccessResponse>(
        environment.api_url +
        this.apiEndpoint + '/' + riskAssessment.id,
        {withCredentials: true}).pipe(
        map((response: SuccessResponse) => {
          return response.success;
        }),
        take(1)
      );
    }
  }

  export(
    project: Project,
    riskAssessment: RiskAssessment,
    format: string,
    sort: string,
    sortDirection: string,
    filteredRisks: Array<string>,
    filteredActions?: Array<string>,
    locale: string = null
  ) {

    if (locale === null) {
      locale = this.locale.split('-', 1)[0];
    }

    const httpOptions = {
      responseType: 'blob' as 'json',
      withCredentials: true,
      headers: {
        'Accept-Language': locale,
      }
    };

    return this.http.post(
      environment.api_url +
      this.projectService.apiEndpoint + '/' + project.id + '/' +
      this.apiEndpoint + '/' + riskAssessment.id +
      '/export',
      {
        format,
        sort,
        sort_direction: sortDirection,
        filtered_risks: filteredRisks,
        filtered_actions: filteredActions,
      },
      httpOptions,
    );
  }
}
