<h1 mat-dialog-title i18n="@@editRiskDialogHeader">Edit Risk</h1>
<form [formGroup]="form" (ngSubmit)="updateRisk()">
  <div mat-dialog-content fxLayout="column" fxLayoutGap="16px">
    <span i18n="@@editRiskDialogText">Edit the risk information below</span>
    <div class="error" *ngIf="hasError">
      <span *ngIf="this.errorMessage">{{this.errorMessage}}</span>
      <span *ngIf="!this.errorMessage" i18n="@@dialogError">There was an error, please try it again.</span>
    </div>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@riskCategoryInputLabel">Category</mat-label>
        <mat-select formControlName="category" required>
          <mat-option *ngFor="let category of categories" [value]="category.id">
            {{category.display_name}}
          </mat-option>
        </mat-select>
        <mat-hint i18n="@@riskCategoryInputHint">Select the risk category</mat-hint>
        <mat-error i18n="@@riskCategoryInputError">
          Please select a category
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@riskScopeInputLabel">Scope</mat-label>
        <input type="text"
               i18n-placeholder="@@riskScopeInputPlaceholder"
               placeholder="Select or type to create one"
               aria-label="Scope"
               matInput
               formControlName="scope"
               [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayScope">
          <mat-option *ngFor="let scope of filteredScopes | async" [value]="scope">
            {{scope.display_name}}
          </mat-option>
        </mat-autocomplete>
        <mat-hint i18n="@@riskScopeInputHint">Search existing scopes or type a name to create a new one</mat-hint>
        <mat-error i18n="@@riskScopeInputError">
          Please provide a scope
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@createdByInputLabel">Created By</mat-label>
        <mat-select formControlName="created_by" i18n-placeholder="@@riskCreatedByInputPlaceholder"
                    placeholder="Created By" #createdBySelect>
          <mat-option>
            <ngx-mat-select-search
              [formControl]="userFilterCtrl"
              noEntriesFoundLabel="Nothing found"
              i18n-noEntriesFoundLabel="@@executedByInputNoEntriesFound"
              placeholderLabel="Search"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
            {{user.name}}
          </mat-option>
        </mat-select>
        <mat-hint i18n="@@riskCreatedByInputHint">Change the user that added the risk if necessary</mat-hint>
        <mat-error i18n="@@riskCreatedByInputError">
          Please select a user
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@descriptionInputLabel">Description</mat-label>
        <textarea formControlName="description" matInput cdkTextareaAutosize></textarea>
        <mat-hint i18n="@@riskDescriptionInputHint">Describe the risk</mat-hint>
        <mat-error i18n="@@riskDescriptionInputError">
          Please provide a description of the risk
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@riskDateInputLabel">Date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="identified_at" (focus)="picker.open()">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-hint i18n="@@riskDateInputHint">Click the calendar icon to choose a date</mat-hint>
        <mat-error i18n="@@riskDateInputError">
          Please provide a identification date
        </mat-error>
      </mat-form-field>
    </p>

    <div fxLayout="row" fxLayoutGap="16px">

      <section>
        <span i18n="@@initialProbabilityInputLabel">Initial probability</span>
        <p>
          <mat-button-toggle-group formControlName="initial_probability">
            <mat-button-toggle [value]="0" aria-label="0">
              0
            </mat-button-toggle>
            <mat-button-toggle [value]="1" aria-label="1">
              1
            </mat-button-toggle>
            <mat-button-toggle [value]="2" aria-label="2">
              2
            </mat-button-toggle>
            <mat-button-toggle [value]="3" aria-label="3">
              3
            </mat-button-toggle>
            <mat-button-toggle [value]="4" aria-label="4">
              4
            </mat-button-toggle>
            <mat-button-toggle [value]="5" aria-label="5">
              5
            </mat-button-toggle>
          </mat-button-toggle-group>
        </p>
      </section>

      <span class="row-spacer"></span>

      <section>
        <span i18n="@@initialConsequenceInputLabel">Initial consequence</span>
        <p style="text-align: right">
          <mat-button-toggle-group formControlName="initial_consequence">
            <mat-button-toggle [value]="1" aria-label="1">
              1
            </mat-button-toggle>
            <mat-button-toggle [value]="2" aria-label="2">
              2
            </mat-button-toggle>
            <mat-button-toggle [value]="3" aria-label="3">
              3
            </mat-button-toggle>
            <mat-button-toggle [value]="4" aria-label="4">
              4
            </mat-button-toggle>
            <mat-button-toggle [value]="5" aria-label="5">
              5
            </mat-button-toggle>
          </mat-button-toggle-group>
        </p>
      </section>

    </div>


  </div>
  <mat-dialog-actions fxLayoutAlign="end" fxLayoutGap="16px">
    <a mat-button mat-dialog-close [disabled]="isLoading" i18n="@@cancelBtnLabel">Cancel</a>
    <button mat-button color="primary" [loading]="isLoading" i18n="@@updateRiskBtnLabel">Update risk</button>
  </mat-dialog-actions>
</form>

