import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Project} from './project.service';
import {RiskAssessment} from './risk-assessment.service';

export interface RiskScope {
  id: number,
  display_name: string
}

@Injectable({
  providedIn: 'root'
})
export class RiskScopeService {

  private apiEndpoint = 'risk_scopes';

  constructor(
    private http: HttpClient,
  ) {
  }

  index(): Observable<Array<RiskScope>> {
    return this.http.get<Array<RiskScope>>(environment.api_url + this.apiEndpoint, {withCredentials: true});
  }

  projectRiskScopesIndex(project: Project): Observable<Array<RiskScope>> {
    return this.http.get<Array<RiskScope>>(
      environment.api_url +
      'projects/' + project.id +
      '/' + this.apiEndpoint,
      {withCredentials: true});
  }

  riskAssessmentRiskScopesIndex(riskAssessment: RiskAssessment): Observable<Array<RiskScope>> {
    return this.http.get<Array<RiskScope>>(
      environment.api_url +
      'risk_assessments/' + riskAssessment.id +
      '/' + this.apiEndpoint,
      {withCredentials: true});
  }
}
