<h1 mat-dialog-title i18n="@@newProjectFromTemplateDialogHeader">New Project from Template</h1>
<form [formGroup]="form" (ngSubmit)="createNewProject()">
  <div mat-dialog-content fxLayout="column" fxLayoutGap="16px">
    <span i18n="@@newProjectFromTemplateDialogText">Create a new project from one or more templates</span>
    <div class="error" *ngIf="hasError">
      <span *ngIf="this.errorMessage">{{this.errorMessage}}</span>
      <span *ngIf="!this.errorMessage" i18n="@@dialogError">There was an error, please try it again.</span>
    </div>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@templateInputLabel">Templates</mat-label>
        <mat-select [multiple]="true" formControlName="from_template_ids" i18n-placeholder="@@templateInputPlaceholder" placeholder="Choose a template" #templateSelect>
          <mat-option>
            <ngx-mat-select-search
              [formControl]="projectFilterCtrl"
              noEntriesFoundLabel="Nothing found"
              i18n-noEntriesFoundLabel="@@executedByInputNoEntriesFound"
              i18n-placeholderLabel="@@executedByInputPlaceholder"
              placeholderLabel="Search"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let template of filteredProjects | async" [value]="template.id">
            {{template.name}}
          </mat-option>
        </mat-select>
        <mat-hint i18n="@@templateInputHint">Choose one or more templates to import into the new project</mat-hint>
        <mat-error i18n="@@templateInputError">
          Please select at least one template
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@projectNameInputLabel">Project name</mat-label>
        <input matInput i18n-placeholder="@@projectNameInputPlaceholder" placeholder="Example project" required formControlName="name">
        <mat-hint i18n="@@projectNameInputHint">Enter the name of the project</mat-hint>
        <mat-error i18n="@@projectNameInputError">
          Please provide the name of the project
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="fill">
        <mat-label i18n="@@caseNumberInputLabel">Case number</mat-label>
        <input matInput placeholder="PSNL-000000" required formControlName="case_number">
        <mat-hint i18n="@@caseNumberInputHint">Enter the case number of the project</mat-hint>
        <mat-error i18n="@@caseNumberInputError">
          Please use numbers only
        </mat-error>
      </mat-form-field>
    </p>
  </div>
  <mat-dialog-actions fxLayoutAlign="end" fxLayoutGap="16px">
    <a mat-button mat-dialog-close [disabled]="isLoading" i18n="@@cancelBtnLabel">Cancel</a>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-button color="primary" [loading]="isLoading" i18n="@@createProjectBtnLabel">Create project</button>
  </mat-dialog-actions>
</form>
